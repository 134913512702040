// src/components/Login.js

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/action/authAction"; // Import your login action

function Login() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(email, password)); // Dispatch login action with email and password
        setEmail("");
        setPassword("");
    };

    return (
        <>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label>Email:</label>
                <input 
                    type="email" 
                    name="useremail" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                /><br />
                <label>Password:</label>
                <input 
                    type="password" 
                    name="userpassword" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                /><br />
                <input type="submit" name="submit" />
            </form>
        </>
    );
}

export default Login;