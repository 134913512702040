
import axios from 'axios';
import { getRxToken } from './Store';
// import gettoken from '../app/cookies/cookieget';
// import settoken from '../app/cookies/cookieset';
import { update_token_fun } from '../redux/action/homeAction';

const axiosApiInstance = axios.create();
// Request interceptor for API calls


axiosApiInstance.interceptors.request.use(
    async (config) => {
    
        
        // const token:any=await gettoken()
        
        // const keys =await token.value;
        const keys="123"

        config.headers = {
            'authorization': `Bearer ${keys}`,
            'Content-Type': config.url.includes("file")? `multipart/form-data`: 'application/json' 
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

// Response interceptor for API calls
// axiosApiInstance.interceptors.response.use((response) => {
//     return response
// }, async function (error) {

//     const token:any=await gettoken()


//     const keys= await token.value


//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
     
//         console.log('error_interceptor',error,getRxToken());
//         originalRequest._retry = true;
     
        
//         const { data } = await axios.post(`${process.env.API_URL}/refresh`, '', { headers: { 'Authorization':`Bearer ${keys}` } })
//         console.log("data.token",data.token);
//         const update_token=data.token
//          update_token_fun(update_token)
      
      
//         await settoken(update_token)
   
        
//         axios.defaults.headers.common['Authorization'] = update_token;
//         return axiosApiInstance(originalRequest);
//     }

   
//     return Promise.reject(error);
// });

export default axiosApiInstance;
