import React, { useState } from "react";

const Contact = ({ label, options }) => {
  const [selectedCode, setSelectedCode] = useState("EN");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <section>
      <div className="text-sm pb-4 font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div className="flex items-center border  border-gray-300  p-2.5 w-full">
        {/* Dropdown for country code */}
        <select
          value={selectedCode}
          onChange={handleCodeChange}
          className="border-none bg-transparent focus:outline-none text-gray-700 text-sm px-2"
        >
          {options?.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>

        {/* Divider */}
        <span className="h-full w-px bg-gray-300 mx-2"></span>

        {/* Input for phone number */}
        <input
          type="text"
          value={phoneNumber}
          onChange={handlePhoneChange}
          placeholder="Enter phone number"
          className="flex-1 focus:outline-none text-gray-700 text-sm"
        />
      </div>
    </section>
  );
};

export default Contact;
