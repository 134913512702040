

import { ACTION_TYPE } from '../action/type'
const initialState = {  
    productDescriptionResponesLoading:false,
    productDescriptionResponseSuccess:[],
    productDescriptionResponseError:null
}

const productlistinitialState = {  
    productListResponesLoading:false,
    productListResponseSuccess:[],
    productListResponseError:null
}

const productlistitemsinitialState = {  
    productListitemsResponesLoading:false,
    productListitemsResponseSuccess:[],
    productListitemsResponseError:null
}

export  function productReducer(state = initialState, action) {
   
   switch (action.type) {
        case ACTION_TYPE.GET_PRODUCT_DATA_REQUEST:
            return {
                ...state,
                productDescriptionResponesLoading:true,
                productDescriptionResponseSuccess:[],
                productDescriptionResponseError:null
            }
        case ACTION_TYPE.GET_PRODUCT_DATA_RESPONSE_SUCCESS:
            
            return {
                ...state,
                productDescriptionResponesLoading:false,
                productDescriptionResponseSuccess:action.payload.productResponseSuccess,
                productDescriptionResponseError:null
            
            }
        case ACTION_TYPE.GET_PRODUCT_DATA_RESPONSE_FAILURE:   
            return {
                ...state,
                productDescriptionResponesLoading:false,
                productDescriptionResponseSuccess:[],
                productDescriptionResponseError:action.errorMessage
             
            }  
           
        default:
            return state;
    }
}

export  function productFilterListReducer(state = productlistinitialState, action) {
   
    switch (action.type) {
         case ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA_REQUEST:
             return {
                 ...state,
                 productListResponesLoading:true,
                 productListResponseSuccess:[],
                 productListResponseError:null
             }
         case ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA_SUCCESS:
             
             return {
                 ...state,
                 productListResponesLoading:false,
                 productListResponseSuccess:action.payload.productResponseSuccess,
                 productListResponseError:null                
             
             }
         case ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA__FAILURE:   
             return {
                 ...state,
                 productListResponesLoading:false,
                 productListResponseSuccess:[],
                 productListResponseError:action.errorMessage
              
             }  
            
         default:
             return state;
     }
 }

 export  function productFilterListitemsReducer(state = productlistitemsinitialState, action) {
   
    switch (action.type) {
         case ACTION_TYPE.GET_PRODUCT_LIST_DATA_REQUEST:
             return {
                 ...state,
                 productListitemsResponesLoading:true,
                 productListitemsResponseSuccess:[],
                 productListitemsResponseError:null
             }
         case ACTION_TYPE.GET_PRODUCT_LIST_DATA_SUCCESS:
             
             return {
                 ...state,
                 productListitemsResponesLoading:false,
                 productListitemsResponseSuccess:action.payload.productListitemResponseSuccess,
                 productListitemsResponseError:null
                }
         case ACTION_TYPE.GET_PRODUCT_LIST_DATA_FAILURE:   
             return {
                 ...state,
                 productListitemsResponesLoading:false,
                 productListitemsResponseSuccess:[],
                 productListitemsResponseError:action.errorMessage
                }  
            
         default:
             return state;
     }
 }

