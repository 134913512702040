

import { ACTION_TYPE } from '../action/type'
const initialState = {
    cartResponesLoading: false,
    cartResponseSuccess: null,
    cartResponseError: null
}
const cart_items_number_initialState = {
    cart_items_numberResponesLoading: false,
    cart_items_numberResponseSuccess: null,
    cart_items_numberResponseSuccess_info: null,
    cart_items_numberResponseError: null,
}
//updatecartlatestprice...
const update_cart_latest_price_initialState = {
    update_cart_latest_priceResponesLoading: false,
    update_cart_latest_priceResponseSuccess: null,    
    update_cart_latest_priceResponseError: null,
}
//CARTADD 
const add_to_cart_initialState = {
    add_to_cartResponesLoading: false,
    add_to_cartResponseSuccess: null,    
    add_to_cartResponseError: null,
} 

const youMayLike_initialState = {
    youMayLike_responesLoading: false,
    youMayLike_responseSuccess: null,
    youMayLike_responseError: null
}

export function cartReducer(state = initialState, action) {

    switch (action.type) {
        case ACTION_TYPE.GET_CART_DATA_REQUEST:
            return {
                ...state,
                cartResponesLoading: true,
                cartResponseSuccess: null,
                cartResponseError: null
            }
        case ACTION_TYPE.GET_CART_DATA_RESPONSE_SUCCESS:

            return {
                ...state,
                cartResponesLoading: false,
                cartResponseSuccess: action.payload.cartResponseSuccess,
                cartResponseError: null

            }
        case ACTION_TYPE.GET_CART_DATA_RESPONSE_FAILURE:
            return {
                ...state,
                cartResponesLoading: false,
                cartResponseSuccess: null,
                cartResponseError: action.errorMessage

            }

        default:
            return state;
    }
}

//for cart count....
export function cartNumberReducer(state = cart_items_number_initialState, action) {
    console.log(action.type, "action.type", action.payload, 'action.payload')
    switch (action.type) {
        case ACTION_TYPE.CART_COUNT_REQUEST:
            return {
                ...state,
                cart_items_numberResponesLoading: true,
                cart_items_numberResponseSuccess: null,
                cart_items_numberResponseSuccess_info: null,
                cart_items_numberResponseError: null
            }
        case ACTION_TYPE.CART_COUNT_SUCCESS:

            return {
                ...state,
                cart_items_numberResponesLoading: false,
                cart_items_numberResponseSuccess: action.payload.cartCountResponseSuccess,
                cart_items_numberResponseSuccess_info: action.payload.cartCountResponseSuccess_info,
                cart_items_numberResponseError: null

            }
        case ACTION_TYPE.CART_COUNT_FAILURE:
            return {
                ...state,
                cart_items_numberResponesLoading: false,
                cart_items_numberResponseSuccess: null,
                cart_items_numberResponseSuccess_info: null,
                cart_items_numberResponseError: action.errorMessage

            }
        case ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS:
            return {
                ...state,
                cart_items_numberResponesLoading: false,
                cart_items_numberResponseSuccess: action.payload.cartCountResponseSuccess,
                cart_items_numberResponseSuccess_info: action.payload.cartCountResponseSuccess_info,
                cart_items_numberResponseError: null
            };
        case ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN:
            const updatedCartItems = state.cart_items_numberResponseSuccess_info.filter(
                item => item.productSku !== action.payload.productSku
            );
            const totalAmount = updatedCartItems.reduce((sum, item) => sum + item.price, 0);
            return {
                ...state,
                cart_items_numberResponesLoading: false,
                cart_items_numberResponseSuccess: {
                    ...state.cart_items_numberResponseSuccess,
                    nodes: state.cart_items_numberResponseSuccess.nodes.map(node => ({
                        ...node,
                        shoppingCartItemsByShoppingCartId: {
                            ...node.shoppingCartItemsByShoppingCartId,
                            nodes: updatedCartItems, // Assign updated cart items here
                        },
                        // Update the grossAmount, netAmount, and discountedPrice
                        grossAmount: totalAmount,
                        netAmount: totalAmount,
                        discountedPrice: totalAmount,
                    })),
                },
                cart_items_numberResponseSuccess_info: updatedCartItems,
                cart_items_numberResponseError: null
            };
        case ACTION_TYPE.CART_COUNT_WITHOUT_LOGIN:
            return {
                ...state,
                cart_items_numberResponesLoading: false,
                cart_items_numberResponseSuccess: action.payload.cartCountResponseSuccess,
                cart_items_numberResponseSuccess_info: action.payload.cartCountResponseSuccess_info,
                cart_items_numberResponseError: null
            };

        default:
            return state;
    }
}

//for you may like
export function youMayLikeReducer(state = youMayLike_initialState, action) {    
    switch (action.type) {
        case ACTION_TYPE.YOU_MAY_LIKE_REQUEST:
            return {
                ...state,
                youMayLike_responesLoading: true,
                youMayLike_responseSuccess: null,
                youMayLike_responseError: null
            };
        case ACTION_TYPE.YOU_MAY_LIKE_SUCCESS:
            return {
                ...state,
                youMayLike_responesLoading: false,
                youMayLike_responseSuccess: action.payload.youmaylikeResponseSuccess,
                youMayLike_responseError: null
            };
        case ACTION_TYPE.YOU_MAY_LIKE_FAILURE:
            return {
                ...state,
                youMayLike_responesLoading: false,
                youMayLike_responseSuccess: null,
                youMayLike_responseError: action.errorMessage
            };
        default:
            return state;
    }
}
//updatecartlatestprice
export function updateCartLatestPricereducer(state = update_cart_latest_price_initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.UPDATE_CART_LATEST_PRICE_REQUEST:
            return {
                ...state,                
                update_cart_latest_priceResponesLoading: true,
                update_cart_latest_priceResponseSuccess: null,    
                update_cart_latest_priceResponseError: null,
            };
        case ACTION_TYPE.UPDATE_CART_LATEST_PRICE_SUCCESS:
            return {
                ...state,
                update_cart_latest_priceResponesLoading: false,
                update_cart_latest_priceResponseSuccess: action.payload.updatecartmessage,    
                update_cart_latest_priceResponseError: null                
            };
        case ACTION_TYPE.UPDATE_CART_LATEST_PRICE_FAILURE:
            return {
                ...state,
                update_cart_latest_priceResponesLoading: false,
                update_cart_latest_priceResponseSuccess: null,    
                update_cart_latest_priceResponseError: action.errorMessage
            };
        default:
            return state;
    }
}

//addtocart
export function addtocartreducer(state = add_to_cart_initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.ADD_TO_CART_REQUEST:
            return {
                ...state, 
                add_to_cartResponesLoading: true,
                add_to_cartResponseSuccess: null,    
                add_to_cartResponseError: null               
                
            };
        case ACTION_TYPE.ADD_TO_CART_SUCCESS:
            return {
                ...state,
                add_to_cartResponesLoading: false,
                add_to_cartResponseSuccess: action.payload.addtocartresponsesuccesss,
                add_to_cartResponseError: null
            };
        case ACTION_TYPE.ADD_TO_CART_FAILURE:
            return {
                ...state,
                add_to_cartResponesLoading: false,
                add_to_cartResponseSuccess: null,
                add_to_cartResponseError: action.errorMessage               
            };
        default:
            return state;
    }
}

