import React from "react";
import Tick from "../../product_img/success_tick.png";

const Textinput = ({
  label = "test",
  placeholder = "test",
  value,
  onChange,
  type = "text",
  keyvalue,
}) => {
  return (
    <section>
      <div className="text-sm font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div key={keyvalue} className="py-4 relative">
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(label, e.target.value)}
          placeholder={placeholder}
          className="w-full px-4  py-2 border border-[#CACACA]  focus:outline-none focus:ring-2 focus:ring-blue-500"
          key={label}
        />
        {(label === "New Mobile number" || label === "New Email Address") && (
          <img
            src={Tick}
            alt="tick_img"
            className="absolute top-1/2 right-4 w-5 h-5 transform -translate-y-1/2 pointer-events-none"
          />
        )}
      </div>
    </section>
  );
};

export default Textinput;
