import axios from "axios";
import Config from "./config";
import constants from "./constants";
import axiosApiInstance from "./axiosInterceptor";
import {
  HOMENOTIFICATIONQUERY,SUGGESTEDPRODUCTS,PAYMENTFAILED,CARTIDLOGIN,
  GET_DETAILS_WITH_PINCODE,
  DISTINCTCOUNTRY,
  GET_PINCODE_DETAILS,
  GET_EMAIL_SUBSCRIPTIONS,
  CURRENCYANDCOUNTRIES,
  GET_ORDERS_SUMMARY_QUERY,
  ALLUSERADDRESSESS,
  CART_COUNT_QUERY,
  CART_COUNT_QUERY_NEW,
  CART,MYACCOUNT,RECENTLY_VIEWED_AND_YOU_MAY_ALSO_LIKE,PRODUCTDETAILS,PRODUCT_HOME_COUNT
} from "./graphqlQuery";
import config from "./config";

const API_URL_graphql = constants.api_url_graphql;
const api_url = constants.api_url;
export const login = async (form_data) =>
  await new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        `${process.env.API_URL}/login`,
        form_data,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      Config.logGeneral && resolve({ data });
    } catch (error) {
      Config.logGeneral && console.log("Login Error", error);

      reject(error);
    }
  });

// export const authenticateUser = (email, password) => {
//   return new Promise((resolve, reject) => {
//     axios.post(`${api_url}/api/auth/signin`, {
//       email,
//       password,
//       roles: ["user"], // Include roles if necessary
//     })
//       .then(response => {
//         console.log(response.data, "Login successful");
//         resolve(response.data); // Resolve with the response data
//       })
//       .catch(error => {
//         console.log(error, "Error in getting the response");
//         reject(error.response ? error.response.data.errors[0].message : error.message); // Reject with error message
//       });
//   });
// };
export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(`${api_url}/addquestion`, formData); // Make the API POST request
    return response.data; // Return the response data (message)
  } catch (error) {
    // Handle errors and return an error message
    if (error.response) {
      // If the error is from the server
      return { success: false, message: error.response.data.message || 'Something went wrong. Please try again.' };
    } else {
      // If there is a network error or no response
      return { success: false, message: 'Network error. Please try again later.' };
    }
  }
};
export const authenticateUser = (email, password) => {
  return new Promise((resolve, reject) => {
    // Step 1: Call the Sign-in API
    axios
      .post(`${api_url}/api/auth/signin`, {
        email,
        password,
        roles: ["user"], // Include roles if necessary
      })
      .then((response) => {
        console.log(response.data, "Login successful");

        // Step 2: On successful login, extract the data you need (e.g., access token or userprofile)
        const { accessToken, userprofile } = response.data;

        // Step 3: Now call the GraphQL API to get additional data, passing the access token or necessary params
        return axios
          .post(API_URL_graphql, {
            query: CARTIDLOGIN,
            variables: { userprofileId: userprofile.id },
          })
          .then((graphqlResponse) => {
            console.log("GraphQL Data:", graphqlResponse.data);

            // Combine both the login response data and the GraphQL response data
            const combinedData = {
              ...response.data, // Spread the response from the signin API
              shoppingCartId:
                graphqlResponse.data.data.allShoppingCarts.nodes[0]?.id || null, // Add shopping cart data
            };
            console.log(combinedData, "combinedData");
            // Step 4: Resolve the combined data
            resolve(combinedData);
          })
          .catch((error) => {
            console.log(error, "Error in GraphQL request");
            reject(
              error.response
                ? error.response.data.errors[0].message
                : error.message
            ); // Reject with error message
          });
      })
      .catch((error) => {
        console.log(error, "Error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};
//for payment page
export const createOrder = async (cartId, userId, voucherCode = "") => {
  try {
    const response = await axios.post(`${api_url}/createorder`, {
      user_id: userId,
      cart_id: cartId,
      payment_mode: "Prepaid",
      voucher_code: voucherCode || "",
    });

    const data = response.data;

    if (!data || !data.order) {
      throw new Error("Failed to create order");
    }

    return data.order;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error creating order");
  }
};
export const sendToAirPay = async (
  orderId,
  amount = "1",
  buyerPhone = "7397306212",
  buyerPinCode = "600078"
) => {
  try {
    const response = await axios.post(`${api_url}/sendtoairpay`, {
      orderid: orderId,
      amount: amount,
      buyerPhone: buyerPhone,
      buyerPinCode: buyerPinCode,
      customvar: "",
      subtype: "Online",
    });

    const data = response.data;

    if (!data || !data.checksum) {
      throw new Error("Failed to get AirPay payment data");
    }

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Error communicating with AirPay"
    );
  }
};

// Function to add products to the cart
export const add_to_cart = async (user_id, cart_id = null) => {
  return new Promise((resolve, reject) => {
    const cartDetails = {
      cart_id: "",
      userId: "",
      products: [{ sku_id: "SE11744-92570000", qty: 1, price: 4832 }],
    };
    // const cartDetails={"cart_id":"","userId":"","products":[{"sku_id":"SBA11673-92570000","qty":1,"price":5355},]}
    // Retrieve cart details from localStorage
    // const cartDetails = JSON.parse(localStorage.getItem('cartDetails')); // Assuming 'cartDetails' is stored as a JSON string

    // Check if cartDetails exists in localStorage
    if (!cartDetails || cartDetails.length === 0) {
      return reject("No products found in cart details.");
    }

    // Construct the products array from localStorage cartDetails
    const products = cartDetails.products.map((item) => ({
      sku_id: item.sku_id, // Product SKU ID
      qty: item.qty, // Quantity
      price: item.price, // Price
    }));

    // Prepare the request payload
    const payload = {
      user_id: user_id, // User ID
      products: products, // Products array from localStorage
      cart_id: cart_id, // Cart ID (if any, otherwise a new cart will be created)
      combo_products: [], // Combo products array (if needed, or can be left empty)
    };

    // Make the API call to add to cart
    axios
      .post(`${api_url}/addtocart`, payload)
      .then((response) => {
        console.log(response.data, "Cart updated successfully");
        resolve(response.data); // Resolve with the response data
      })
      .catch((error) => {
        console.error("Error adding to cart", error);
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

export const fetchOrders = (orderId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: GET_ORDERS_SUMMARY_QUERY,
        variables: { orderId: { id: orderId } },
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//api function to get count in home page
export const home_product_type_count = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: PRODUCT_HOME_COUNT,        
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//api function to get the products in suggestion filter
export const filter_product_suggestion = (searchtext) => { //searchtext - text entered by user in the format "%enteredtext%" 
  const formattedSearchText = searchtext || "%love%";
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: SUGGESTEDPRODUCTS,
        variables: {
          "productName": formattedSearchText
        },        
      })
      .then((response) => {
        resolve(response); 
      })
      .catch((error) => {
        console.error(error, "error in getting the random suggested products in filter response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//api function to get the data for order fail paage 
export const payment_order_failed = (orderid) => { //searchtext - text entered by user in the format "%enteredtext%" 
  const orderId_failedpayment = orderid || "15b8c6e0-b845-11ef-b770-3d01ed4c81fd";
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: PAYMENTFAILED,
        variables: {
          "orderId": {
            "id": orderId_failedpayment
          }
        },        
      })
      .then((response) => {
        resolve(response); 
      })
      .catch((error) => {
        console.error(error, "error in getting the failed payment data");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//api function to get data for my account - Personal information, Manage address, wishlist, all orders....
export const myaccount_address_wishlist = (userprofileId) => {
  const defaultUserProfileId = "c1fe4440-9e2c-11ee-8623-87b2129b0c34";
  const userprofileIdUUID = userprofileId || defaultUserProfileId;
  const userprofileIdString = userprofileId || defaultUserProfileId;
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: MYACCOUNT,
        variables: {
          "userprofileIdUUID": userprofileIdUUID,
          "userprofileIdString": userprofileIdString
        },        
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to add user addressess...
export const addUserAddress = (address, user_id, id = null) => {
  return new Promise((resolve, reject) => {
    const requestBody = {
      address,
      user_id,
      id,
    };

    // Make the POST request with the body
    axios
      .post(`${api_url}/adduseraddress`, requestBody, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error, "error in getting the response");

        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to get the cart count and product list with generated_sku..
export const cart_products_count = (userid) => {
  Config.logGeneral && console.log(userid, " userid@@@@");
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        // query: CART_COUNT_QUERY,
        query: CART_COUNT_QUERY_NEW,
        variables: { UserId: { userprofileId: userid } },
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to get product description page info
export const productDescription = (skuId = "1006468") => {
  Config.logGeneral && console.log(skuId, " skuId*****");
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        // query: CART_COUNT_QUERY,
        query: PRODUCTDETAILS,
        variables: { conditionfilter: { skuId } },
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to get filter list
export const productfilterlistitem = () => {
  return new Promise((resolve, reject) => {
    // Your request body (filters)
    const requestBodyforupdateCartLatestPrice = {
      material: "Silver",
      category: "Jewellery",
    };

    // Assume you have the token available, you could get it from localStorage, cookies, etc.
    const token =
      localStorage.getItem("x-access-token") ||
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN1YmhhQGluZm9ncmFwaGljYW5hbHl0aWNzLmNvbSIsImlhdCI6MTczNjE0NzkzMywiZXhwIjoxNzM2MjM0MzMzfQ.Dqc0PmPlX61mFHeEx6Y-TQipFXpWFzLmw14mxqnHKAA"; // Replace with where you store your token

    // Make sure token exists before sending request
    if (!token) {
      reject("No token found in localStorage");
      return;
    }

    axios
      .post(`${api_url}/filterlist`, requestBodyforupdateCartLatestPrice, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
          "x-access-token": token, // Pass the token here in the headers
        },
      })
      .then((response) => {
        console.log(response, "response from filterlist API");
        if (response.status === 200) {
          console.dir(response);
          console.log("Successfully fetched filtered list!", response);
        } else {
          alert("Failed to fetch filtered list"); // Alert failure
        }
        resolve(response); // Resolve with the response if successful
      })
      .catch((error) => {
        console.error(error, "Error in fetching filtered list");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to update the cart /updatecart_latestprice
export const updateCartLatestPrice = (user_id) => {
  return new Promise((resolve, reject) => {
    const requestBodyforupdateCartLatestPrice = {
      user_id,
    };
    axios
      .post(
        `${api_url}/updatecart_latestprice`,
        requestBodyforupdateCartLatestPrice,
        {
          headers: {
            "Content-Type": "application/json", // Set the content type as JSON
          },
        }
      )
      .then((response) => {
        console.log(response, " response from cartMessageAdd");
        if (response.status === 200) {
          console.log(" successfully cart price updated!!!");
        } else {
          alert("Failed to update cart price"); // Alert failure
        }
        resolve(response); // Resolve with the response if successful
      })
      .catch((error) => {
        console.error(error, "error in updating the cart");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to update the addcart....
//{"user_id":"0d65ab60-b1f7-11ef-992d-fbacd0318d15","products":[{"sku_id":"SBA11688-92570000","qty":1,"price":4414}],"cart_id":"05644840-b2ce-11ef-a991-c1039639f4fd"}
export const addToCart = (user_id, products, cart_id) => {
  return new Promise((resolve, reject) => {
    const requestBodyforaddToCart = {
      user_id,
      products,
      cart_id,
    };
    axios
      .post(`${api_url}/addtocart`, requestBodyforaddToCart, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
      })
      .then((response) => {
        console.log(response, " response from cartMessageAdd");
        if (response.status === 200) {
          console.log(" successfully product added!!!");
        } else {
          alert("Failed to cart added"); // Alert failure
        }
        resolve(response); // Resolve with the response if successful
      })
      .catch((error) => {
        console.error(error, "error in adding the product to cart");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to get the cart details.....
export const cart_products_detils = (productList) => {
  console.log(productList, " productList***@@@@");
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: CART,
        // variables: { UserId: { userprofileId: userid } },
        variables: { productList: productList },
        // variables: { "productList": ["SBA11374-92570000","SBA11212-92570000_2.3"] }
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to get the useraddresses....
export const useraddressess = (userId) => {
  console.log(userId, " userId llll");
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: ALLUSERADDRESSESS,
        variables: { userprofileId: userId },
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//to resend email in successpage
export const resendorderemail = (orderId) => {
  console.log(orderId, " orderId for resendorderemail");

  return new Promise((resolve, reject) => {
    const requestBody = {
      order_id: orderId, // Pass the orderId dynamically into the request body
    };

    // Make the POST request with the body
    axios
      .post(`${api_url}/resendorderemail`, requestBody, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
      })
      .then((response) => {
        // Check if response has a message (similar to the fetch example)
        console.log(response, " response from resendorderemail");
        if (response.status === 200) {
          console.log("Your mail has been resending successfully");
          alert("Your email has been resent successfully!"); // Alert success
          resolve(response); // Resolve with the response if successful
        } else {
          console.log("Failed to resend the email");
          alert("Failed to resend the email. Please try again later."); // Alert failure
          reject("Unexpected response format");
        }
        
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        alert("Failed to resend the email. Please try again later.");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to add gift message..
export const cartMessageAdd = (cart_id, gift_from, gift_to, message) => {
  return new Promise((resolve, reject) => {
    const requestBody = {
      cart_id,
      gift_from,
      gift_to,
      message,
    };

    // Make the POST request with the body
    axios
      .post(`${api_url}/addgiftwrap`, requestBody, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
      })
      .then((response) => {
        // Check if response has a message (similar to the fetch example)
        console.log(response, " response from cartMessageAdd");
        if (response.status === 200) {
          console.log(" successfully cart message added!!!");
        } else {
          alert("Failed to add cart message"); // Alert failure
        }
        resolve(response); // Resolve with the response if successful
      })
      .catch((error) => {
        console.error(error, "error in adding the cart message");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to add address in cart address api

export const cartaddressadd = (
  user_id,
  address,
  cart_id,
  isguestlogin = false
) => {
  return new Promise((resolve, reject) => {
    const requestBody = {
      user_id: user_id,
      address: address, // Array of address objects
      cart_id: cart_id, // Cart ID
      isguestlogin: isguestlogin, // Guest login flag (true/false)
    };

    // Make the POST request with the body
    axios
      .post(`${api_url}/addaddress`, requestBody, {
        headers: {
          "Content-Type": "application/json", // Set the content type as JSON
        },
      })
      .then((response) => {
        // Check if response has a message (similar to the fetch example)
        console.log(response, " response from cartaddressadd");
        if (response.status === 200) {
          console.log(" successfully cart address added!!!");
        } else {
          alert("Failed to add cart address"); // Alert failure
        }
        resolve(response); // Resolve with the response if successful
      })
      .catch((error) => {
        console.error(error, "error in adding the cart address");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message
      });
  });
};

//to get the you_may_like
export const you_may_like = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: RECENTLY_VIEWED_AND_YOU_MAY_ALSO_LIKE,
        variables: {
          imgcondition: {
            imagePosition: 1,
            isdefault: true,
          },
          filterdata: {
            and: [
              {
                productMaterialsByProductSku: {
                  every: {
                    materialName: {
                      equalTo: "Silver",
                    },
                  },
                },
              },
              {
                productMaterialsByProductSkuExist: true,
              },
              {
                transSkuListsByProductId: {
                  every: {
                    discountPrice: {
                      lessThanOrEqualTo: 12444,
                      greaterThanOrEqualTo: 2444,
                    },
                  },
                },
              },
              {
                isactive: {
                  equalTo: true,
                },
              },
            ],
          },
          Conditiondatatranssku: {
            isdefault: true,
          },
          filterdata2: {
            and: [
              {
                productMaterialsByProductSku: {
                  every: {
                    materialName: {
                      equalTo: "Silver",
                    },
                  },
                },
              },
              {
                productMaterialsByProductSkuExist: true,
              },
              {
                isactive: {
                  equalTo: true,
                },
              },
            ],
          },
          Conditiondatatranssku2: {
            isdefault: true,
          },
          filterdatatranssku2: {
            skuId: {
              notEqualTo: "1024257",
            },
          },
        },
      })
      .then((response) => {
        console.log(response, "rettt");
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to get shipping charge..
export const shipping_charge = (cartId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(api_url + "/getshippingcharge", {
        cart_id: cartId,
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
//to get product list product items
export const productlistitems = (contd) => {
  console.log(contd," uuuuu")
  const filteredFilters = Object.entries(contd || {})
    .filter(([key, value]) => value !== null)  // Filter entries where value is not null
    .reduce((acc, [key, value]) => {
      if(key === 'Product Type'){
        
        acc[key.toLowerCase().split(" ").join("")] = value;  // Create a new object with the non-null key-value pairs
        return acc;
      }
      else if(key === 'Sort By'){
        
        acc['sortBy'] = value;  // Create a new object with the non-null key-value pairs
        return acc;
      }
      else{
        acc[key.toLowerCase()] = value;  // Create a new object with the non-null key-value pairs
        return acc;
      }
      
    }, {});
  
  let condition={"material":"Silver","category":"Jewellery","offset":0,"isJewellery":false};
  if(contd){
    condition={...condition,...filteredFilters}    
  }
  return new Promise((resolve, reject) => {
    axios
      .post(api_url + "/fetchproducts", condition)
      .then((response) => {
        console.log(response, "response from productlistitem call");
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to remove cart item...
export const removeCartItemData = (cartId, productId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api_url}/removecartitem`, {
        cart_id: cartId,
        product_id: productId,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error?.response ? error?.response?.data?.message : error.message
        ); // Reject with error message.
      });
  });
};

//to add wishlist
export const CartItemAddToWishList = (user_id, product_sku, product_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api_url}/addwishlist`, { user_id, product_sku, product_id })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(error.response ? error.response.data.message : error.message); // Reject with error message.
      });
  });
};

//user subscription add
export const subscribeUser = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api_url}/addemailsubscription`, { email })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.message); // Successful subscription
        }
      })
      .catch((error) => {
        // If the error is an HTTP error with a response, handle it here
        if (error.response) {
          if (error.response.status === 409) {
            reject(error.response.data.message); // Email already subscribed
          } else {
            reject(
              error.response.data.message ||
                "Subscription failed. Please try again."
            );
          }
        } else if (error.request) {
          // No response from server
          reject("No response from the server. Please try again later.");
        } else {
          // Other types of errors
          reject("An unexpected error occurred. Please try again.");
        }
      });
  });
};

//user email id already present/not
export const ismailsubscribed = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: GET_EMAIL_SUBSCRIPTIONS,
        variables: { email }, // Pass the email as a variable
      })
      .then((response) => {
        // Check if there are any errors in the response
        if (response.data.errors) {
          reject(
            response.data.errors.map((err) => err.message).join(", ") ||
              "Unknown error"
          );
        } else {
          // If there's no error, resolve with the nodes (email subscriptions)
          console.log(response, "allEmailSubscriptions.....mmm");
          resolve(response.data.data.allEmailSubscriptions.nodes);
        }
      })
      .catch((error) => {
        console.error(error, "Error in getting the response");
        // Reject with a more detailed error message, including GraphQL error message if present
        reject(
          error.response
            ? error.response.data.errors[0]?.message || error.message
            : error.message
        );
      });
  });
};
//for home notification
export const homeNotification = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: HOMENOTIFICATIONQUERY,
      })
      .then((response) => {
        // Check if there are any errors in the response
        if (response.data.errors) {
          reject(
            response.data.errors.map((err) => err.message).join(", ") ||
              "Unknown error"
          );
        } else {
          // The response contains a stringified JSON in cdnByPage.data, we need to parse it
          console.log(response.data, "response.data");

          try {
            // Parsing the stringified JSON from the response data
            const parsedData = JSON.parse(response.data.data.cdnByPage.data);

            // Find the "HomeNotifiaction" component from the parsed array
            const homeNotificationData = parsedData.find(
              (obj) => obj.component === "HomeNotifiaction"
            );

            if (homeNotificationData) {
              resolve(homeNotificationData); // Resolve with the "HomeNotifiaction" data
            } else {
              reject("HomeNotifiaction component not found");
            }
          } catch (error) {
            console.error("Error parsing the response data", error);
            reject("Error parsing the response data");
          }
        }
      })
      .catch((error) => {
        console.error(error, "Error in home notification response");
        // Reject with a more detailed error message, including GraphQL error message if present
        reject(
          error.response
            ? error.response.data.errors[0]?.message || error.message
            : error.message
        );
      });
  });
};

//for home response data
export const homeResponse = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: HOMENOTIFICATIONQUERY,
      })
      .then((response) => {
        // Check if there are any errors in the response
        if (response.data.errors) {
          reject(
            response.data.errors.map((err) => err.message).join(", ") ||
              "Unknownerror"
          );
        } else {
          // The response contains a stringified JSON in cdnByPage.data, we need to parse it
          config.logGeneral &&
            console.log(response.data, "homeResponseresponsedata");

          try {
            // Parsing the stringified JSON from the response data
            const parsedData = JSON.parse(response.data.data.cdnByPage.data);

            config.logGeneral && console.log("parsedhomedata", parsedData);

            if (parsedData) {
              resolve(parsedData); // Resolve with the "HomeNotifiaction" data
            } else {
              reject("HomeNotifiaction component not found");
            }
          } catch (error) {
            config.logGeneral &&
              console.error("Error parsing the response data", error);
            reject("Error parsing the response data");
          }
        }
      })
      .catch((error) => {
        config.logGeneral &&
          console.error(error, "Error in home notification response");
        // Reject with a more detailed error message, including GraphQL error message if present
        reject(
          error.response
            ? error.response.data.errors[0]?.message || error.message
            : error.message
        );
      });
  });
};

//TO GET CURRENCY AND COUNTRIES
export const currencyandcountrydata = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: CURRENCYANDCOUNTRIES,
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to check given pincode has shipping available or not...
export const pincodeAvailability = (pincode) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: GET_PINCODE_DETAILS,
        variables: { pincode: pincode },
      })
      .then((response) => {
        resolve(response); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};

//to get distinct country names and currency,symbol,fx rate....
export const getAllCountryNames = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: DISTINCTCOUNTRY,
      })
      .then((response) => {
        resolve(response?.data?.data?.allMasterCountries?.nodes); // Resolve with order nodes from response.
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject(
          error.response ? error.response.data.errors[0].message : error.message
        ); // Reject with error message.
      });
  });
};
export const checkPincodeAvailabilityAPI = (pincode, country) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL_graphql, {
        query: GET_DETAILS_WITH_PINCODE,
        variables: { pincode, country },
      })
      .then((response) => {
        console.log(
          response?.data?.data?.allPincodeMasters?.nodes,
          " pincodeandcountry check"
        );
        if (response?.data?.data?.allPincodeMasters?.nodes?.length == 0)
          reject("Shipping not available for the given Pincode and Country!!");
        else if (response?.data?.data?.allPincodeMasters?.nodes?.length > 0)
          resolve("Shipping available for the given Pincode and Country!!");
        // Resolve with order nodes from response.
        else
          reject("Shipping not available for the given Pincode and Country!!");
      })
      .catch((error) => {
        console.error(error, "error in getting the response");
        reject("Shipping not available for the given Pincode and Country!!");
      });
  });
};
