
import { ACTION_TYPE } from '../action/type'
const initialState = {
    isLoggedIn: false,
    userProfile_id: null,
    cartID:null,
    userProfile_email: null,
    accessToken: null,
    loading: false,
    error: null,
    loginModalLoading: true,
    loginOpenModal: false,
    loginModalDefaultType: 'login',
    loginModalType: ''
};


const authReducer = (state = initialState, action) => {    
    switch (action.type) {
        case ACTION_TYPE.LOGIN_REQUEST:
            return { ...state, loading: true, error: null };
        case ACTION_TYPE.LOGIN_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                isLoggedIn: true, 
                accessToken: action.payload.accessToken, 
                userProfile_id: action.payload.userprofile.id,
                userProfile_email:action.payload.userprofile.email,
                cartID:action.payload.shoppingCartId                
            };
        case ACTION_TYPE.LOGIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ACTION_TYPE.LOGOUT:
            return { ...state, isLoggedIn: false, userProfile_id: null,userProfile_email:null,accessToken:null,cartID:null };
        case ACTION_TYPE.LOGIN_MODAL:
            return { ...state, loginModalLoading: false, loginOpenModal: action.payload.isOpenModal,loginModalType: action.payload.modalType  };
        case ACTION_TYPE.LOGIN_MODAL_TYPE:
            return { ...state, loginModalLoading: false, loginModalType: action.payload.modalType  };
        default:
            return state;
    }
};

export default authReducer;