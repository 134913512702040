import { legacy_createStore as createStore, applyMiddleware } from "redux";
import rootReducer from "../reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import Config from "../../utilities/config";
import { ACTION_TYPE } from "../action/type";

//redux logger
const logger = (store) => (next) => (action) => {
  // if (action.type === ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS) {
  //   console.log("storeconsole", store);
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
  //   });
  // }
  // if (action.type === ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS) {
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
  //   });
  // }
  // if (action.type === ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS) {
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
  //   });
  // }
  // if (action.type === ACTION_TYPE.GET_SINGLE_ORDER_DATA_SUCCESS) {
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
  //   });
  // }
  // if (action.type === ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS) {
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
  //   });
  //   store.dispatch({
  //     type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
  //   });
  // }

  // Config.logGeneral && console.info("dispatching", action);
  let result = next(action);
  // Config.logGeneral && console.log("next state", store.getState());
  Config.logGeneral && console.groupEnd();
  return result;
};

// Middleware: Redux Persist Config
const persistConfig = {
  key: "root",
  storage: storage,
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(persistedReducer, applyMiddleware(thunk, logger));

let persistor = persistStore(store);

export { store, persistor };
