import React from "react";

import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";

function Accountpagesidemenu({ handleClick, activeSidebar }) {
  console.log("activeSidebar", activeSidebar.title === constants.SingleOrder);

  return (
    <main>
      <section>
        {accountMenu?.map((item) => (
          <ul key={item.key}>
            <li
              className={`font-medium p-2 mb-1 ${
                item.title === activeSidebar.title ||
                item?.subtitle === activeSidebar.title
                  ? `text-[#15AB9E] bg-[#EEF9F8]`
                  : item.title === "Logout"
                  ? "text-[#F44444]"
                  : "text-[#262626]"
              }  text-md py-4 cursor-pointer hover:text-[#15AB9E] hover:bg-[#EEF9F8]`}
              onClick={() => handleClick(item)}
            >
              {item.title}
            </li>
          </ul>
        ))}
      </section>
    </main>
  );
}

export default Accountpagesidemenu;
