import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { product_description } from '../redux/action/productAction';
//userProfile_id
import { Update_Cart_Latest_Price,Add_To_Cart } from '../redux/action/cartAction';
import { formatCurrency, getImage } from '../utilities/helper';
function ProductDetails() {
  const dispatch = useDispatch();
  // const [skuId,setskuId]=(null)
  const { accessToken, userProfile_id, userProfile_email,cartID } = useSelector((state) => state.authReducer);
  let Localstoragecart=localStorage.getItem('cartDetails');
  let checkproductexists=false;
  const { productDescriptionResponseSuccess,productDescriptionResponseError } = useSelector((state) => state.productReducer);  
  if(Localstoragecart){
    Localstoragecart=JSON.parse(Localstoragecart);
    checkproductexists=Localstoragecart?.products?.some((v)=>{
      return v.sku_id === productDescriptionResponseSuccess[0]?.generatedSku
    })
  }
  
  const productImageArray = productDescriptionResponseSuccess[0]?.productListByProductId?.productImagesByProductId?.nodes || [];
  let main_image = productImageArray?.filter((b) => {
    if (b.imagePosition === 1) {
      // return getImage(b.imageUrl,"1000X1000");
      return b;
    }
  }).map((e) => {
    return getImage(e.imageUrl, "1000X1000");
  }) || ["https://via.placeholder.com/500"];  
  let enquireLink = () => {    
    const currentUrl = window.location.href;
    const encodedUrl = encodeURIComponent(currentUrl);
    const whatsappLink = `https://wa.me/919952625252?text=Hi - ${encodedUrl}`;
    window.open(whatsappLink);
  };
  let cartAdd = () => {   
    if (userProfile_id) {
      let cartDetails = localStorage.getItem('cartDetails');      
      // Check if the cart exists in localStorage
      if (cartDetails) {
        // Parse cartDetails if it exists
        cartDetails = JSON.parse(cartDetails);        
        // Make sure that `productDescriptionResponseSuccess` has data
        if (productDescriptionResponseSuccess && productDescriptionResponseSuccess[0]) {
          let newProduct = {
            sku_id: productDescriptionResponseSuccess[0]?.generatedSku || '',
            qty: 1,
            price: productDescriptionResponseSuccess[0]?.markupPrice || ""
          };
    
          // Add new product to the products array
          cartDetails.products.push(newProduct);
    
          // Save updated cart back to localStorage
          localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
          alert('Cart updated successfully!');
        } else {
          alert('Product data is missing.');
        }
      } else {
        // If cartDetails does not exist, initialize a new cart
        if (productDescriptionResponseSuccess && productDescriptionResponseSuccess[0]) {
          cartDetails = {
            cart_id: cartID,
            user_id: userProfile_id,
            products: [{
              sku_id: productDescriptionResponseSuccess[0]?.generatedSku || '',
              qty: 1,
              price: productDescriptionResponseSuccess[0]?.markupPrice || ""
            }]
          };
    
          // Save the new cart to localStorage
          localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
          alert('Cart initialized with the first item!');
        } else {
          alert('Product data is missing.');
        }
      }
    
      // Call the APIs to update the cart and its price
      dispatch(Update_Cart_Latest_Price(userProfile_id));
      dispatch(Add_To_Cart(userProfile_id, cartDetails.products, cartDetails.cart_id));
    
      // Redirect to cart page
      window.location.href = "/styloriSilver/cart";
    }
    else{
      let cartDetails = {
        "cart_id":"",
        "user_id":"",
        "products":
          [{sku_id: productDescriptionResponseSuccess?.generatedSku || '',
            qty: 1,
            price: productDescriptionResponseSuccess[0]?.markupPrice || ""}
          ]
        };
      localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
      window.location.href="/styloriSilver/cart";
    }
  };
  let buyNow = () => {    
        if (userProfile_id) {
          let cartDetails = localStorage.getItem('cartDetails');      
          // Check if the cart exists in localStorage
          if (cartDetails) {
            // Parse cartDetails if it exists
            cartDetails = JSON.parse(cartDetails);        
            // Make sure that `productDescriptionResponseSuccess` has data
            if (productDescriptionResponseSuccess && productDescriptionResponseSuccess[0]) {
              let newProduct = {
                sku_id: productDescriptionResponseSuccess[0]?.generatedSku || '',
                qty: 1,
                price: productDescriptionResponseSuccess[0]?.markupPrice || ""
              };
        
              // Add new product to the products array
              cartDetails.products.push(newProduct);
        
              // Save updated cart back to localStorage
              localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
              alert('Cart updated successfully!');
            } else {
              alert('Product data is missing.');
            }
          } else {
            // If cartDetails does not exist, initialize a new cart
            if (productDescriptionResponseSuccess && productDescriptionResponseSuccess[0]) {
              cartDetails = {
                cart_id: cartID,
                user_id: userProfile_id,
                products: [{
                  sku_id: productDescriptionResponseSuccess[0]?.generatedSku || '',
                  qty: 1,
                  price: productDescriptionResponseSuccess[0]?.markupPrice || ""
                }]
              };
        
              // Save the new cart to localStorage
              localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
              alert('Buy Now initialized with the first item!');
            } else {
              alert('Product data is missing.');
            }
          }
        
          // Call the APIs to update the cart and its price
          dispatch(Update_Cart_Latest_Price(userProfile_id));
          dispatch(Add_To_Cart(userProfile_id, cartDetails.products, cartDetails.cart_id));
        
          // Redirect to cart page
          window.location.href = "/styloriSilver/checkout";
        }
        else{
          let cartDetails = {
            "cart_id":"",
            "user_id":"",
            "products":
              [{sku_id: productDescriptionResponseSuccess?.generatedSku || '',
                qty: 1,
                price: productDescriptionResponseSuccess[0]?.markupPrice || ""}
              ]
            };
          localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
          window.location.href="/styloriSilver/checkout";
        }
      };
  useEffect(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const skuId = searchParams.get('sku_id');
    
    if (skuId) {
      dispatch(product_description(skuId)); // Dispatch the action with the skuId
    }
  }, []); // Empty dependency array ensures this runs only once on mount
  return (
    productDescriptionResponseSuccess?.length > 0 &&
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto p-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">

          <div>
            <div class="mb-4">
              {/* <img src="https://via.placeholder.com/500" alt="Product" class="w-full rounded-lg shadow-md" /> */}
              {/* https://assets.stylori.com/product/SBA10190/1000X1000/SBA10190-2G.webp */}
              <img src={main_image[0]} alt="Product" class="w-full rounded-lg shadow-md" />
            </div>
            <div className="grid grid-cols-3 gap-2">
              {productImageArray?.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={getImage(image.imageUrl, "1000X1000")}  // Assuming image is a URL in the productImageArray
                    alt="https://via.placeholder.com/500"
                    className="w-full h-auto rounded-lg shadow-md cursor-pointer"
                  />
                );
              })}
            </div>

          </div>


          <div>
            <h1 class="text-2xl font-bold text-gray-800">{productDescriptionResponseSuccess[0]?.productListByProductId?.productName}</h1>
            <p class="text-xl text-green-600 font-semibold mt-4">{formatCurrency(productDescriptionResponseSuccess[0]?.markupPrice)}</p>
            <p class="text-sm text-gray-500">Tax Included. Ships by 24 November 2024.</p>

            <div class="mt-4 flex items-center space-x-4">
              <div class="flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c1.38 0 2.5-1.12 2.5-2.5S13.38 3 12 3 9.5 4.12 9.5 5.5 10.62 8 12 8z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 11v2a1 1 0 001 1h4M3 3l18 18" />
                </svg>
                <p class="text-sm text-gray-600">{productDescriptionResponseSuccess[0]?.purity} / {productDescriptionResponseSuccess[0]?.metalColor}</p>
              </div>
              <div class="flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16c0-1.657 1.343-3 3-3h1.5a2.5 2.5 0 015 0H14a3 3 0 013 3" />
                </svg>
                <p class="text-sm text-gray-600">Weight: {productDescriptionResponseSuccess[0]?.skuWeight}g</p>
              </div>
            </div>

            <div class="mt-6">
              <button class="bg-green-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-green-700" onClick={checkproductexists ? () => {}:buyNow}>{checkproductexists ? `In Bag`:`Buy Now` }</button>
              <button class="ml-4 bg-gray-200 text-gray-800 px-6 py-2 rounded-lg shadow-md hover:bg-gray-300" onClick={checkproductexists ? () => {}:cartAdd}>{checkproductexists ? `In Cart`:`Add to Cart` }</button>
            </div>

            <div class="mt-4">
              <button class="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700" onClick={enquireLink}>
                Get In Touch
              </button>
            </div>

            <div class="mt-8">
              <h2 class="text-lg font-semibold text-gray-800">Description</h2>
              <p class="text-sm text-gray-600 mt-2">{productDescriptionResponseSuccess[0]?.transSkuDescriptionsBySkuId?.nodes[0]?.skuDescription}</p>
              <ul class="mt-4 text-sm text-gray-600 space-y-1">
                <li>Necklace: Height - 135 mm, Width - 100 mm</li>
                <li>Earrings: Height - 35 mm, Width - 20 mm</li>
              </ul>
            </div>

            <div class="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
              <div class="text-center">
                <img src="https://via.placeholder.com/50" alt="Icon" class="mx-auto" />
                <p class="text-sm text-gray-600 mt-2">Certified Jewellery</p>
              </div>
              <div class="text-center">
                <img src="https://via.placeholder.com/50" alt="Icon" class="mx-auto" />
                <p class="text-sm text-gray-600 mt-2">Diverse Styles</p>
              </div>
              <div class="text-center">
                <img src="https://via.placeholder.com/50" alt="Icon" class="mx-auto" />
                <p class="text-sm text-gray-600 mt-2">Hypoallergenic</p>
              </div>
              <div class="text-center">
                <img src="https://via.placeholder.com/50" alt="Icon" class="mx-auto" />
                <p class="text-sm text-gray-600 mt-2">Secured Payment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >


  )
}

export default ProductDetails