import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import config from "../utilities/config";
import {
    CartItemAddToWishList,
    ismailsubscribed,
    subscribeUser,
    getAllCountryNames,
    checkPincodeAvailabilityAPI,
    add_to_cart,
  } from "../utilities/api";
import constants from "../utilities/constants";
import { product_list_filter,product_list_item } from '../redux/action/productAction';
import {
    getCartpagedata,
    getCartpagedata_count,
    youMayLikeData,
    removeCartItem,
    getCartpagedata_count_without_login,
    removeCartItemWithoutlogin,
  } from "../redux/action/cartAction";
import {getHomepagenotification} from '../redux/action/homeAction';
import { formatCurrency, getImage } from '../utilities/helper';

function ProductList() {
    const dispatch = useDispatch();
    const filterList=["Collection","Theme","Occasion","Style","Finish","Product Type","Sort By"];
    // Handle filter change
    const handleFilterChange = (category, value) => {
        setSelectedFilters(prevState => {
            // If the same item is selected, unselect it
            const newSelection = prevState[category] === value ? null : value;
            return { ...prevState, [category]: newSelection };
        });
    };
      // const [skuId,setskuId]=(null)
    const { accessToken, userProfile_id, userProfile_email,cartID } = useSelector((state) => state.authReducer);
    const { homeNotificationResponseSuccess,homeNotificationResponseError} = useSelector((state) => state.homeNotificationReducer);
    //to get the cart count...
      const {
        cart_items_numberResponesLoading,
        cart_items_numberResponseSuccess,
        cart_items_numberResponseSuccess_info,
        cart_items_numberResponseError,
      } = useSelector((state) => state.cartNumberReducer);
      const [selectedFilters, setSelectedFilters] = useState({
        "Collection": null,
        "Theme": null,
        "Occasion": null,
        "Style": null,
        "Finish": null,
        "Product Type": null,
        "Sort By":null
    });
    const {productListResponseSuccess,productListResponseError} = useSelector((state) => state.productFilterListReducer); 
    const {productListitemsResponseSuccess,productListitemsResponseError} = useSelector((state) => state.productFilterListitemsReducer);
    const productsToDisplay=productListitemsResponseSuccess?.data?.allProductLists || [];
    const cartId = cart_items_numberResponseSuccess
    ? cart_items_numberResponseSuccess?.nodes[0]?.id
    : "";
    console.log(productListResponseSuccess,"productListResponseSuccess")   
    console.log(homeNotificationResponseSuccess,"homeNotificationResponseSuccess")
    console.log(productListitemsResponseSuccess,"productListitemsResponseSuccess")
    var filters = filterList.reduce((acc, filterType) => {
        acc[filterType] = productListResponseSuccess[filterType] || [];
        return acc;
      }, {});
      console.log(filters,"filtersyy")
      filters={...filters,"Sort By":[{name:"New to Stylori"},{name:"Featured"},{name:"Price Low to High"},{name:"Price High to Low"},{name:"Ready to Ship"},{name:"Best Seller"}]}     
    
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    const form = e.target;
    form.submit(); // Submit the form programmatically
  };
  const handleRemoveItem = (productSku) => {
      // Dispatch action to remove item from cart
      if (userProfile_id) {
        dispatch(removeCartItem(cartId, productSku, userProfile_id));
      } else {
        alert(`remove cart item for not login case!!`);
        dispatch(removeCartItemWithoutlogin(productSku));
      }
    };
    const handleAddToWishlist = (productSku, productId) => {
        // Call API to add the product to the wishlist
        CartItemAddToWishList(userProfile_id, productSku, productId)
          .then((response) => {
            // On success, log the response and remove the item from the cart
            config.logGeneral &&
              console.log("Product added to wishlist:", response);
            // Remove the item from cart after adding it to the wishlist
            handleRemoveItem(productSku);
          })
          .catch((error) => {
            // Handle any errors
            config.logGeneral &&
              console.error("Error adding item to wishlist:", error);
          });
      };
  useEffect(() => {
    dispatch(getHomepagenotification());      
        dispatch(product_list_filter()); // Dispatch the action with the skuId
        dispatch(product_list_item(selectedFilters));      
    }, [selectedFilters]);

  return (
    <body className="bg-gray-100">
    <div className="container mx-auto p-4">
        
        <h1 className="text-3xl font-bold mb-6">Necklace and Earrings</h1>

        <div className="flex gap-6">
            
        <div className="w-1/4 bg-white p-4 rounded shadow">
            <h2 className="text-lg font-semibold mb-4">Applied Filters</h2>
            <div className="flex flex-wrap gap-2 mb-4">
                {Object.entries(selectedFilters).map(([category, selectedItem]) => (
                    selectedItem && (
                        <span key={category} className="px-2 py-1 bg-gray-200 rounded text-sm">
                            {selectedItem}
                        </span>
                    )
                ))}
                {Object.values(selectedFilters).some(item => item !== null) && (
                    <button 
                        className="text-blue-500 text-sm"
                        onClick={() => setSelectedFilters({
                            "Collection": null,
                            "Theme": null,
                            "Occasion": null,
                            "Style": null,
                            "Finish": null,
                            "Product Type": null
                        })}
                    >
                        Clear Filter
                    </button>
                )}
            </div>

            <h2 className="text-lg font-semibold mb-4">Pricing Range</h2>
            <input type="range" className="w-full mb-4" />
            
            <div className="flex flex-col space-y-4">
                {filterList.map((filterCategory) => (
                    <div key={filterCategory}>
                        <h2 className="text-lg font-semibold mb-4">{filterCategory}</h2>
                        
                        <div className="space-y-2">
                            {filters[filterCategory]?.map((option, idx) => (
                                <label key={idx} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox"
                                        onChange={() => handleFilterChange(filterCategory, option.name)}
                                        checked={selectedFilters[filterCategory] === option.name}
                                    />
                                    <span>{option.name}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>

           
            <div className="w-3/4">
                        {productsToDisplay?.length > 0 ? (
                            <div className="grid grid-cols-3 gap-6">
                                {productsToDisplay.map((product) => {
                                    console.log(product,"productasd")
                                    const { productName, trans_sku_lists, productImagesByProductId,selling_qty,is_featured } = product;
                                    const productImage = productImagesByProductId[0]?.imageUrl || "https://via.placeholder.com/500";
                                    const productUrl = trans_sku_lists[0]?.skuUrl || "#";
                                    const sellingPrice = trans_sku_lists[0]?.sellingPrice || trans_sku_lists[0]?.discountPrice;
                                    
                                    return (
                                        <div key={product.productId} className="relative bg-white p-4 rounded shadow">
                                            {selling_qty && <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 text-sm rounded">
                                                Best Seller
                                            </div>}
                                            {is_featured && <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 text-sm rounded">
                                                Limited Offer
                                            </div>}
                                            <button 
                                                className="absolute top-2 right-2 text-red-500 hover:text-red-600 focus:outline-none"
                                                aria-label="Add to Wishlist"
                                                onClick={() => {                                                    
                                                    handleAddToWishlist(
                                                        product?.trans_sku_lists[0]?.generatedSku,
                                                        product?.productId
                                                    );
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 21l-1-1-7-7C3.735 11.392 3 9.53 3 7.5 3 4.42 5.42 2 8.5 2c1.74 0 3.41.81 4.5 2.09C14.09 2.81 15.76 2 17.5 2 20.58 2 23 4.42 23 7.5c0 2.03-.735 3.892-2 5.5l-7 7-1 1z" />
                                                </svg>
                                            </button>

                                            <img 
                                                src={getImage(productImage, "700X700")} 
                                                alt={productName} 
                                                className="mb-4 rounded"
                                            />
                                            <h3 className="text-lg font-semibold">{productName}</h3>
                                            <p className="text-gray-600">{formatCurrency(sellingPrice)}                                                
                                            </p>
                                            <a href={productUrl} className="text-blue-500 text-sm">View Product</a>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="text-center py-6">
                                <p className="text-xl font-semibold text-gray-600">No products to list</p>
                            </div>
                        )}
                    </div>
        </div>
    </div>
</body>
  );
}

export default ProductList;
