// src/components/Footer.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./LoginModal";
import Facebook from "../product_img/facebook.svg";
import Instagram from "../product_img/instagram.svg";
import Linkedin from "../product_img/linkedin.svg";
import Twitter from "../product_img/twitter.svg";
import Youtube from "../product_img/youtube.svg";
import {
  subscribeUserWithMail,
  clearMessage,
} from "../redux/action/homeAction";

const Footer = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter a valid email address.");
      return;
    }

    // Dispatch the action with the email
    dispatch(subscribeUserWithMail(email));

    // Optional: Reset the input field after submission
    setEmail("");
  };
  const { mailsubscriptionResponseSuccess, mailsubscriptionResponseError } =
    useSelector((state) => state.homeReducer);
  console.log(
    mailsubscriptionResponseSuccess,
    "mailsubscriptionResponseSuccess",
    mailsubscriptionResponseError
  );
  useEffect(() => {
    // If there's a success or error message, set a timeout to clear it after 5 seconds
    if (mailsubscriptionResponseSuccess || mailsubscriptionResponseError) {
      const timeout = setTimeout(() => {
        dispatch(clearMessage());
      }, 5000);

      return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
    }
  }, [
    mailsubscriptionResponseSuccess,
    mailsubscriptionResponseError,
    dispatch,
  ]);
  return (
    
    <div className="bg-[#06ab9f] text-white pt-14 pb-8 w-full">
      <div className="container mx-auto grid grid-cols-3">
          <div className='lg:grid lg:grid-cols-3 hidden w-full col-span-2 border-r border-[#3CCEC1]'>
            <div className='col-span-1 sm:block'>
              <ul>
                <li className='py-2 text-lg'><b>Shop by Categories</b></li>
                <li className='py-2'>Jewellery</li>
                <li className='py-2'>Style</li>
                <li className='py-2'>Collections</li>
                <li className='py-2'>Finish</li>
                <li className='py-2'>Ready to Shop</li>
              </ul>
              
            </div>
            <div className='col-span-1 sm:block'>
            <ul>
                <li className='py-2 text-lg'><b>About us</b></li>
                <li className='py-2'>NAC Jewellers</li>
                <li className='py-2'>Stylori</li>
                <li className='py-2'>Privacy Policy</li>
                <li className='py-2'>Sitemap</li>
                <li className='py-2'>My Account</li>
              </ul>
            </div>
            <div className='col-span-1 sm:block'>
              <ul>
                <li className='py-2 text-lg'><b>Contact us</b></li>
                <li className='py-2'>Whatsapp: +91 99526 25252</li>
                <li className='py-2'>Email: hello@stylori.com</li>
                <li className='py-2'>Customer Care: 1800 102 0330</li>
                <li className='py-2'>Seattle, WA</li>
              </ul>
            </div>
          </div> 
          
        <div className="grid grid-cols-3 w-full col-span-2 border-r border-[#3CCEC1] lg:hidden">
          <div className="col-span-1 sm:block">
            <ul>
              <li className="py-2 text-lg">
                <b>Shop by Categories</b>
              </li>
              <li className="py-2">Jewellery</li>
              <li className="py-2">Style</li>
              <li className="py-2">Collections</li>
              <li className="py-2">Finish</li>
              <li className="py-2">Ready to Shop</li>
            </ul>
          </div>
          <div className="col-span-1 sm:block">
            <ul>
              <li className="py-2 text-lg">
                <b>About us</b>
              </li>
              <li className="py-2">NAC Jewellers</li>
              <li className="py-2">Stylori</li>
              <li className="py-2">Privacy Policy</li>
              <li className="py-2">Sitemap</li>
              <li className="py-2">My Account</li>
            </ul>
          </div>
          <div className="col-span-1 sm:block">
            <ul>
              <li className="py-2 text-lg">
                <b>Contact us</b>
              </li>
              <li className="py-2">Whatsapp: +91 99526 25252</li>
              <li className="py-2">Email: hello@stylori.com</li>
              <li className="py-2">Customer Care: 1800 102 0330</li>
              <li className="py-2">Seattle, WA</li>
            </ul>
          </div>
        </div>
        <div className="w-full sm-block">
          <div class="px-8">
            <h2 className="text-[32px] font-Cormorant">
              You can be one step ahead
            </h2>
            <h6 className="text-xl pb-5">
              Sign up to hear about our updates on the dot.
            </h6>
            <input
              type="mail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Your email address"
              className="text-[#A59D9D] text-[16px] py-2 px-4 border-[#BEBEBE] w-3/4 focus:outline-none"
            />
            <button
              className="bg-[#EFEBE8] text-[#222222] py-2 px-6 uppercase font-semibold text-[16px]"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
            {mailsubscriptionResponseSuccess && (
              <p className="text-green-500">
                {mailsubscriptionResponseSuccess}
              </p>
            )}
            {mailsubscriptionResponseError && (
              <p className="text-red-500">{mailsubscriptionResponseError}</p>
            )}

            <div className="flex justify-end pt-10 items-center">
              <Link to="/">
                <img src={Instagram} alt="Instagram" className="px-3" />
              </Link>
              <Link to="/">
                <img src={Twitter} alt="Twitter" className="px-3" />
              </Link>
              <Link to="/">
                <img src={Facebook} alt="Facebook" className="px-3" />
              </Link>
              <Link to="/">
                <img src={Youtube} alt="Youtube" className="px-3" />
              </Link>
              <Link to="/">
                <img src={Linkedin} alt="Linkedin" className="pl-3" />
              </Link>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 w-full col-span-3">
          <ul className="pt-10 col-span-1">
            <li className="text-sm">© 2021 Stylori Silver</li>
          </ul>
          <ul className="pt-10 flex col-span-2">
            <li
              className="pr-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/privacyPolicy")}
            >
              PRIVACY POLICY
            </li>
            <li className="px-2 text-sm"> • </li>
            <li
              className="px-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/termsconditions")}
            >
              {" "}
              TERMS OF USE{" "}
            </li>
            <li className="px-2 text-sm"> • </li>
            <li
              className="px-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/sitemap")}
            >
              {" "}
              SITEMAP{" "}
            </li>
            <li className="px-2 text-sm"> • </li>
            <li
              className="px-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/deliveryreturns")}
            >
              {" "}
              Shipping & Return{" "}
            </li>
            <li className="px-2 text-sm"> • </li>
            <li
              className="pl-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/faqs")}
            >
              {" "}
              FAQs
            </li>
            <li className="px-2 text-sm"> • </li>
            <li
              className="pl-2 uppercase text-sm cursor-pointer"
              onClick={() => Navigate("/productcare")}
            >
              {" "}
              Product Care
            </li>
          </ul>
        </div>
      </div>

      
    </div>
  );
};

export default Footer;
