import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cart from "../product_img/cart.svg";
import Myaccount from "../product_img/profileicon.svg";
import Wishlist from "../product_img/wishlist.svg";
import close from "../product_img/close.svg";
import search from "../product_img/searchicon.svg";
import Sparkling from "../product_img/sparkling.svg";
import kundan from "../product_img/kundan.webp";
import pichwai from "../product_img/pichwai.webp";
import varshin from "../product_img/varshin.webp";

const Header = ({ headerResponseSuccess = [] }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Handle search query change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      // Filter results based on query
      const filteredResults = headerResponseSuccess.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div className="lg:flex text-[#1C1C1C] items-center space-x-4">
      {/* Search Section */}
      <div className=" flex items-center cursor-pointer">
        {/* Search Icon */}
        {!isSearchOpen && (
          <img
            src={search}
            alt="Search"
            className="pr-3"
            onClick={() => setIsSearchOpen(true)}
          />
        )}

        {/* Search Bar */}
        {isSearchOpen && (
          <div className="absolute top-3 bottom-0 left-1/4 right-1/2 m-auto w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="w-full pl-12 pr-12 py-3 rounded-md focus:outline-none bg-[#F5F5F5] text-[#242424]"
              autoFocus
            />
            {/* Search Icon Inside the Input Field */}
            <img
              src={search}
              alt="Search"
              className="absolute left-3 top-6 transform -translate-y-1/2"
            />
            {/* Close Button */}
            <button
              onClick={() => setIsSearchOpen(false)}
              className="absolute top-6 right-3 transform -translate-y-1/2"
            >
              <img src={close} alt="Close" />
            </button>

            {/* Search Results Box */}
            {searchQuery && (
              <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                {searchResults.length > 0 ? (

            "No results Found"
               
                ) : (
                   
                    <div className="bg-white w-11/12 md:w-full p-6 rounded-md shadow-lg">
                      <h2 className="text-lg font-medium mb-4 uppercase">Suggestions</h2>
                      <hr className="border-[#06ab9f] border"></hr>
                      <div className="flex flex-wrap gap-3 pt-5 pb-3">
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Pendants</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Earrings</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Rings</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Bangles</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Bracelets</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Nosepins</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Kada</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Bridal Accessories</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Short Necklace</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Long Necklace</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Idols</p>
                        </div>
                        <div className="flex border w-auto rounded-full py-1 px-2">
                          <img src={Sparkling} className="pr-1" />
                          <p className="text-base font-medium text-[#262626]">Anklet</p>
                        </div>
                      </div>
                      <h2 className="text-lg font-medium mb-4 uppercase pt-5">Products</h2>
                      <hr className="border-[#06ab9f] border"></hr>
                      <div className="grid grid-cols-4 gap-3 pt-5">
                    <div className="flex col-span-1 items-center gap-2">
                      <img src={kundan} className="size-16"/>
                      <div className="">
                        <h3 className="text-[#242424] text-sm font-semibold pb-1">Kundan earrings</h3>
                        <p className="text-[#6A6A6A] text-xs font-medium">₹ 5,800</p>
                      </div>
                    </div>
                    <div className="flex col-span-1 items-center gap-2">
                      <img src={pichwai} className="size-16"/>
                      <div className="">
                        <h3 className="text-[#242424] text-sm font-semibold pb-1">Muhali Pichwai Earrings</h3>
                        <p className="text-[#6A6A6A] text-xs font-medium">₹ 5,800</p>
                      </div>
                    </div>
                    <div className="flex col-span-1 items-center gap-2">
                      <img src={varshin} className="size-16"/>
                      <div className="">
                        <h3 className="text-[#242424] text-sm font-semibold pb-1">Varshin Earrings</h3>
                        <p className="text-[#6A6A6A] text-xs font-medium">₹ 9,300</p>
                      </div>
                    </div>
                    <div className="flex col-span-1 items-center gap-2">
                      <img src={kundan} className="size-16"/>
                      <div className="">
                        <h3 className="text-[#242424] text-sm font-semibold pb-1">Nikitha Krish Earrings</h3>
                        <p className="text-[#6A6A6A] text-xs font-medium">₹ 9,300</p>
                      </div>
                    </div>
                  </div>
                     
                    </div>
                  
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Other Header Elements */}
      <Link to="#" className="hover:text-yellow-400 pr-3">
        <img src={Cart} alt="Cart" />
      </Link>
      <Link
        to="/wishlist"
        className="hover:text-yellow-400 pr-3 hidden lg:block"
      >
        <img src={Wishlist} alt="Wishlist" className="w-[26px]" />
      </Link>
      <Link
        to="/"
        className="hover:text-yellow-400 w-[32px] hidden lg:block"
      >
        <img src={Myaccount} alt="My Account" className="w-[26px]" />
      </Link>
    </div>
  );
};

export default Header;
