import React from "react";

const Button = ({
  label,
  onClick,
  type = "button",
  variant = "primary",
  modelbtn = true,
  width = null,
}) => {
  const baseStyles = "px-4 py-2 rounded-lg  font-semibold w-full mx-2";
  const variantStyles =
    variant === "primary"
      ? "bg-[#06AB9F]  text-white"
      : "border border-[#353535] text-[#353535]";

  return (
    <button
      type={type}
      onClick={() => onClick(label)}
      className={`${
        modelbtn
          ? baseStyles
          : `text-[16px] py-2 ${
              width === "full" ? "px-14 me-2" : "px-2 border border-[#C6C6C6] "
            }`
      } ${variantStyles}`}
    >
      {label}
    </button>
  );
};

export default Button;
