import { ACTION_TYPE } from './type';
import { cart_products_detils, cart_products_count, you_may_like, removeCartItemData,updateCartLatestPrice,addToCart } from "../../utilities/api";
import { getValue } from '../../utilities/helper';
import Config from "../../utilities/config";



const allTransSkuLists = {
    "nodes": [
        {
            "generatedSku": "SNLC10877-92570000",
            "purity": "92.5",
            "metalColor": "Silver",
            "discountPrice": 33136.4,
            "discount": null,
            "minOrderQty": null,
            "maxOrderQty": null,
            "skuId": "1023573",
            "markupPrice": 29822.760000000002,
            "skuUrl": "/styloriSilver/jewellery/long necklace/Erupting-Zircon Necklace Set?sku_id=1023573",
            "productListByProductId": {
                "productId": "SNLC10877",
                "productName": "Erupting Zircon Necklace Set",
                "isactive": true,
                "productImagesByProductId": {
                    "nodes": [
                        {
                            "imageUrl": "product/SNLC10877/SNLC10877-1S.jpg"
                        }
                    ]
                },
                "productType": "Long Necklace",
                "productMaterialsByProductSku": {
                    "nodes": [
                        {
                            "materialName": "Silver"
                        }
                    ]
                },
                "productDiamondsByProductSku": {
                    "nodes": []
                },
                "productGemstonesByProductSku": {
                    "nodes": []
                }
            },
            "skuWeight": 50.47,
            "diamondType": null,
            "skuSize": null
        },
        {
            "generatedSku": "SE11544-92570000",
            "purity": "92.5",
            "metalColor": "Gold",
            "discountPrice": 7836.4,
            "discount": null,
            "minOrderQty": null,
            "maxOrderQty": null,
            "skuId": "1024257",
            "markupPrice": 7052.759999999999,
            "skuUrl": "/styloriSilver/jewellery/earrings/Margaric-Colorful Ear Stud?sku_id=1024257",
            "productListByProductId": {
                "productId": "SE11544",
                "productName": "Margaric Colorful Ear Stud",
                "isactive": true,
                "productImagesByProductId": {
                    "nodes": [
                        {
                            "imageUrl": "product/SE11544/SE11544-1G.jpg"
                        }
                    ]
                },
                "productType": "Earrings",
                "productMaterialsByProductSku": {
                    "nodes": [
                        {
                            "materialName": "Silver"
                        }
                    ]
                },
                "productDiamondsByProductSku": {
                    "nodes": []
                },
                "productGemstonesByProductSku": {
                    "nodes": []
                }
            },
            "skuWeight": 15.54,
            "diamondType": null,
            "skuSize": null
        }
    ]
}


export const getCartpagedata = (form_data) => async (dispatch) => {
    console.log(form_data, "form_data for getCartpagedata")
    dispatch({ type: ACTION_TYPE.GET_CART_DATA_REQUEST });

    try {
        // const  data  = await login(form_data);


        dispatch({
            type: ACTION_TYPE.GET_CART_DATA_RESPONSE_SUCCESS,
            payload: {
                cartResponseSuccess: allTransSkuLists
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.GET_CART_DATA_RESPONSE_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

//TO GET CART COUNT...
export const getCartpagedata_count = (userid) => async (dispatch) => {
    Config.logGeneral && console.log(userid, "userid for getCartpagedata_count")
    dispatch({ type: ACTION_TYPE.CART_COUNT_REQUEST });

    try {
        const cart_count_data = await cart_products_count(userid);
        Config.logGeneral && console.log(cart_count_data, "cart_count_data 77777777")

        dispatch({
            type: ACTION_TYPE.CART_COUNT_SUCCESS,
            payload: {
                cartCountResponseSuccess: cart_count_data?.data?.data?.allShoppingCarts,
                cartCountResponseSuccess_info: getValue(cart_count_data?.data?.data?.allShoppingCarts, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes'),
                //getValue(cartItems, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes');getValue(cart_count_data?.data?.data?.allShoppingCarts, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes');
                // cartCountResponseSuccess:cart_count_data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.CART_COUNT_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

//TO GET CART COUNT WITHOUT LOGIN...
export const getCartpagedata_count_without_login = () => async (dispatch) => {
    Config.logGeneral && console.log("getCartpagedata_count_without_login")
    dispatch({ type: ACTION_TYPE.CART_COUNT_REQUEST });

    try {
        const cart_count_data_without_login = await cart_products_detils(["SBA11374-92570000", "SBA11212-92570000_2.3"]);
        console.log(cart_count_data_without_login, "cart_count_data_without_login99")
        let prod_details = cart_count_data_without_login?.data?.data?.allTransSkuLists?.nodes;
        let shipo = prod_details.map((e) => {
            return {
                "qty": 1,
                "productSku": e.generatedSku,
                "price": e.markupPrice,
                "comboMainProduct": null,
                "transSkuListByProductSku": {
                    "discount": e.discount,
                    "discountPrice": e.discountPrice,
                    "generatedSku": e.generatedSku,
                    "isReadyToShip": e.isReadyToShip,
                    "markupPrice": e.markupPrice,
                    "purity": e.purity,
                    "skuSize": e.skuSize,
                    "skuUrl": e.skuUrl,
                    "productListByProductId": {
                        "productId": e.productListByProductId.productId,
                        "productName": e.productListByProductId.productName,
                        "productImagesByProductId": e.productListByProductId.productImagesByProductId
                    }
                }
            }

        })
        let gross_amt = prod_details.reduce((acc, cur, index) => { return acc + parseInt(cur.markupPrice) }, 0)
        let ship_format = {
            "nodes": [
                {
                    "userprofileId": "",
                    "shippingCharge": 0,//if user not log in 
                    "id": "",
                    "shoppingCartItemsByShoppingCartId": {},
                    "status": "pending",
                    "grossAmount": gross_amt,
                    "netAmount": gross_amt,
                    "discount": null,
                    "discountedPrice": gross_amt
                }
            ]
        };
        ship_format.nodes[0].shoppingCartItemsByShoppingCartId.nodes = shipo;


        Config.logGeneral && console.log(cart_count_data_without_login, "cart_count_data_without_login7")
        Config.logGeneral && console.log(ship_format, "ship_format")


        dispatch({
            type: ACTION_TYPE.CART_COUNT_WITHOUT_LOGIN,
            payload: {
                cartCountResponseSuccess: ship_format,
                cartCountResponseSuccess_info: getValue(ship_format, 'nodes.0.shoppingCartItemsByShoppingCartId.nodes'),
            }
        });
        console.log("77777")
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.CART_COUNT_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

// Action to remove item from cart
export const removeCartItem = (cartId, productId, userid) => async (dispatch) => {
    try {
        // Call the API to remove the product from the cart
        const response = await removeCartItemData(cartId, productId);

        if (response.data.message === "You removed this product successfully") {
            dispatch({
                type: ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS,
                payload: productId,  // Send the product ID that was removed
            });

            // After successfully removing the item, fetch updated cart items        
            dispatch(getCartpagedata_count(userid))
        }
    } catch (error) {
        console.error('Error removing cart item:', error);
    }
};

//TO REMOVE FROM CART WITHOUTLOGIN...
export const removeCartItemWithoutlogin = (productSku) => {
    return {
        type: ACTION_TYPE.REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN,
        payload: {productSku},  // The productSku of the item to remove
    };
};

//to updatecartlatestprice
export const Update_Cart_Latest_Price = (userprofileid) => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_REQUEST });
    try {
        const Update_Cart_Latest_Price_data = await updateCartLatestPrice(userprofileid)
        Config.logGeneral && console.log(Update_Cart_Latest_Price_data, "Update_Cart_Latest_Price_data 9999999")

        dispatch({
            type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_SUCCESS,
            payload: {
                updatecartmessage: Update_Cart_Latest_Price_data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.UPDATE_CART_LATEST_PRICE_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

// to add products to cart...
export const Add_To_Cart = (user_id,products,cart_id) => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.ADD_TO_CART_REQUEST });
    try {
        const addToCart_data = await addToCart(user_id,products,cart_id)
        Config.logGeneral && console.log(addToCart_data, "addToCart_data 9999999")

        dispatch({
            type: ACTION_TYPE.ADD_TO_CART_SUCCESS,
            payload: {
                addtocartresponsesuccesss: addToCart_data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.ADD_TO_CART_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

// to get you may youMayLikeReducer...
export const youMayLikeData = () => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.YOU_MAY_LIKE_REQUEST });
    try {
        const youMayLike_data = await you_may_like()
        Config.logGeneral && console.log(youMayLike_data, "youMayLike_data 77777777")

        dispatch({
            type: ACTION_TYPE.YOU_MAY_LIKE_SUCCESS,
            payload: {
                youmaylikeResponseSuccess: youMayLike_data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.YOU_MAY_LIKE_FAILURE, errorMessage: error?.response?.data?.message });
    }
};