import React from "react";
import { useSelector } from "react-redux";
import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";
import EditIcon from "../product_img/Edit-icon.svg";
import DeleteIcon from "../product_img/Delete-icon.svg";
import PrimaryImg from "../product_img/primary_img.png";
import TickImg from "../product_img/allorder_tick_img.png";
import Button from "./Atoms/Button";

function Accountpageindividualcontent({
  showContent,
  handleModel,
  onClick,
  handleClick,
}) {
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);
  return (
    <main className="2xl:h-[547px] h-full">
      {persionalInformationDataSuccess !== null &&
        showContent === constants.PersonalInformation && (
          <section className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 ">
            <div>
              {persionalInformationDataSuccess
                .filter((item) => item.key <= 6)
                .map((item) => (
                  <section
                    key={item.key}
                    className=" p-2 lg:p-4 grid grid-cols-2 gap-1 lg:grid-cols-3 lg:gap-2"
                  >
                    <label className="lg:ps-8">{item.label}</label>

                    <span>
                      <span className="lg:pr-10">:</span>
                      <span> {item.value}</span>
                    </span>
                  </section>
                ))}
            </div>
            <div>
              {persionalInformationDataSuccess
                .filter((item) => item.key > 6)
                .map((item) => (
                  <section
                    key={item.key}
                    className="p-2 lg:p-3 grid  grid-cols-2 lg:grid-cols-4 lg:gap-2"
                  >
                    <label className="lg:ps-8">{item.label}</label>
                    <span className="flex flex-row ">
                      <span className="pr-4">:</span>
                      <span className=""> {item.value}</span>
                      <span
                        onClick={() => handleModel(item.label, false)}
                        className="ps-3 underline text-[#15AB9E] cursor-pointer"
                      >
                        Change
                      </span>
                    </span>
                  </section>
                ))}
            </div>
          </section>
        )}
      {persionalAddressDataSuccess !== null &&
        showContent === constants.ManageAddress && (
          <div className="flex w-full flex-wrap justify-center items-center">
            {persionalAddressDataSuccess.map((item) => (
              <section className="border border-[#d9d9d970] rounded-tl-[16px] rounded-tr-[16px] w-[400px]  m-2 xl:w-[640px] h-[222px]">
                <div className="border-b  border-b-[#D9D9D9] p-4 flex flex-row justify-between">
                  <div className="flex flex-row  justify-start items-center  ">
                    <label className="text-[#15AB9E]">{item?.cilentName}</label>
                    {item.primary && (
                      <img
                        src={PrimaryImg}
                        alt="primary-img"
                        className="w-[100px] h-[30px] ps-1 "
                      />
                    )}
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        handleModel(constants.AddressEdit, true, item?.mobile)
                      }
                      className="m-1"
                    >
                      <img src={EditIcon} alt="edit-icon" />
                    </button>
                    <button
                      onClick={() =>
                        handleModel(
                          constants.AddressDelete,
                          constants.Confirmation,
                          item?.mobile
                        )
                      }
                      className="m-1"
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                    </button>
                  </div>
                </div>
                <span></span>
                <section className="p-3 ">
                  <h6 className="pt-2">{item?.address1}</h6>
                  <h6 className="pt-2">{item?.address2}</h6>
                  <h6 className="pt-2">{item?.address3}</h6>
                  <h6 className="pt-2">{item?.address4}</h6>
                </section>
              </section>
            ))}
          </div>
        )}
      {wishListDataSuccess !== null && showContent === constants.Wishlist && (
        <section className="flex flex-row flex-wrap gap-4 mt-4  justify-center items-center">
          {wishListDataSuccess.map((item) => (
            <div className=" flex flex-col hover:border border-[#BBBBBB] p-1">
              <img src={item?.image} className="w-72 h-72" alt="wishlist-img" />

              <span className=" text-[#000000] text-[16px] pt-2 font-medium">
                {item?.title}
              </span>
              <span className="text-[#676767] text-[14px]  font-medium">
                {item?.price}
              </span>
              <div className="flex flex-row pt-3 ">
                <Button
                  label={"Move to Cart"}
                  modelbtn={false}
                  variant="primary"
                  width="full"
                  onClick={onClick}
                />
                <Button
                  label={"Remove"}
                  modelbtn={false}
                  variant="secondary"
                  onClick={onClick}
                />
              </div>
            </div>
          ))}
        </section>
      )}
      {allOrdersDataSuccess !== null && showContent === constants.AllOrders && (
        <section className="flex flex-row flex-wrap p-2 ">
          {allOrdersDataSuccess.map((item) => (
            <div
              onClick={() =>
                handleClick({ title: constants.SingleOrder, actionTitle: null })
              }
              style={{ boxShadow: " rgba(0, 0, 0, 0.13)" }}
              className="flex flex-row w-[580px] m-2 p-2 rounded-lg justify-start items-center cursor-pointer  border border-[#CECECE] "
            >
              <div className="me-3">
                <img
                  src={item?.image}
                  className="w-40 h-40"
                  alt="allorder-img"
                />
              </div>
              <div className="py-5">
                {item?.status?.map((item) => (
                  <span className="relative">
                    <span
                      className={`me-2 p-2  rounded-[50px] text-[12px] font-semibold ${
                        item === "Shipped"
                          ? "bg-[#1E91CF] text-white"
                          : item === "Cancelled"
                          ? " bg-[#FF9933] text-white"
                          : item === "Refunded" || item === "Delivered"
                          ? "bg-[#00AA63] text-white pe-7"
                          : null
                      }`}
                    >
                      {item}
                    </span>

                    {(item === "Refunded" || item === "Delivered") && (
                      <img
                        src={TickImg}
                        className="w-4 h-4  absolute right-2 transition -translate-x-2 -translate-y-5 "
                        alt="tickimg"
                      />
                    )}
                  </span>
                ))}

                <span className="block mt-2 font-Cormorant font-bold text-xl">
                  {item?.title}
                </span>
                <span className="block text-[#262626] font-bold text-xl">
                  {item?.amount}
                </span>
                <div className="bg-[#EBEBEB] border-b w-[400px] mt-2"></div>
                <span className="block text-[14px] font-medium mt-2 text-[#262626]">
                  Order Number :
                  <span className="underline text-[#4D4D4D]">
                    {item?.ordernumber}
                  </span>
                </span>
                <span className="block text-[13px] font-medium text-[#262626]">
                  Ordered Date :{item?.orderdate}
                </span>
              </div>
            </div>
          ))}
        </section>
      )}
      {singleOrderDataSuccess !== null &&
        showContent === constants.SingleOrder && (
          <section className="">
            <div>datas</div>
          </section>
        )}
    </main>
  );
}

export default Accountpageindividualcontent;
