import React, { useEffect } from 'react';
import Config from "../utilities/config";
import constant from '../utilities/constants';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { formatCurrency, combineValues, formatDate,getValue } from '../utilities/helper';
import {getOrderfailPage} from "../redux/action/orderAction";
export default function PaymentFail(){
    const dispatch = useDispatch();
    const { orderid } = useParams();    
    const {orderResponseFail,orderResponseFailError,orderfailResponesLoading} = useSelector((state) => state.orderReducer);
    Config.logGeneral && console.log(orderResponseFailError,"orderResponseFailError");
    Config.logGeneral && console.log(orderResponseFail,"orderResponseFail");
    useEffect(() => {
        dispatch(getOrderfailPage(orderid));
    }, [dispatch, orderid]);
    if (orderfailResponesLoading) {
        return (
          <div className="bg-blue-500 text-white p-6">Loading data...</div>
        );
      }
    
      if (orderResponseFailError) {
        return (
          <div className="bg-red-500 text-white p-6">
            Error: {orderResponseFailError}
          </div>
        );
      }
    if (orderResponseFail && orderResponseFail.data?.data?.allOrders?.nodes[0]) {
        const orderData = orderResponseFail.data.data.allOrders.nodes[0];
        const shoppingCart = orderData.shoppingCartByCartId;
        const subtotal = shoppingCart?.discountedPrice || "NA";
        const formattedAmount = (subtotal !== "NA") ? formatCurrency(subtotal) : "₹0";
        //get the product info... from shoppingCartItemsByShoppingCartId
        const productInfo = orderData?.shoppingCartByCartId?.shoppingCartItemsByShoppingCartId?.nodes;
        const productName = combineValues(productInfo, 'transSkuListByProductSku.productListByProductId.productName', ' & ');
        const productskuIds = combineValues(productInfo, 'transSkuListByProductSku.generatedSku', ' & ');
        const discountPrice = combineValues(productInfo, 'transSkuListByProductSku.discountPrice', ',').split(",").reduce((acc, curr) => acc + parseFloat(curr), 0);
        const MarkPrice = combineValues(productInfo, 'transSkuListByProductSku.sellingPrice', ',').split(",").reduce((acc, curr) => acc + parseFloat(curr), 0);
        const discountshow = Math.floor(discountPrice - MarkPrice).toLocaleString('en-IN');
        const orderdate = orderData?.createdAt;
        const cartId = orderData?.cartId;
        const shippingCharge=formatCurrency(shoppingCart?.shippingCharge) || "Free";  
        
        //DDth Month YYYY at HH:mm AM/PM
        const orderDate = orderdate && formatDate(orderdate, "DDth Month YYYY  HH:mm AM/PM");
        //ship by date
        const shipDate = orderdate && formatDate(orderdate, "DDth DayOfWeek",constant.shipping_days);    
        //delivery date
        const deliveryDate = orderdate && formatDate(orderdate, "DD-MM-YYYY",constant.shipping_days);    
        // const orderDateTime=orderdate && formatTime(orderdate);
        const cartAddress = shoppingCart?.cartAddressesByCartId?.nodes[0];
        return (          
          <>
            <div class="bg-custom-gradient">
              <div class="container mx-auto px-4">
                <div class="grid grid-cols-12">
                  <div class="col-span-12">
                    <div className='pt-32 pb-10'>
                      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className='mx-auto w-1/2'>
                        <g clip-path="url(#clip0_1224_59)">
                          <path d="M46.5527 46.5588C56.8024 36.309 56.8024 19.691 46.5527 9.44125C36.303 -0.808465 19.6849 -0.808464 9.43521 9.44125C-0.8145 19.691 -0.8145 36.309 9.43521 46.5588C19.6849 56.8085 36.303 56.8085 46.5527 46.5588Z" fill="#46CC6B" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M53.8721 32.4533C52.0043 43.382 43.3815 52.0044 32.4531 53.8723L18.3217 39.741C14.9401 36.9503 12.7849 32.727 12.7849 28.0002C12.7849 19.597 19.5968 12.7852 28 12.7852C32.7267 12.7852 36.95 14.9405 39.7407 18.3219L53.8721 32.4533Z" fill="#179C5F" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8781 28.9036L25.2113 32.8829C25.8434 33.4648 26.8218 33.4338 27.4162 32.8209L35.1467 25.4194C35.7755 24.8148 35.7946 23.8144 35.1897 23.1853C34.5849 22.5572 33.5851 22.5375 32.9563 23.1421L26.2601 29.5533L23.0188 26.5769C22.3767 25.9861 21.3762 26.0275 20.785 26.6703C20.194 27.3125 20.2356 28.3123 20.8781 28.9036ZM28 12.7852C36.4031 12.7852 43.215 19.597 43.215 28.0002C43.215 36.4034 36.4031 43.2153 28 43.2153C19.5968 43.2153 12.7849 36.4034 12.7849 28.0002C12.7849 19.597 19.5968 12.7852 28 12.7852Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1224_59">
                            <rect width="56" height="56" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className='text-center font-bold text-gray-800 my-2 text-2xl'>"Oops! Your Payment Failed"</p>
                      {/* <p className='text-center text-lg'>Your order <span className='text-teal-500 text-base font-bold'>#{orderData.id}</span> has been successfully placed.</p> */}
                      <p className='text-center text-lg'>We couldn't process your payment. Don't worry, you can try again or use a different payment method.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <div class="container mx-auto px-4 2xl:mb-16">
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12 lg:col-span-8">
                    <div className='grid grid-cols-12'>
                      <div class="col-span-12">
                        <div className='border-solid border border-slate-300 px-10 py-10 mb-5'>
                          <p className='flex items-center gap-2 font-bold text-xl text-gray-800 mb-2'>Your Payment Has Failed
                            {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1124_143)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C3.58243 16 0 12.4176 0 8C0 3.58243 3.58243 0 8 0C12.4176 0 16 3.58243 16 8C16 12.4176 12.4176 16 8 16ZM6.89934 9.40762L11.7001 4.60693C11.8662 4.44076 12.139 4.44246 12.3034 4.60693L12.8478 5.15131C13.0123 5.31578 13.0123 5.59024 12.8478 5.75465L7.20945 11.3931C7.1086 11.4939 6.96693 11.5336 6.8342 11.5108C6.74337 11.4994 6.65575 11.4595 6.58734 11.3912L3.15269 7.95654C2.98854 7.79238 2.98658 7.51931 3.15269 7.35313L3.69707 6.80876C3.86324 6.64265 4.1343 6.64265 4.30047 6.80876L6.89934 9.40762Z" fill="#222222" />
                              </g>
                              <defs>
                                <clipPath id="clip0_1124_143">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg> */}
                          </p>
                          <p className='text-[15px] text-gray-800 mb-3'>Thank you for choosing NAC Jewellers! We can’t wait for you to enjoy your new jewelry.</p>
                          <p className='text-[15px] text-gray-800'>We’ve sent you an email confirmation &nbsp;  
                            {/* <span className='text-blue-700 font-bold underline'>Resend email</span> */}
                            <a href="#" className='text-blue-700 font-bold underline' 
                                // onClick={(e) => { 
                                // e.preventDefault(); 
                                // dispatch(resend_order_mail(orderid)); }}
                            >
                              Resend email
                            </a>
                          </p>
                        </div>
                      </div>
                      <div class="col-span-12">
                        <div className='border-solid border border-slate-300 px-10 py-10 mb-5'>
                          <p className='text-2xl font-bold text-gray-800 mb-4'>Customer Details</p>
                          <p className='text-[15px] font-bold text-gray-800 mb-1'>Email Address</p>
                          <p className='text-[15px] font-light text-gray-700 mb-6'>georgevinoth09@gmail.com</p>
                          <div className='grid grid-cols-12'>
                            <div className='col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0'>
                              <p className='text-base font-bold text-gray-800 mb-2'>Billing Address</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.firstname} {cartAddress?.lastname}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.addressline1 || "NA"}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.city || "NA"} - {cartAddress?.pincode || "NA"}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.shoppingCartByCartId?.userProfileByUserprofileId?.email || "NA"}</p>
                            </div>
                            <div className='col-span-12 lg:col-span-6 lg:px-4 2xl:pr-10 2xl:pl-0 mt-[20px] sm:mt-0'>
                              <p className='text-base font-bold text-gray-800 mb-2'>Shipping address</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.firstname} {cartAddress?.lastname}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.addressline1 || "NA"}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.city || "NA"} - {cartAddress?.pincode || "NA"}</p>
                              <p className='text-gray-700 text-[15px]'>{cartAddress?.shoppingCartByCartId?.userProfileByUserprofileId?.email || "NA"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <div className='border-solid border border-slate-300 p-10 mb-5 lg:pt-10 pb-10'>
                      <p className='flex item-center gap-2 text-md font-semibold underline mb-2'>
                        <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1127_161)">
                            <path d="M5.41634 15.8333H3.74967C3.51967 15.8333 3.33301 15.6467 3.33301 15.4167C3.33301 15.1867 3.51967 15 3.74967 15H5.41634C5.64634 15 5.83301 15.1867 5.83301 15.4167C5.83301 15.6467 5.64634 15.8333 5.41634 15.8333Z" fill="black" />
                            <path d="M18.9588 15.834H17.9171C17.6871 15.834 17.5005 15.6473 17.5005 15.4173C17.5005 15.1873 17.6871 15.0007 17.9171 15.0007H18.613L19.1746 12.0073C19.1671 10.4757 17.8588 9.16732 16.2505 9.16732H13.5146L12.1888 15.0007H14.5838C14.8138 15.0007 15.0005 15.1873 15.0005 15.4173C15.0005 15.6473 14.8138 15.834 14.5838 15.834H11.6671C11.5405 15.834 11.4205 15.7765 11.3413 15.6773C11.2621 15.579 11.2321 15.449 11.2605 15.3257L12.7755 8.65898C12.8188 8.46815 12.9871 8.33398 13.1821 8.33398H16.2505C18.318 8.33398 20.0005 10.0165 20.0005 12.084L19.368 15.494C19.3313 15.6915 19.1596 15.834 18.9588 15.834Z" fill="black" />
                            <path d="M16.2503 17.5007C15.102 17.5007 14.167 16.5665 14.167 15.4173C14.167 14.2682 15.102 13.334 16.2503 13.334C17.3987 13.334 18.3337 14.2682 18.3337 15.4173C18.3337 16.5665 17.3987 17.5007 16.2503 17.5007ZM16.2503 14.1673C15.5612 14.1673 15.0003 14.7282 15.0003 15.4173C15.0003 16.1065 15.5612 16.6673 16.2503 16.6673C16.9395 16.6673 17.5003 16.1065 17.5003 15.4173C17.5003 14.7282 16.9395 14.1673 16.2503 14.1673Z" fill="black" />
                            <path d="M7.08333 17.5007C5.935 17.5007 5 16.5665 5 15.4173C5 14.2682 5.935 13.334 7.08333 13.334C8.23167 13.334 9.16667 14.2682 9.16667 15.4173C9.16667 16.5665 8.23167 17.5007 7.08333 17.5007ZM7.08333 14.1673C6.39417 14.1673 5.83333 14.7282 5.83333 15.4173C5.83333 16.1065 6.39417 16.6673 7.08333 16.6673C7.7725 16.6673 8.33333 16.1065 8.33333 15.4173C8.33333 14.7282 7.7725 14.1673 7.08333 14.1673Z" fill="black" />
                            <path d="M5.41699 8.33333H2.08366C1.85366 8.33333 1.66699 8.14667 1.66699 7.91667C1.66699 7.68667 1.85366 7.5 2.08366 7.5H5.41699C5.64699 7.5 5.83366 7.68667 5.83366 7.91667C5.83366 8.14667 5.64699 8.33333 5.41699 8.33333Z" fill="black" />
                            <path d="M5.41634 10.8333H1.24967C1.01967 10.8333 0.833008 10.6467 0.833008 10.4167C0.833008 10.1867 1.01967 10 1.24967 10H5.41634C5.64634 10 5.83301 10.1867 5.83301 10.4167C5.83301 10.6467 5.64634 10.8333 5.41634 10.8333Z" fill="black" />
                            <path d="M5.41667 13.3333H0.416667C0.186667 13.3333 0 13.1467 0 12.9167C0 12.6867 0.186667 12.5 0.416667 12.5H5.41667C5.64667 12.5 5.83333 12.6867 5.83333 12.9167C5.83333 13.1467 5.64667 13.3333 5.41667 13.3333Z" fill="black" />
                            <path d="M11.6663 15.834H8.74967C8.51967 15.834 8.33301 15.6473 8.33301 15.4173C8.33301 15.1873 8.51967 15.0007 8.74967 15.0007H11.3338L13.2272 6.66732H3.74967C3.51967 6.66732 3.33301 6.48065 3.33301 6.25065C3.33301 6.02065 3.51967 5.83398 3.74967 5.83398H13.7497C13.8763 5.83398 13.9963 5.89148 14.0755 5.99065C14.1547 6.08898 14.1847 6.21898 14.1563 6.34232L12.073 15.509C12.0297 15.6998 11.8605 15.834 11.6663 15.834Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1127_161">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Ships By {shipDate}
                      </p>
                      <p className='text-[15px] font-normal text-gray-700 mb-2'>Shipping Method: [Standard / Express Shipping]</p>
                      <p className='text-[15px] font-normal text-gray-700 mb-6'>Estimated Delivery Date: {deliveryDate}</p>
                      <hr className="border-t-1 border-gray-300 my-6 rounded-lg" />
                      <p className='text-base font-bold mb-2'>{productName}</p>
                      <p className='text-[15px] text-gray-700 mb-3'>{productskuIds}</p>
                      <p className='text-[15px] text-gray-700 mb-6'>Date: {orderDate}
                        {/* <span>{orderDateTime}</span> */}
                      </p>
                      <hr className='border-t-1 border-gray-300 my-6 rounded-lg' />
                      <div className='flex justify-between'>
                        <div>
                          <p className='mb-2 text-[15px] text-gray-700'>Subtotal</p>
                          <p className='mb-2 text-[15px] text-gray-700'>Discount</p>
                          <p className='mb-2 text-[15px] text-gray-700'>Shipping Charges</p>
                          <p className='mb-2 text-[15px] text-gray-700'>Payment Mode</p>
                        </div>
                        <div className='text-right'>
                          <p className='mb-2 text-[15px] text-gray-700'>{formattedAmount}</p>
                          <p className='mb-2 text-[15px] text-gray-700'>₹{discountshow}</p>
                          <p className='mb-2 text-green-400 text-normal font-bold'>{shippingCharge}</p>
                          <p className='mb-2 text-[15px] text-gray-700'>{orderData.paymentMode}</p>
                        </div>
                      </div>
                      <div className="border border-solid p-3 text-center text-white bg-green-500 text-[15px] font-bold hover:border-green-400 hover:bg-white hover:text-gray-700 group 2xl:mt-5 cursor-pointer duration-100">
                        <Link>Your Order Savings <span className="text-yellow-200 text-[15px] font-bold text-white group-hover:text-green-500">₹{discountshow}</span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="bg-yellow-500 text-white p-6">No data available for this order.</div>
        );
      }
}