import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBestSellerdata,
  getHeaderdata,
  getHomepagedata,
  getHomePageMainBannerdata,
  getHomePageSubBannerdata,
  getProductCountCard,
  getReadourblogsdata,
  getShopbyfinishdata,
  getVideodata,
} from "../redux/action/homeAction";
import config from "../utilities/config";

function Home() {
  const dispatch = useDispatch();
  const {
    homeResponesLoading,
    homeResponseSuccess,
    homeResponseError,
    homeMainBannerResponseLoading,
    homeMainBannerResponseSuccess,
    homeMainBannerResponseError,
    homeSubBannerResponseLoading,
    homeSubBannerResponseSuccess,
    homeSubBannerResponseError,
    bestSellerResponseLoading,
    bestSellerResponseSuccess,
    bestSellerResponseError,
    shopbyfinishCollectionResponseLoading,
    shopbyfinishCollectionResponseSuccess,
    shopbyfinishCollectionResponseError,
    readOurBlogsDataResponseLoading,
    readOurBlogsDataResponseSuccess,
    readOurBlogsDataResponseError,
    videoDataResponesLoading,
    videoDataResponseSuccess,
    videoDataResponseError,
    productsCountCardDataResponesLoading,
    productsCountCardDataResponseSuccess,
    productsCountCardDataResponseError,
  } = useSelector((state) => state.homeReducer);
  const { headerResponseSuccess, headerResponseError } = useSelector(
    (state) => state.headerReducer
  );
  config.logGeneral &&
    console.log(
      "homeResponseSuccess",
      homeResponseSuccess,
      "homeResponseError",
      homeResponseError
    );
  config.logGeneral &&
    console.log("homeMainBannerResponseSuccess", homeMainBannerResponseSuccess);
  config.logGeneral &&
    console.log("homeSubBannerResponseSuccess", homeSubBannerResponseSuccess);
  config.logGeneral &&
    console.log("bestSellerResponseSuccess", bestSellerResponseSuccess);
  config.logGeneral &&
    console.log(
      "shopbyfinishCollectionResponseSuccess",
      shopbyfinishCollectionResponseSuccess
    );
  config.logGeneral &&
    console.log(
      "readOurBlogsDataResponseSuccess",
      readOurBlogsDataResponseSuccess
    );
  config.logGeneral &&
    console.log("videoDataResponseSuccess", videoDataResponseSuccess);
  config.logGeneral &&
    console.log(
      "productsCountCardDataResponseSuccess",
      productsCountCardDataResponseSuccess
    );
  useEffect(() => {
    dispatch(getHeaderdata());
    dispatch(getHomepagedata());
    dispatch(getHomePageMainBannerdata());
    dispatch(getHomePageSubBannerdata());
    dispatch(getBestSellerdata());
    dispatch(getShopbyfinishdata());
    dispatch(getReadourblogsdata());
    dispatch(getVideodata());
    dispatch(getProductCountCard());
  }, []);
  return (
    <div>
      <div className="bg-customBlue text-white p-6">
        Custom background color!
      </div>
    </div>
  );
}

export default Home;
