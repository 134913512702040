import { ACTION_TYPE } from './type'
import Config from "../../utilities/config";
import { productDescription,productfilterlistitem,productlistitems } from '../../utilities/api';

export const product_description = (skuId) => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.GET_PRODUCT_DATA_REQUEST });
    try {
        const product_description_data = await productDescription(skuId)
        Config.logGeneral && console.log(product_description_data, "product_description_data 77777777")

        dispatch({
            type: ACTION_TYPE.GET_PRODUCT_DATA_RESPONSE_SUCCESS,//GET_PRODUCT_DATA_RESPONSE_SUCCESS
            payload: {
                productResponseSuccess: product_description_data?.data?.data?.allTransSkuLists?.nodes
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error productDescriptionResponseError", error)
        dispatch({ type: ACTION_TYPE.GET_PRODUCT_DATA_RESPONSE_FAILURE, errorMessage: error?.response?.data?.message });
    }
};

export const product_list_filter = () => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA_REQUEST });
    try {
        const product_list_filter_data = await productfilterlistitem()
        Config.logGeneral && console.log(product_list_filter_data, "product_list_filter_data 77777777")

        dispatch({
            type: ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA_SUCCESS,//GET_PRODUCT_DATA_RESPONSE_SUCCESS
            payload: {
                productResponseSuccess: product_list_filter_data?.data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error productDescriptionResponseError", error)
        dispatch({ type: ACTION_TYPE.GET_PRODUCT_FILTER_LIST_DATA__FAILURE, errorMessage: error?.response?.data?.message });
    }
};

export const product_list_item = (productfil) => async (dispatch) => {
    dispatch({ type: ACTION_TYPE.GET_PRODUCT_LIST_DATA_REQUEST});
    try {
        const product_list_item_data = await productlistitems(productfil)
        Config.logGeneral && console.log(product_list_item_data, "product_list_item_data77")

        dispatch({
            type: ACTION_TYPE.GET_PRODUCT_LIST_DATA_SUCCESS,
            payload: {
                productListitemResponseSuccess: product_list_item_data?.data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error productlistitemResponseError", error)
        dispatch({ type: ACTION_TYPE.GET_PRODUCT_LIST_DATA_FAILURE, errorMessage: error?.response?.data?.message });
    }
};