import React, { useEffect, useState } from "react";
import Accountpagesidemenu from "../components/Accountpagesidemenu";
import Accountpagecontent from "../components/Accountpagecontent";
import { useDispatch, useSelector } from "react-redux";

import {
  getPersionalData,
  getAllOrders,
  getPersionalAddress,
  getRedeemPointsDetails,
  getShoppingDetails,
  getSingleOrder,
  getWishList,
  logoutWebsite,
} from "../redux/action/myAccountAction";
import { accountMenu } from "../utilities/helper";
import constants from "../utilities/constants";
import PopupModal from "../components/PopupModal";
import Modalinformationpage from "./Modalscreens/Modalinformationpage";

export default function Account() {
  const dispatch = useDispatch();
  const [myAccountPageHeader, setMyAccountPageHeader] = useState(
    accountMenu[0]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [modelTitle, setModeltitle] = useState(null);
  const [modelSubTitle, setModelSubTitle] = useState(null);
  const [showModelData, setShowModelData] = useState(null);
  const [headerEditModal, setHeaderEditModal] = useState(false);
  const [value, setvalue] = useState(null);

  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);

  // handle the model values
  const handleModelChange = (value, handleModal, handlevalue = null) => {
    setIsOpen(true);
    setHeaderEditModal(handleModal);
    console.log("handleModelChange", value);
    setShowModelData(value);
    if (value === constants.MobileNumber) {
      setModeltitle("Change Mobile Number");
      setModelSubTitle(null);
    }
    if (value === constants.EmailAddress) {
      setModeltitle("Change Email Address");
      setModelSubTitle(null);
    }
    if (value === constants.Password) {
      setModeltitle("Reset your Password");
      setModelSubTitle("Create a new password to secure your account.");
    }
    if (value === constants.PersonalInformation) {
      setModeltitle(value);
      setModelSubTitle(null);
    }
    if (value === constants.AddressDelete) {
      setModeltitle("Confirmation");
      setModelSubTitle(null);
    }
    if (value === constants.AddressEdit) {
      setModeltitle("Edit Address");
      setModelSubTitle(null);
    }
    if (value === constants.ManageAddress) {
      setModeltitle("Add Address");
      setModelSubTitle(null);
    }
    if (value === constants.SingleOrder) {
      setModeltitle("View Orders");
      setModelSubTitle(null);
    }
  };
  // handle model input changes
  const handleInputChange = (label, value) => {
    console.log("handleChangedata", label, value);
    if (label === "Make this as a Primary Address") {
      setvalue(!value);
    } else {
      setvalue(value);
    }
  };
  // handle model button changes
  const handleButtonChange = (label) => {
    console.log("handleButtonChange", label);

    if (label === "Cancel") {
      setIsOpen(false);
    }
  };
  //handle side menu click and show content
  const handleClick = (item) => {
    console.log("handleClickitem", item);
    setMyAccountPageHeader(item);
    if (item.title === constants.PersonalInformation) {
      dispatch(getPersionalData());
    }
    if (item.title === constants.ManageAddress) {
      dispatch(getPersionalAddress());
    }
    if (item.title === constants.ShoppingBag) {
      dispatch(getShoppingDetails());
    }
    if (item.title === constants.Wishlist) {
      dispatch(getWishList());
    }
    if (item.title === constants.AllOrders) {
      dispatch(getAllOrders());
    }
    if (item.title === constants.RedeemPoints) {
      dispatch(getRedeemPointsDetails());
    }
    if (item.title === constants.SingleOrder) {
      // dispatch(getRedeemPointsDetails());
    }
    if (item.title === constants.Logout) {
      dispatch(logoutWebsite());
    }
    if (item === constants.SingleOrder) {
      console.log("constants.SingleOrder");

      dispatch(getSingleOrder());
    }
  };
  return (
    <main className="mx-auto container mt-2 mb-10 h-full ">
      <h1 className="font-bold text-3xl font-Cormorant md:ps-2">My Account</h1>
      <section className="grid grid-flow-row md:grid-flow-col  gap-4 mt-5 ">
        <section className="">
          <Accountpagesidemenu
            handleClick={handleClick}
            activeSidebar={myAccountPageHeader}
          />
        </section>
        <section className="border border-[#D9D9D9] rounded-tl-[16px] rounded-tr-[16px]  md:col-span-10 ">
          <Accountpagecontent
            header={myAccountPageHeader}
            handleModel={handleModelChange}
            handleButtonChange={handleButtonChange}
            handleClick={handleClick}
          />
          <PopupModal isOpen={isOpen}>
            <Modalinformationpage
              modelTitle={modelTitle}
              modelSubTitle={modelSubTitle}
              handleInputChange={handleInputChange}
              handleButtonChange={handleButtonChange}
              showModelData={showModelData}
              headerEditModal={headerEditModal}
              value={value}
              setIsOpen={setIsOpen}
            />
          </PopupModal>
        </section>
      </section>
    </main>
  );
}
