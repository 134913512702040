import { ACTION_TYPE } from "../action/type";
const initialState = {
  homeResponesLoading: false,
  homeResponseSuccess: null,
  homeResponseError: null,
  //contactus
  contactusLoding:false,
  contactusSuccess:null,
  contactusFail:null,  
  //mailsubscription
  mailsubscriptionResponesLoading: false,
  mailsubscriptionResponseSuccess: null,
  mailsubscriptionResponseError: null,
  //homeMainBannerResponse
  homeMainBannerResponseLoading: false,
  homeMainBannerResponseSuccess: null,
  homeMainBannerResponseError: null,
  //homeSubBannerResponse
  homeSubBannerResponseLoading: false,
  homeSubBannerResponseSuccess: null,
  homeSubBannerResponseError: null,
  //bestSellerResponse
  bestSellerResponseLoading: false,
  bestSellerResponseSuccess: null,
  bestSellerResponseError: null,
  //navarathnaCollectionResponse
  shopbyfinishCollectionResponseLoading: false,
  shopbyfinishCollectionResponseSuccess: null,
  shopbyfinishCollectionResponseError: null,
  //readOurBlogsDataResponse
  readOurBlogsDataResponseLoading: false,
  readOurBlogsDataResponseSuccess: null,
  readOurBlogsDataResponseError: null,
  //video DataResponse
  videoDataResponesLoading: false,
  videoDataResponseSuccess: null,
  videoDataResponseError: null,
  //products count card
  productsCountCardDataResponesLoading: false,
  productsCountCardDataResponseSuccess: null,
  productsCountCardDataResponseError: null,
};
const homeNotificationinitialState = {
  homeNotificationResponesLoading: false,
  homeNotificationResponseSuccess: [],
  homeNotificationResponseError: null,
};

const headerInitialState = {
  headerResponesLoading: false,
  headerResponseSuccess: null,
  headerResponseError: null,
};

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.GET_HOME_DATA_REQUEST:
      return {
        ...state,
        homeResponesLoading: true,
        homeResponseSuccess: null,
        homeResponseError: null,
      };
    //request mail subscription
    case ACTION_TYPE.MAIL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        mailsubscriptionResponesLoading: true,
        mailsubscriptionResponseSuccess: null,
        mailsubscriptionResponseError: null,        
      };
      //success mail subscription
      case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS:
        return {
          ...state,
          mailsubscriptionResponesLoading: false,
          mailsubscriptionResponseSuccess: action.payload,
          mailsubscriptionResponseError: null,          
        };
        case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR:
        return {
          ...state,
          mailsubscriptionResponesLoading: false,
          mailsubscriptionResponseSuccess: null,
          mailsubscriptionResponseError: null,          
        };
      //failed mail subscription
      case ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_FAILURE:
        return {
          ...state,
          mailsubscriptionResponesLoading: false,
          mailsubscriptionResponseSuccess: null,
          mailsubscriptionResponseError: action.errorMessage,
        };
    case ACTION_TYPE.GET_HOME_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeResponesLoading: false,
        homeResponseSuccess: action.payload.homeResponseSuccess,
        homeResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeResponesLoading: false,
        homeResponseSuccess: null,
        homeResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_REQUEST:
      return {
        ...state,
        homeMainBannerResponseLoading: true,
        homeMainBannerResponseSuccess: null,
        homeMainBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeMainBannerResponseLoading: false,
        homeMainBannerResponseSuccess:
          action.payload.homeMainBannerResponseSuccess,
        homeMainBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeMainBannerResponseLoading: false,
        homeMainBannerResponseSuccess: null,
        homeMainBannerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_REQUEST:
      return {
        ...state,
        homeSubBannerResponseLoading: true,
        homeSubBannerResponseSuccess: null,
        homeSubBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        homeSubBannerResponseLoading: false,
        homeSubBannerResponseSuccess:
          action.payload.homeSubBannerResponseSuccess,
        homeSubBannerResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        homeSubBannerResponseLoading: false,
        homeSubBannerResponseSuccess: null,
        homeSubBannerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_REQUEST:
      return {
        ...state,
        bestSellerResponseLoading: true,
        bestSellerResponseSuccess: null,
        bestSellerResponseError: null,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        bestSellerResponseLoading: false,
        bestSellerResponseSuccess: action.payload.bestSellerResponseSuccess,
        bestSellerResponseError: null,
      };
    case ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        bestSellerResponseLoading: false,
        bestSellerResponseSuccess: null,
        bestSellerResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: true,
        shopbyfinishCollectionResponseSuccess: null,
        shopbyfinishCollectionResponseError: null,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: false,
        shopbyfinishCollectionResponseSuccess:
          action.payload.shopbyfinishCollectionResponseSuccess,
        shopbyfinishCollectionResponseError: null,
      };
    case ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        shopbyfinishCollectionResponseLoading: false,
        shopbyfinishCollectionResponseSuccess: null,
        shopbyfinishCollectionResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_REQUEST:
      return {
        ...state,
        readOurBlogsDataResponseLoading: true,
        readOurBlogsDataResponseSuccess: null,
        readOurBlogsDataResponseError: null,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        readOurBlogsDataResponseLoading: false,
        readOurBlogsDataResponseSuccess:
          action.payload.readOurBlogsDataResponseSuccess,
        readOurBlogsDataResponseError: null,
      };
    case ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        readOurBlogsDataResponseLoading: false,
        readOurBlogsDataResponseSuccess: null,
        readOurBlogsDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_REQUEST:
      return {
        ...state,
        videoDataResponesLoading: true,
        videoDataResponseSuccess: null,
        videoDataResponseError: null,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_SUCCESS:
      return {
        ...state,
        videoDataResponesLoading: false,
        videoDataResponseSuccess: action.payload.videoDataResponseSuccess,
        videoDataResponseError: null,
      };
    case ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_FAILURE:
      return {
        ...state,
        videoDataResponesLoading: false,
        videoDataResponseSuccess: null,
        videoDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_REQUEST:
      return {
        ...state,
        productsCountCardDataResponesLoading: true,
        productsCountCardDataResponseSuccess: null,
        productsCountCardDataResponseError: null,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_SUCCESS:
      return {
        ...state,
        productsCountCardDataResponesLoading: false,
        productsCountCardDataResponseSuccess:
          action.payload.productsCountCardDataResponseSuccess,
        productsCountCardDataResponseError: null,
      };
    case ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_FAILURE:
      return {
        ...state,
        productsCountCardDataResponesLoading: false,
        productsCountCardDataResponseSuccess: null,
        productsCountCardDataResponseError: action.errorMessage,
      };
    case ACTION_TYPE.CONTACTUSFORM_REQUEST:
      return {
        ...state,
        contactusLoding:true,
        contactusSuccess:null,
        contactusFail:null,  
      };
    case ACTION_TYPE.CONTACTUSFORM_RESET:
      return {
        ...state,
        contactusLoding:false,
        contactusSuccess:null,
        contactusFail:null,  
      };
    case ACTION_TYPE.CONTACTUSFORM_SUCCESS:
      return {
        ...state,
        contactusLoding:false,
        contactusSuccess:action.payload,
        contactusFail:null,  
      };
      case ACTION_TYPE.CONTACTUSFORM_FAIL:
        return {
          ...state,
          contactusLoding:false,
          contactusSuccess:null,
          contactusFail:action.errorMessage,  
        };
    default:
      return state;
  }
}

export function homeNotificationReducer(
  state = homeNotificationinitialState,
  action
) {
  switch (action.type) {
    case ACTION_TYPE.GET_HOME_NOTIFICATION_REQUEST:
      return {
        ...state,
        homeNotificationResponesLoading: true,
        homeNotificationResponseSuccess: [],
        homeNotificationResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_SUCCESS:
      return {
        ...state,
        homeNotificationResponesLoading: false,
        homeNotificationResponseSuccess:
          action.payload.homenotificationResponseSuccess,
        homeNotificationResponseError: null,
      };
    case ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_FAILURE:
      return {
        ...state,
        homeNotificationResponesLoading: false,
        homeNotificationResponseSuccess: [],
        homeNotificationResponseError: action.errorMessage,
      };

    default:
      return state;
  }
}

export function headerReducer(state = headerInitialState, action) {
  switch (action.type) {
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST:
      return {
        ...state,
        headerResponesLoading: true,
        headerResponseSuccess: null,
        headerResponseError: null,
      };
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        headerResponesLoading: false,
        headerResponseSuccess: action.payload.headerResponseSuccess,
        headerResponseError: null,
      };
    case ACTION_TYPE.GET_HEADER_DATA_REQUEST_FAILURE:
      return {
        ...state,
        headerResponesLoading: false,
        headerResponseSuccess: null,
        headerResponseError: action.errorMessage,
      };

    default:
      return state;
  }
}
