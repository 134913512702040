//Network Constants

export const ACTION_TYPE = {
  GET_HOME_DATA_REQUEST: "GET_HOME_DATA_REQUEST",
  GET_HOME_DATA_RESPONSE_SUCCESS: "GET_HOME_DATA_RESPONSE_SUCCESS",
  GET_HOME_DATA_RESPONSE_FAILURE: "GET_HOME_DATA_RESPONSE_FAILURE",
  //mail subscription
  MAIL_SUBSCRIPTION_REQUEST: "MAIL_SUBSCRIPTION_REQUEST",
  MAIL_SUBSCRIPTION_RESPONSE_SUCCESS: "MAIL_SUBSCRIPTION_RESPONSE_SUCCESS",
  MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR: "MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR",
  MAIL_SUBSCRIPTION_RESPONSE_FAILURE: "MAIL_SUBSCRIPTION_RESPONSE_FAILURE",
  // home banner type
  GET_HOME_MAIN_BANNER_DATA_REQUEST: "GET_HOME_MAIN_BANNER_DATA_REQUEST",
  GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS:
    "GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS",
  GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE:
    "GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE",

  // home collection banner type
  GET_HOME_SUB_BANNER_DATA_REQUEST: "GET_HOME_SUB_BANNER_DATA_REQUEST",
  GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS:
    "GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS",
  GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE:
    "GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE",
  //best seller type
  GET_BESTSELLER_DATA_REQUEST: "GET_BESTSELLER_DATA_REQUEST",
  GET_BESTSELLER_DATA_RESPONSE_SUCCESS: "GET_BESTSELLER_DATA_RESPONSE_SUCCESS",
  GET_BESTSELLER_DATA_RESPONSE_FAILURE: "GET_BESTSELLER_DATA_RESPONSE_FAILURE",
  //shopbyfinish collection type
  GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST:
    "GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST",
  GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS:
    "GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS",
  GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE:
    "GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE",
  //read our blogs type
  GET_READ_OUR_BLOGS_DATA_REQUEST: "GET_READ_OUR_BLOGS_DATA_REQUEST",
  GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS:
    "GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS",
  GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE:
    "GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE",
  //home page video data  type
  GET_VIDEO_DATA_REQUEST: "GET_VIDEO_DATA_REQUEST",
  GET_VIDEO_DATA_RESPONSE_SUCCESS: "GET_VIDEO_DATA_RESPONSE_SUCCESS",
  GET_VIDEO_DATA_RESPONSE_FAILURE: "GET_VIDEO_DATA_RESPONSE_FAILURE",
  //home page product count data type
  GET_PRODUCT_COUNT_CARD_DATA_REQUEST: "GET_PRODUCT_COUNT_CARD_DATA_REQUEST",
  GET_PRODUCT_COUNT_CARD_DATA_SUCCESS: "GET_PRODUCT_COUNT_CARD_DATA_SUCCESS",
  GET_PRODUCT_COUNT_CARD_DATA_FAILURE: "GET_PRODUCT_COUNT_CARD_DATA_FAILURE",
  //header type
  GET_HEADER_DATA_REQUEST: "GET_HEADER_DATA_REQUEST",
  GET_HEADER_DATA_REQUEST_SUCCESS: "GET_HEADER_DATA_REQUEST_SUCCESS",
  GET_HEADER_DATA_REQUEST_FAILURE: "GET_HEADER_DATA_REQUEST_FAILURE",

  GET_PRODUCT_DATA_REQUEST: "GET_PRODUCT_DATA_REQUEST",
  GET_PRODUCT_DATA_RESPONSE_SUCCESS: "GET_PRODUCT_DATA_RESPONSE_SUCCESS",
  GET_PRODUCT_DATA_RESPONSE_FAILURE: "GET_PRODUCT_DATA_RESPONSE_FAILURE",

  GET_PRODUCT_FILTER_LIST_DATA_REQUEST: "GET_PRODUCT_FILTER_LIST_DATA_REQUEST",
  GET_PRODUCT_FILTER_LIST_DATA_SUCCESS: "GET_PRODUCT_FILTER_LIST_DATA_SUCCESS",
  GET_PRODUCT_FILTER_LIST_DATA_FAILURE: "GET_PRODUCT_FILTER_LIST_DATA__FAILURE",
  //to get products in product list
  GET_PRODUCT_LIST_DATA_REQUEST: "GET_PRODUCT_LIST_DATA_REQUEST",
  GET_PRODUCT_LIST_DATA_SUCCESS: "GET_PRODUCT_LIST_DATA_SUCCESS",
  GET_PRODUCT_LIST_DATA_FAILURE: "GET_PRODUCT_LIST_DATA_FAILURE",

  //for home notification
  GET_HOME_NOTIFICATION_REQUEST: "GET_HOME_NOTIFICATION_REQUEST",
  GET_HOME_NOTIFICATION_RESPONSE_SUCCESS:"GET_HOME_NOTIFICATION_RESPONSE_SUCCESS",
  GET_HOME_NOTIFICATION_RESPONSE_FAILURE:"GET_HOME_NOTIFICATION_RESPONSE_FAILURE",

  GET_CART_DATA_REQUEST: "GET_CART_DATA_REQUEST",
  GET_CART_DATA_RESPONSE_SUCCESS: "GET_CART_DATA_RESPONSE_SUCCESS",
  GET_CART_DATA_RESPONSE_FAILURE: "GET_CART_DATA_RESPONSE_FAILURE",

  //kirthi
  //ordersuccess...
  GET_ORDERSUCCESS_DATA_REQUEST: "GET_ORDERSUCCESS_DATA_REQUEST",
  GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS:"GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS",
  GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE:"GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE",
  //orderfailed...
  GET_ORDERFAILED_DATA_REQUEST: "GET_ORDERFAILED_DATA_REQUEST",
  GET_ORDERFAILED_DATA_RESPONSE_SUCCESS:"GET_ORDERFAILED_DATA_RESPONSE_SUCCESS",
  GET_ORDERFAILED_DATA_RESPONSE_FAILURE:"GET_ORDERFAILED_DATA_RESPONSE_FAILURE",
  //resendordermail..
  RESENDORDERMAIL_REQUEST:"RESENDORDERMAIL_REQUEST",
  RESENDORDERMAIL_SUCCESS:"RESENDORDERMAIL_SUCCESS",
  RESENDORDERMAIL_FAIL:"RESENDORDERMAIL_FAIL",
  //contact us...
  CONTACTUSFORM_REQUEST:"CONTACTUSFORM_REQUEST",
  CONTACTUSFORM_SUCCESS:"CONTACTUSFORM_SUCCESS",
  CONTACTUSFORM_FAIL:"CONTACTUSFORM_FAIL",
  CONTACTUSFORM_RESET:"CONTACTUSFORM_RESET",
  //login....
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_MODAL: "LOGIN_MODAL",
  LOGIN_MODAL_TYPE: "LOGIN_MODAL_TYPE",
  //cart_count...
  CART_COUNT_REQUEST: "CART_COUNT_REQUEST",
  CART_COUNT_SUCCESS: "CART_COUNT_SUCCESS",
  CART_COUNT_FAILURE: "CART_COUNT_FAILURE",
  REMOVE_CART_ITEM_SUCCESS: "REMOVE_CART_ITEM_SUCCESS",
  REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN:
    "REMOVE_CART_ITEM_SUCCESS_WITHOUT_LOGIN",
  CART_COUNT_WITHOUT_LOGIN: "CART_COUNT_WITHOUT_LOGIN",

  UPDATE_CART_LATEST_PRICE_REQUEST: "UPDATE_CART_LATEST_PRICE_REQUEST",
  UPDATE_CART_LATEST_PRICE_SUCCESS: "UPDATE_CART_LATEST_PRICE_SUCCESS",
  UPDATE_CART_LATEST_PRICE_FAILURE: "UPDATE_CART_LATEST_PRICE_FAILURE",

  ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

  //you may like
  YOU_MAY_LIKE_REQUEST: "YOU_MAY_LIKE_REQUEST",
  YOU_MAY_LIKE_SUCCESS: "YOU_MAY_LIKE_SUCCESS",
  YOU_MAY_LIKE_FAILURE: "YOU_MAY_LIKE_FAILURE",

  //user address...
  USER_ADDRESSES_REQUEST: "USER_ADDRESSES_REQUEST",
  USER_ADDRESSES_SUCCESS: "USER_ADDRESSES_SUCCESS",
  USER_ADDRESSES_FAILURE: "USER_ADDRESSES_FAILURE",

  //countries....currency...
  CURRENCY_AND_COUNTRIES_REQUEST: "USER_ADDRESSES_REQUEST",
  CURRENCY_AND_COUNTRIES_SUCCESS: "USER_ADDRESSES_SUCCESS",
  CURRENCY_AND_COUNTRIES_FAILURE: "USER_ADDRESSES_FAILURE",
  // persional information
  GET_PERSIONAL_INFORMATION_REQUEST: "GET_PERSIONAL_INFORMATION_REQUEST",
  GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
    "GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS",
  GET_PERSIONAL_INFORMATION_REQUEST_FAILURE:
    "GET_PERSIONAL_INFORMATION_REQUEST_FAILURE",
  CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
    "CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS",
  // MANAGE ADDRESS
  GET_PERSIONAL_ADDRESS_REQUEST: "GET_PERSIONAL_ADDRESS_REQUEST",
  GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
    "GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS",
  GET_PERSIONAL_ADDRESS_REQUEST_FAILURE:
    "GET_PERSIONAL_ADDRESS_REQUEST_FAILURE",
  CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
    "CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS",
  //WISHLIST DATA
  GET_WISH_LIST_DATA_REQUEST: "GET_WISH_LIST_DATA_REQUEST",
  GET_WISH_LIST_DATA_SUCCESS: "GET_WISH_LIST_DATA_SUCCESS",
  GET_WISH_LIST_DATA_FAILURE: "GET_WISH_LIST_DATA_FAILURE",
  CLEAR_WISH_LIST_DATA_SUCCESS: "CLEAR_WISH_LIST_DATA_SUCCESS",
  // ALL ORDERS
  GET_ALL_ORDERS_DATA_REQUEST: "GET_ALL_ORDERS_DATA_REQUEST",
  GET_ALL_ORDERS_DATA_SUCCESS: "GET_ALL_ORDERS_DATA_SUCCESS",
  GET_ALL_ORDERS_DATA_FAILURE: "GET_ALL_ORDERS_DATA_FAILURE",
  CLEAR_ALL_ORDERS_DATA_SUCCESS: "CLEAR_ALL_ORDERS_DATA_SUCCESS",
  // SINGLE ORDER

  GET_SINGLE_ORDER_DATA_REQUEST: "GET_SINGLE_ORDER_DATA_REQUEST",
  GET_SINGLE_ORDER_DATA_SUCCESS: "GET_SINGLE_ORDER_DATA_SUCCESS",
  GET_SINGLE_ORDER_DATA_FAILURE: "GET_SINGLE_ORDER_DATA_FAILURE",
  CLEAR_SINGLE_ORDER_DATA_SUCCESS: "CLEAR_SINGLE_ORDER_DATA_SUCCESS",
};
