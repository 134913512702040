import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { contactus_form_submission, resetContactUsStatus } from "../redux/action/homeAction";
import config from "../utilities/config";

function ContactUsForm() {
  const dispatch = useDispatch();
  const { contactusLoding, contactusSuccess, contactusFail } = useSelector((state) => state.homeReducer);
  config.logGeneral && console.log(contactusSuccess, "contactusSuccess");
  config.logGeneral && console.log(contactusFail, "contactusFail");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  // State for validation error messages
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: '',
    formError: '',
  });

  // State for showing success or failure message
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    let newErrors = {
      name: '',
      email: '',
      message: '',
      formError: '',
    };

    // Validate Name
    if (formData.name.length > 100) {
      newErrors.name = 'Name should not exceed 100 characters.';
      isValid = false;
    }

    // Validate Email
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
      isValid = false;
    }

    // Validate Message
    if (formData.message.length > 1000) {
      newErrors.message = 'Message should not exceed 1000 characters.';
      isValid = false;
    }

    // Update the error state immediately
    setErrors(newErrors);

    if (isValid) {
      setIsSubmitting(true);

      try {
        await dispatch(contactus_form_submission(formData));

        // No need to set message here since it will be handled by the useEffect based on Redux state
      } catch (error) {
        // Handle network or any other errors that occur during the API call
        setErrors({ ...newErrors, formError: 'An error occurred. Please try again later.' });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Set the overall form error message if validation fails
      setErrors({ ...newErrors, formError: 'Please fix the errors above.' });
    }
  };

  // Effect to handle success or failure messages
  useEffect(() => {
    if (contactusSuccess) {
      setMessage("Thanks for contacting us. Our team will reach out to you.");
      setMessageType("success");

    }

    if (contactusFail) {
      setMessage("There was an issue with your submission. Please try again later.");
      setMessageType("error");
    }

    // Reset message and state after 5 seconds
    if (contactusSuccess || contactusFail) {
      const timer = setTimeout(() => {
        setMessage(""); // Clear the message
        setMessageType(""); // Clear message type
        dispatch(resetContactUsStatus()); // Reset the status in Redux
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',          
        })
      }, 5000);

      // Cleanup timer on unmount or status change
      return () => clearTimeout(timer);
    }
  }, [contactusSuccess, contactusFail, dispatch]);

  return (
    <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md mx-auto">
      <h2 className="text-2xl font-bold text-center mb-6">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        {/* Name Field */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            maxLength="100"
            required
          />
          {errors.name && <div className="text-red-500 text-sm">{errors.name}</div>}
        </div>

        {/* Email Field */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
          {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
        </div>

        {/* Phone Number Field */}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            pattern="[0-9]{10}"
            required
          />
        </div>

        {/* Message Field */}
        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            maxLength="1000"
            rows="4"
            required
          />
          {errors.message && <div className="text-red-500 text-sm">{errors.message}</div>}
        </div>

        {/* Form Error (General) */}
        {errors.formError && <div className="text-red-500 text-sm">{errors.formError}</div>}

        {/* Submit Button */}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="w-1/5 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Send'}
          </button>
        </div>
      </form>

      {/* Success or Failure Message */}
      {message && (
        <div
          className={`mt-4 text-sm p-3 rounded-md ${messageType === "success" ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"}`}
        >
          {message}
        </div>
      )}
    </div>
  );
}

export default ContactUsForm;
