import login_img from '../product_img/login_modal_img.webp';
import Logo from '../product_img/logo.webp';
import React, { useEffect, useState,useCallback } from "react";
import config from '../utilities/config';
import { useDispatch,useSelector } from "react-redux";
import {add_to_cart} from "../utilities/api";
import { login } from "../redux/action/authAction"; // Import your login action
// Modal.js


const Modal = ({ onClose,children }) => {
   
    const dispatch = useDispatch();
    const { userProfile_id,loginOpenModal } = useSelector((state) => state.authReducer);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault();
        const loginResponse = await dispatch(login(email, password));
        console.log(loginResponse,"loginResponsefrommodal")
        onClose();
        setEmail("");
        setPassword("");
    };
    
    if (!loginOpenModal) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg w-full md:w-3/4 lg:w-1/2 shadow-lg p-2 md:p-0 relative">
            <button 
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-2xl bg-customGreen text-white w-7 h-7 rounded-2xl leading-1"
                onClick={onClose}
            >
                &times;
            </button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                   <img src={login_img} alt='Login Image' className='rounded-lg hidden md:block' />
                </div>
                <div>
                    <div className='flex items-center h-full justify-center p-2 md:p-5'>
                        <div className='text-center'>
                        <img src={Logo} alt='Login Image' className='w-40 m-auto mb-5' />
                        <p className='text-lg font-semibold'>Login to Complete Your Purchase</p>
                        <p className='text-customGreen mt-2 font-medium'>You’re almost there!</p>
                        <p className='mt-2 mb-5 text-gray-500 text-base'>Please log in to proceed with purchasing your selected items from your wishlist.</p>

    <div className='mt-6'>
        <form className='w-full mx-auto' onSubmit={handleSubmit}>
            <input
                type='email'
                placeholder='Enter Email ID '
                className='w-full p-3 mb-4 border border-gray-300 rounded-none'
                name="useremail" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type='password'
                placeholder='Password'
                className='w-full p-3 mb-4 border border-gray-300 rounded-none'
                name="userpassword" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                type='submit'
                className='w-full p-3 bg-customGreen border text-white font-bold rounded-none hover:bg-customGreen-dark hover:bg-white hover:text-gray-600 hover:border'
            >
                Login
            </button>
        </form>

        <div className='flex items-center my-4'>
            <hr className='flex-1 border-t border-gray-300' />
            <span className='mx-4 text-gray-500'>or</span>
            <hr className='flex-1 border-t border-gray-300' />
        </div>


        {/* Social Sign-In Options */}
        {/* <div className='mt-4'>
            <div className='flex justify-between items-center mt-4'>
                <button className='w-1/2 p-3 bg-white text-gray-600 font-normal rounded-lg border border-solid hover:bg-gray-100 flex justify-center items-center mr-2 text-[15px]'>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-2'>
                <path d="M20.3055 8.0415H19.5V8H10.5V12H16.1515C15.327 14.3285 13.1115 16 10.5 16C7.1865 16 4.5 13.3135 4.5 10C4.5 6.6865 7.1865 4 10.5 4C12.0295 4 13.421 4.577 14.4805 5.5195L17.309 2.691C15.523 1.0265 13.134 0 10.5 0C4.9775 0 0.5 4.4775 0.5 10C0.5 15.5225 4.9775 20 10.5 20C16.0225 20 20.5 15.5225 20.5 10C20.5 9.3295 20.431 8.675 20.3055 8.0415Z" fill="#FFC107"/>
                <path d="M1.65308 5.3455L4.93858 7.755C5.82758 5.554 7.98058 4 10.5001 4C12.0296 4 13.4211 4.577 14.4806 5.5195L17.3091 2.691C15.5231 1.0265 13.1341 0 10.5001 0C6.65908 0 3.32808 2.1685 1.65308 5.3455Z" fill="#FF3D00"/>
                <path d="M10.4999 20.0003C13.0829 20.0003 15.4299 19.0118 17.2044 17.4043L14.1094 14.7853C13.0719 15.5749 11.8037 16.0017 10.4999 16.0003C7.89891 16.0003 5.69041 14.3418 4.85841 12.0273L1.59741 14.5398C3.25241 17.7783 6.61341 20.0003 10.4999 20.0003Z" fill="#4CAF50"/>
                <path d="M20.3055 8.0415H19.5V8H10.5V12H16.1515C15.7571 13.1082 15.0467 14.0766 14.108 14.7855L14.1095 14.7845L17.2045 17.4035C16.9855 17.6025 20.5 15 20.5 10C20.5 9.3295 20.431 8.675 20.3055 8.0415Z" fill="#1976D2"/>
                </svg>
                Sign in with Google
                </button>
                <button className='w-1/2 p-3 bg-white border border-solid text-gray-600 font-normal rounded-lg hover:bg-gray-100 flex justify-center items-center text-[15px]'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
                <g clip-path="url(#clip0_1314_52)">
                <path d="M24 12C24 5.37262 18.6274 0 12 0C5.37262 0 0 5.37262 0 12C0 17.9895 4.38825 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9705 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.3399 7.875 13.875 8.80003 13.875 9.74906V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9896 24 12Z" fill="#1877F2"/>
                <path d="M16.6711 15.4688L17.2031 12H13.875V9.74906C13.875 8.79994 14.3399 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6575 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.7453 23.9514 11.3722 24.0001 12 24C12.6278 24.0001 13.2547 23.9514 13.875 23.8542V15.4688H16.6711Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1314_52">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
                    Sign in with Facebook
                </button>
            </div>
        </div> */}

        {/* Mobile Icon Centered */}
        <div className='mt-8'>
            <div className='flex justify-center text-gray-500 cursor-pointer'>
            <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-1'>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.40005 2.99961C2.40005 2.78744 2.48433 2.58395 2.63436 2.43392C2.78439 2.28389 2.98788 2.19961 3.20005 2.19961H12.8C13.0122 2.19961 13.2157 2.28389 13.3657 2.43392C13.5158 2.58395 13.6 2.78744 13.6 2.99961V18.9996C13.6 19.2118 13.5158 19.4153 13.3657 19.5653C13.2157 19.7153 13.0122 19.7996 12.8 19.7996H3.20005C2.98788 19.7996 2.78439 19.7153 2.63436 19.5653C2.48433 19.4153 2.40005 19.2118 2.40005 18.9996V2.99961ZM3.20005 0.599609C2.56353 0.599609 1.95308 0.852466 1.50299 1.30255C1.05291 1.75264 0.800049 2.36309 0.800049 2.99961V18.9996C0.800049 19.6361 1.05291 20.2466 1.50299 20.6967C1.95308 21.1468 2.56353 21.3996 3.20005 21.3996H12.8C13.4366 21.3996 14.047 21.1468 14.4971 20.6967C14.9472 20.2466 15.2 19.6361 15.2 18.9996V2.99961C15.2 2.36309 14.9472 1.75264 14.4971 1.30255C14.047 0.852466 13.4366 0.599609 12.8 0.599609H3.20005ZM5.60005 17.6396C5.45153 17.6396 5.30909 17.6986 5.20407 17.8036C5.09905 17.9086 5.04005 18.0511 5.04005 18.1996C5.04005 18.3481 5.09905 18.4906 5.20407 18.5956C5.30909 18.7006 5.45153 18.7596 5.60005 18.7596H10.4C10.5486 18.7596 10.691 18.7006 10.796 18.5956C10.901 18.4906 10.96 18.3481 10.96 18.1996C10.96 18.0511 10.901 17.9086 10.796 17.8036C10.691 17.6986 10.5486 17.6396 10.4 17.6396H5.60005Z" fill="black"/>
            </svg>
                Sign in with Mobile Number
            </div>
        </div>

        <div className='mt-6'>
            <p className='text-gray-500'>
                Don’t have an account?{' '}
                <a href='#' className='text-customGreen font-bold hover:text-gray-600'>
                    Create a New Account
                </a>
            </p>
        </div>
    </div>
</div>


                    </div>
                    {children}
                </div>
            </div>
        </div>
    </div>
    );
};

export default Modal;
