import { combineReducers } from "redux";
import {
  homeReducer,
  homeNotificationReducer,
  headerReducer,
} from "./homeReducer";
import {
  productReducer,
  productFilterListReducer,
  productFilterListitemsReducer,
} from "./productReducer";
import {
  cartReducer,
  cartNumberReducer,
  youMayLikeReducer,
  updateCartLatestPricereducer,
  addtocartreducer,
} from "./cartReducer";
import { orderReducer, useraddressreducer } from "./orderReducer";
import authReducer from "./authReducer";
import { myAccountReducer } from "./myAccountReducer";
// import checkoutReducer from './checkoutReducer';
// import { create_post_Reducer } from './create_post_Reducer';
// import { sndPostReducer } from './sendPostReducer';

const appReducer = combineReducers({
  homeReducer: homeReducer,
  homeNotificationReducer: homeNotificationReducer,
  cartReducer: cartReducer,
  productFilterListitemsReducer: productFilterListitemsReducer,
  orderReducer: orderReducer,
  authReducer: authReducer,
  productReducer: productReducer,
  cartNumberReducer: cartNumberReducer,
  useraddressreducer: useraddressreducer,
  youMayLikeReducer: youMayLikeReducer,
  updateCartLatestPricereducer: updateCartLatestPricereducer,
  productFilterListReducer: productFilterListReducer,
  addtocartreducer: addtocartreducer,
  headerReducer: headerReducer,
  myAccountReducer: myAccountReducer,
  // create_post_Reducer: create_post_Reducer as any,
  // sendPostReducer:sndPostReducer as any
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
