export const GET_ORDERS_SUMMARY_QUERY = `
    query MyQuery($orderId: OrderCondition) {
        allOrders(condition: $orderId) {
            nodes {
                paymentMode
                shoppingCartByCartId {
                    discountedPrice
                    shippingCharge
                    discount
                    shoppingCartItemsByShoppingCartId {
                        nodes {
                            transSkuListByProductSku {
                                discountPrice
                                discount
                                generatedSku
                                sellingPrice
                                purity
                                metalColor
                                isReadyToShip
                                vendorDeliveryTime
                                productListByProductId {
                                    productImagesByProductId(filter: {isdefault: {equalTo: true}, imagePosition: {equalTo: 1}}) {
                                        nodes {
                                            imageUrl
                                        }
                                    }
                                    productName
                                    productType
                                    productDiamondsByProductSku {
                                        nodes {
                                            stoneWeight
                                        }
                                    }
                                }
                                skuWeight
                                markupPrice
                                skuSize
                            }
                            qty
                        }
                    }
                    giftwrapsByCartId {
                        nodes {
                            message
                            giftTo
                        }
                    }
                    cartAddressesByCartId(condition: {addressType: 1}) {
                        nodes {
                            addressline1
                            city
                            contactNumber
                            country
                            countryCode
                            firstname
                            lastname
                            pincode
                            state
                            userProfileByUserprofileId {
                              email
                            }
                        }
                    }
                }
                createdAt
                id
                cartId
            }
        }
    }
`;
//for payment failure
export const PAYMENTFAILED=`query MyQuery($orderId: OrderCondition) {
  allOrders(condition: $orderId) {
    nodes {
      paymentMode
      shoppingCartByCartId {
        discountedPrice
        discount
        shoppingCartItemsByShoppingCartId {
          nodes {
            transSkuListByProductSku {
              discountPrice
              discount
              generatedSku
              sellingPrice
              purity
              metalColor
              isReadyToShip
              vendorDeliveryTime
              productListByProductId {
                productImagesByProductId(filter: {isdefault: {equalTo: true}, imagePosition: {equalTo: 1}}) {
                  nodes {
                    imageUrl
                  }
                }
                productName
                productType
                productDiamondsByProductSku {
                  nodes {
                    stoneWeight
                  }
                }
              }
              skuWeight
              markupPrice
              skuSize
            }
            qty
          }
        }
        giftwrapsByCartId {
          nodes {
            message
            giftTo
          }
        }
        cartAddressesByCartId(condition: {addressType: 1}) {
          nodes {
            addressline1
            city
            contactNumber
            country
            countryCode
            firstname
            lastname
            pincode
            state
            addressType
            shoppingCartByCartId {
              userProfileByUserprofileId {
                email
              }
            }
          }
        }
      }
      createdAt
      id
    }
  }
}
`
//query for filter suggestion products
export const SUGGESTEDPRODUCTS=`query MyQuery($productName: String!) {
  allTransSkuLists(
    condition: {purity: "92.5"}
    filter: {
      productListByProductId: {
        isactive: {equalTo: true},
        or: [
          {productName: {likeInsensitive: $productName}},          
          {productId: {likeInsensitive: $productName}}
        ],
        productImagesByProductId: {some: {imagePosition: {equalTo: 1}}}
      }
    }
    orderBy: UPDATED_AT_DESC
  ) {
    nodes {
      skuUrl
      productListByProductId {
        productName
        productImagesByProductId(condition: {imagePosition: 1}) {
          nodes {
            imageUrl
            imagePosition
          }
        }
      }
      skuId
      markupPrice
      generatedSku
      productId
    }
  }
}`
//query to retreive to get data for my account - Personal information, Manage address, wishlist, all orders....
export const MYACCOUNT = `
query MyCombinedQuery($userprofileIdUUID: UUID!, $userprofileIdString: String!) {
  userProfileById(id: $userprofileIdUUID) {
    lastName
    firstName
    city
    country
    countryCode
    email
    mobile
    salutation
    pincode
    ordersByUserProfileId(
      orderBy: CREATED_AT_DESC      
      filter: {id: {isNull: false}}
    ) {
      nodes {
        paymentId
        paymentMode
        paymentStatus
        orderStatus
        shoppingCartByCartId {
          id
          shoppingCartItemsByShoppingCartId {
            nodes {
              productSku 
              qty 
              transSkuListByProductSku { 
                markupPrice 
                discountPrice 
                skuUrl 
                productListByProductId { 
                  productName 
                  productImagesByProductId(filter: {imagePosition: {equalTo: 1}}) { 
                    nodes { 
                      imageUrl 
                      imagePosition 
                    } 
                  } 
                } 
              } 
            } 
          } 
        } 
        id 
      } 
      totalCount 
    } 
    userWhislistsByUserprofileId(condition: {isActive: true}) { 
      totalCount 
      nodes { 
        productId 
        skuId 
        isActive 
        transSkuListBySkuId { 
          markupPrice 
          skuUrl 
        } 
        productListByProductId { 
          productImagesByProductId(condition: {imagePosition: 1}) { 
            nodes { 
              imageUrl 
              imagePosition 
            } 
          } 
          productName 
        } 
      } 
    } 
  }

  allUserAddresses(condition: {userprofileId: $userprofileIdString, isActive: true}, orderBy: UPDATED_AT_ASC) {
    nodes {
      addressline1,
      addressline2,
      id,
      city,
      contactno: contactNumber,
      country,
      countryCode,
      defaultBilling,
      defaultShipping,
      firstname,
      lastname,
      pincode,
      state,
      isActive,
      updatedAt,
      createdAt,
      salutation,
    }
  }
}`

export const PRODUCT_HOME_COUNT = `
query MyQuery {
  rings: allTransSkuLists(
    condition: {metalColor: "Gold", purity: "92.5"}
    filter: {
      productListByProductId: {
        isactive: {equalTo: true}
        productType: {equalTo: "Rings"}
      }
    }
  ) {
    totalCount
  }
  
  earrings:allTransSkuLists(
    condition: {metalColor: "Gold", purity: "92.5"}
    filter: {
      productListByProductId: {
        isactive: {equalTo: true}
        productType: {equalTo: "Earrings"}
      }
    }
  ) {
    totalCount
  }
  
  bangles:allTransSkuLists(
    condition: {metalColor: "Gold", purity: "92.5"}
    filter: {
      productListByProductId: {
        isactive: {equalTo: true}
        productType: {equalTo: "Bangles"}
      }
    }
  ) {
    totalCount
  }
  
  pendants: allTransSkuLists(
    condition: {metalColor: "Gold", purity: "92.5"}
    filter: {
      productListByProductId: {
        isactive: {equalTo: true}
        productType: {equalTo: "Pendants"}
      }
    }
  ) {
    totalCount
  }

  
}


`;
export const HOMENOTIFICATIONQUERY=`query MyQuery {
  cdnByPage(page: "styloriSilver") {
    data
    isActive
  }
}`;
export const cart_count_query = `query FetchCartId($UserId: ShoppingCartCondition) {
    allShoppingCarts(condition: $UserId, orderBy: UPDATED_AT_DESC, first: 1) {
      nodes {
        userprofileId
        shippingCharge
        id
        status
        shoppingCartItemsByShoppingCartId {
          nodes {
            qty
            productSku
            price
            isComboOffer
            comboMainProduct
          }
        }
      }
    }
  }`;

  export const CARTIDLOGIN=`query MyQuery($userprofileId: UUID!) {
  allShoppingCarts(
    orderBy: CREATED_AT_DESC
    condition: {userprofileId: $userprofileId}
    first: 1
  ) {
    nodes {
      id
      userprofileId
      grossAmount
    }
  }
}
`

export const PRODUCTDETAILS=`query MyQuery($conditionfilter: TransSkuListCondition, $conditionImage: ProductImageCondition, $productnamefilter: TransSkuListFilter, $number: Int) {
  allTransSkuLists(
    condition: $conditionfilter
    filter: $productnamefilter
    first: $number
  ) {
    nodes {
      skuSize
      markupPrice
      sellingPrice
      purity
      discount
      skuWeight
      isActive
      metalColor
      maxOrderQty
      minOrderQty
      isActive
      discountPrice
      generatedSku
      skuId
      isReadyToShip
      vendorDeliveryTime
      discountPriceTax
      markupPriceTax
      transSkuDescriptionsBySkuId {
        nodes {
          skuDescription
          ringsizeImage
          certificate
        }
      }
      diamondType
      productListByProductId {
        prodDescription
        productName
        productId
        defaultSize
        width
        height
        isactive
        sizeVarient
        colourVarient
        earringBacking
        defaultWeight
        productType
        productDiamondsByProductSku {
          nodes {
            diamondClarity
            diamondColour
            diamondType
            stoneWeight
            diamondShape
            diamondSettings
            stoneCount
          }
        }
        productGemstonesByProductSku {
          nodes {
            gemstoneType
            gemstoneSize
            gemstoneShape
            gemstoneSetting
            productSku
            stoneCount
            stoneWeight
          }
        }
        productImagesByProductId(
          condition: $conditionImage
          orderBy: IMAGE_POSITION_ASC
        ) {
          nodes {
            ishover
            imageUrl
            imagePosition
            productColor
          }
        }
        productMaterialsByProductSku {
          nodes {
            materialName
          }
        }
      }
      pricingSkuMaterialsByProductSku {
        nodes {
          materialName
          component
          discountPrice
          sellingPrice
          markup
        }
      }
      pricingSkuMetalsByProductSku {
        nodes {
          materialName
          discountPrice
          sellingPrice
          markup
        }
      }
    }
  }
}`
export const CART_COUNT_QUERY_NEW = `query FetchCartId($UserId: ShoppingCartCondition) {
  allShoppingCarts(condition: $UserId, orderBy: UPDATED_AT_DESC, first: 1) {
    nodes {
      userprofileId
      shippingCharge
      id
      status
      shoppingCartItemsByShoppingCartId {
        nodes {
          qty
          productSku
          price
          comboMainProduct
          transSkuListByProductSku {
          discount  
          discountPrice
            generatedSku
            isReadyToShip
            markupPrice
            purity
            skuSize
            skuUrl
            productListByProductId {
              productId
              productName
              productImagesByProductId(condition: {imagePosition: 1}) {
                nodes {
                  imageUrl
                }
              }
            }
          }
        }
      }
      grossAmount
      netAmount
      discount
      discountedPrice
      shippingCharge
    }
  }
}
`
export const CART = `query myquerycart($productList: [String!]) {
    allTransSkuLists(filter: {generatedSku: {in: $productList}}) {
      nodes {
        generatedSku
        purity
        metalColor
        discountPrice
        discount
        minOrderQty
        maxOrderQty
        skuId
        markupPrice
        skuUrl
        isReadyToShip
        productListByProductId {
          productId
          productName
          isactive
          productImagesByProductId(filter: {imagePosition: {equalTo: 1}}) {
            nodes {
              imageUrl
            }
          }
          productType
          productMaterialsByProductSku {
            nodes {
              materialName
            }
          }
          productDiamondsByProductSku {
            nodes {
              diamondColour
              diamondShape
              stoneWeight
            }
          }
          productGemstonesByProductSku {
            nodes {
              gemstoneSize
              gemstoneType
              stoneWeight
            }
          }
        }
        skuWeight
        diamondType
        skuSize
      }
    }
  }
  `
export const RECENTLY_VIEWED_AND_YOU_MAY_ALSO_LIKE= `query MyQuery(
  $filterdata: ProductListFilter,
  $filterdatatranssku: TransSkuListFilter,
  $filterdatatranssku2: TransSkuListFilter,
  $filterdata2: ProductListFilter,
  $imgcondition: ProductImageCondition,
  $Conditiondatatranssku: TransSkuListCondition,
  $Conditiondatatranssku2: TransSkuListCondition
) {
  youMayalsolike1: allProductLists(filter: $filterdata, orderBy: CREATED_AT_DESC, first: 16) {
    nodes {
      productImagesByProductId(condition: $imgcondition) {
        nodes {
          imageUrl
        }
      }
      transSkuListsByProductId(condition: $Conditiondatatranssku, filter: $filterdatatranssku) {
        nodes {
          discountPrice
          generatedSku
          skuUrl
          productListByProductId {
            productMaterialsByProductSku {
              nodes {
                materialName
              }
            }
          }
        }
      }
      productName
      productType
      createdAt
    }
    totalCount
  }

  youMayalsolike2: allProductLists(filter: $filterdata2, orderBy: CREATED_AT_DESC, first: 8) {
    nodes {
      productImagesByProductId(condition: $imgcondition) {
        nodes {
          imageUrl
        }
      }
      transSkuListsByProductId(condition: $Conditiondatatranssku2, filter: $filterdatatranssku2) {
        nodes {
          discountPrice
          generatedSku
          skuUrl
          productListByProductId {
            productMaterialsByProductSku {
              nodes {
                materialName
              }
            }
          }
        }
      }
      productName
      productType
      createdAt
    }
    totalCount
  }
}`

export const ALLUSERADDRESSESS = `query MyQuery($userprofileId: String) {
  allUserAddresses(
    condition: {userprofileId: $userprofileId, isActive: true}, 
    orderBy: UPDATED_AT_ASC
  ) {
    nodes {
      addressline1
      addressline2
      id
      city
      contactno: contactNumber
      country
      countryCode
      defaultBilling
      defaultShipping
      firstname
      lastname
      pincode
      state
      isActive
      updatedAt
      createdAt
      salutation
    }
  }
}
`
export const CURRENCYANDCOUNTRIES = `query {
  allMasterCountries(condition: { isActive: true }) {
    nodes {
      currency
      currencyAlias
      currencySymbol
      fxConversionRate
      id
      isActive
      iso
      iso3
      name
      nicename
      numcode
      phonecode
    }
  }
}
`
export const GET_EMAIL_SUBSCRIPTIONS = `
    query MyQuery($email: String!) {
      allEmailSubscriptions(
        condition: { email: $email, isActive: true }
      ) {
        nodes {
          email
        }
      }
    }
  `;

  export const GET_PINCODE_DETAILS = `
  query GetPincodeDetails($pincode: String!) {
    allPincodeMasters(
      condition: { isActive: true, isDelivery: true, pincode: $pincode }
    ) {
      nodes {
        area
        country
        createdAt
        district
        id
        isActive
        isCod
        isDelivery
        lat
        lng
        maxCartvalue
        minCartvalue
        pincode
        state
        updatedAt
      }
    }
  }
`;
export const DISTINCTCOUNTRY=`query MyQuery {
  allMasterCountries(condition: {isActive: true}) {
    nodes {
      currencySymbol
      currencyAlias
      currency
      name
      nicename
      fxConversionRate
      isActive
      iso
      iso3
      phonecode
      numcode
      id
    }
  }
}`;

export const GET_DETAILS_WITH_PINCODE=`query GetPincodeDetails($country: String!, $pincode: String!) {
  allPincodeMasters(
    condition: {country: $country, pincode: $pincode, isDelivery: true, isActive: true}
  ) {
    nodes {
      area
      country
      createdAt
      district
      id
      isActive
      isCod
      isDelivery
      lat
      lng
      maxCartvalue
      minCartvalue
      nodeId
      pincode
      state
      updatedAt
    }
  }
}
`
