import { ACTION_TYPE } from "../action/type";

const initialState = {
  //persional information
  persionalInformationDataLoading: false,
  persionalInformationDataSuccess: null,
  persionalInformationDataError: null,
  //address
  persionalAddressDataLoading: false,
  persionalAddressDataSuccess: null,
  persionalAddressDataError: null,
  //wishlist
  wishListDataLoading: false,
  wishListDataSuccess: null,
  wishListDataError: null,
  //all orders
  allOrdersDataLoading: false,
  allOrdersDataSuccess: null,
  allOrdersDataError: null,
  //single order
  singleOrderDataLoading: false,
  singleOrderDataSuccess: null,
  singleOrderDataError: null,
};
export const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST:
      return {
        ...state,
        persionalInformationDataLoading: true,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess:
          action.payload.persionalInformationDataSuccess,
        persionalInformationDataError: null,
      };
    case ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_FAILURE:
      return {
        ...state,
        persionalInformationDataLoading: false,
        persionalInformationDataSuccess: null,
        persionalInformationDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST:
      return {
        ...state,
        persionalAddressDataLoading: true,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: action.payload.persionalAddressDataSuccess,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: null,
      };
    case ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_FAILURE:
      return {
        ...state,
        persionalAddressDataLoading: false,
        persionalAddressDataSuccess: null,
        persionalAddressDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST:
      return {
        ...state,
        wishListDataLoading: true,
        wishListDataSuccess: null,
        wishListDataError: null,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: action.payload.wishListDataSuccess,
        wishListDataError: null,
      };
    case ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: null,
        wishListDataError: null,
      };
    case ACTION_TYPE.GET_WISH_LIST_DATA_FAILURE:
      return {
        ...state,
        wishListDataLoading: false,
        wishListDataSuccess: null,
        wishListDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST:
      return {
        ...state,
        allOrdersDataLoading: true,
        allOrdersDataSuccess: null,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: action.payload.allOrdersDataSuccess,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: null,
        allOrdersDataError: null,
      };
    case ACTION_TYPE.GET_ALL_ORDERS_DATA_FAILURE:
      return {
        ...state,
        allOrdersDataLoading: false,
        allOrdersDataSuccess: null,
        allOrdersDataError: action.errorMessage,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_REQUEST:
      return {
        ...state,
        singleOrderDataLoading: true,
        singleOrderDataSuccess: null,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: action.payload.singleOrderDataSuccess,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: null,
        singleOrderDataError: null,
      };
    case ACTION_TYPE.GET_SINGLE_ORDER_DATA_FAILURE:
      return {
        ...state,
        singleOrderDataLoading: false,
        singleOrderDataSuccess: null,
        singleOrderDataError: action.errorMessage,
      };

    default:
      return state;
  }
};
