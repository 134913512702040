import { ACTION_TYPE } from './type'
import Config from "../../utilities/config";
import { fetchOrders,useraddressess,payment_order_failed,resendorderemail } from '../../utilities/api';
const allOrders= {
    "nodes": [
        {
            "paymentMode": "Prepaid",
            "shoppingCartByCartId": {
                "discountedPrice": 4577.76,
                "discount": null,
                "shoppingCartItemsByShoppingCartId": {
                    "nodes": [
                        {
                            "transSkuListByProductSku": {
                                "discountPrice": 5086.4,
                                "discount": 5,
                                "generatedSku": "SNSC8411-92570000",
                                "sellingPrice": null,
                                "purity": "92.5",
                                "metalColor": "Gold",
                                "isReadyToShip": false,
                                "vendorDeliveryTime": 1,
                                "productListByProductId": {
                                    "productImagesByProductId": {
                                        "nodes": [
                                            {
                                                "imageUrl": "product/SNSC8411/SNSC8411-1G.jpg"
                                            }
                                        ]
                                    },
                                    "productName": "Intricate Attigai Necklace",
                                    "productType": "Short Necklace",
                                    "productDiamondsByProductSku": {
                                        "nodes": []
                                    }
                                },
                                "skuWeight": null,
                                "markupPrice": 4577.759999999999,
                                "skuSize": null
                            },
                            "qty": 1
                        }
                    ]
                },
                "giftwrapsByCartId": {
                    "nodes": []
                },
                "cartAddressesByCartId": {
                    "nodes": [
                        {
                            "addressline1": "No 11/ 15 3rd cross st Gomathipuramam Thiruninravur",
                            "city": "Tiruvallur",
                            "contactNumber": "7358335851",
                            "country": "India",
                            "countryCode": null,
                            "firstname": "Sowmiya",
                            "lastname": "Chandrasekaran",
                            "pincode": "602024",
                            "state": "Tamil Nadu",
                            "addressType": 1,
                            "shoppingCartByCartId": {
                                "userProfileByUserprofileId": {
                                    "email": "cmsowmiya@gmail.com"
                                }
                            }
                        }
                    ]
                }
            },
            "createdAt": "2024-10-14T15:18:01.934+05:30",
            "id": "6705df90-8a11-11ef-bce7-0dfebb27ab37"
        }
    ]
}
export const getOrderSuccessPage = (orderId) => async (dispatch) => {
    Config.logGeneral && console.log(orderId,"orderId111")
    dispatch({ type: ACTION_TYPE.GET_ORDERSUCCESS_DATA_REQUEST });
    
    try {
        Config.logGeneral && console.log(orderId,"orderIdbbbb")
        const allOrders = await fetchOrders(orderId);   
              
    
        dispatch({
            type: ACTION_TYPE.GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS,
            payload:{
                orderSuccessResponse:allOrders
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE, errorMessage: "Failed to get the order summary!!"});
    }
};
//ACTION FOR FAILURE ORDER PAGE
export const getOrderfailPage = (orderId) => async (dispatch) => {
    Config.logGeneral && console.log(orderId,"orderId111")
    dispatch({ type: ACTION_TYPE.GET_ORDERFAILED_DATA_REQUEST });
    
    try {
        Config.logGeneral && console.log(orderId,"orderIdbbbb")
        const allFailOrders = await payment_order_failed(orderId);   
              
    
        dispatch({
            type: ACTION_TYPE.GET_ORDERFAILED_DATA_RESPONSE_SUCCESS,
            payload:{
                orderfailResponse:allFailOrders
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.GET_ORDERFAILED_DATA_RESPONSE_FAILURE, errorMessage: "Failed to get the order summary for failed payment!!"});
    }
};
//ACTION FOR FAILURE ORDER PAGE
export const resend_order_mail = (orderId) => async (dispatch) => {
    Config.logGeneral && console.log(orderId,"orderid received  for resend_order_mail")
    dispatch({ type: ACTION_TYPE.RESENDORDERMAIL_REQUEST});
    
    try {        
        const resendOrders = await resendorderemail(orderId);
        dispatch({
            type: ACTION_TYPE.RESENDORDERMAIL_SUCCESS,
            payload:{
                resendResponse:resendOrders
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ 
            type: ACTION_TYPE.RESENDORDERMAIL_FAIL, 
            errorMessage: error.response && error.response.data && error.response.data.errors ? 
                error.response.data.errors[0].message : 
                "An error occurred while resending the email. Please try again later."
        });
    }
};


//useraddressess..
export const getalluseraddressess = (orderId) => async (dispatch) => {
    Config.logGeneral && console.log(orderId,"orderId111")
    dispatch({ type: ACTION_TYPE.USER_ADDRESSES_REQUEST });
    
    try {
        Config.logGeneral && console.log(orderId,"orderIdbbbb")
        const alluseraddress_data = await useraddressess(orderId);
        console.log(alluseraddress_data,"alluseraddress_data&&&&")    
        dispatch({
            type: ACTION_TYPE.USER_ADDRESSES_SUCCESS,
            payload:{
                useraddressSuccessResponse:alluseraddress_data
            }
        });
    } catch (error) {
        Config.logGeneral && console.log("Error", error)
        dispatch({ type: ACTION_TYPE.USER_ADDRESSES_FAILURE, errorMessage: "Failed to get the useraddress!!"});
    }
};