import React, { useEffect, useState } from 'react'
import config from '../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../utilities/constants';
import { getalluseraddressess } from '../redux/action/orderAction';
import { getCartpagedata, getCartpagedata_count, youMayLikeData } from '../redux/action/cartAction'
import { Link } from 'react-router-dom';
import { getAllCountryNames, createOrder, sendToAirPay, addUserAddress, cartMessageAdd, cartaddressadd } from '../utilities/api';
import { formatCurrency, getValue } from '../utilities/helper';
import { loginModalAction } from '../redux/action/authAction';
import ring1 from '../product_img/chain1.webp';
import ring2 from '../product_img/chain2.webp';


function Checkout() {
  const dispatch = useDispatch();
  let voucherCode = '', buyerPhone = '', buyerPinCode = '';
  const [formData, setFormData] = useState({
    name: '',
    country: 'India',
    address: '',
    contact: '',
    zipCode: '',
    city: '',
    state: ''
  });
  //to get the user info from authReducer
  const { accessToken, userProfile_id, userProfile_email } = useSelector((state) => state.authReducer);
  //to get the cart count...
  const { cart_items_numberResponesLoading, cart_items_numberResponseSuccess, cart_items_numberResponseSuccess_info, cart_items_numberResponseError } = useSelector((state) => state.cartNumberReducer)
  const cartId = cart_items_numberResponseSuccess ? cart_items_numberResponseSuccess?.nodes[0]?.id : "";
  const [giftmessage, setgiftmessage] = useState({ cart_id: cartId, gift_from: userProfile_email, gift_to: "", message: "" })
  const [errors, setErrors] = useState({});//address modal form errors
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressLoading, setisAddressLoading] = useState(false);//address form submitted
  const [iscartmessageLoading, setiscartmessageLoading] = useState(false);//address form submitted
  const [selectedOption, setSelectedOption] = useState(null);//for shipping..
  const [billingOption, setbillingOption] = useState(null);//for billing
  const [billing_shipping_same, setbilling_shipping_same] = useState(1);//for checkbox if billing and shipping address same
  const [countryNames, setCountryNames] = useState([]);//all country names to list in address modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [SelectedOptionEditAddress, setSelectedOptionEditAddress] = useState(null);


  //to get all user address..
  const { userAddressess_success, USER_ADDRESSES_FAILURE, USER_ADDRESSES_REQUEST } = useSelector((state) => state.useraddressreducer);

  let useraddress = userAddressess_success && userAddressess_success?.data?.data?.allUserAddresses?.nodes;
  let billing_address = billing_shipping_same === 1 ? useraddress?.slice(0, 1) : useraddress.filter((item, index) => index !== selectedOption);


  console.log(selectedOption, "selectedOption", billingOption, "billingOption", billing_shipping_same, "billing_shipping_same")
  //modal addresss..
  const addressFormValueChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  //addressFormSubmit
  const validateadressformFields = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `Please fill in your ${key.charAt(0).toUpperCase() + key.slice(1)}`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const cartmessageFieldscheck = () => {
    const newErrors = {};
    Object.keys(giftmessage).forEach((key) => {
      if (!giftmessage[key]) {
        newErrors[key] = `Please fill in your ${key.charAt(0).toUpperCase() + key.slice(1)}`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const cartMessageFormSubmit = async (e) => {
    e.preventDefault();
    if (cartmessageFieldscheck()) {

      setiscartmessageLoading(true); // Set loading to true      
      try {
        // Prepare data for API        
        let gift_from = giftmessage.gift_from, gift_to = giftmessage.gift_to, cart_id = giftmessage.cart_id, message = giftmessage.message;
        // Call the API function
        await cartMessageAdd(cart_id, gift_from, gift_to, message);
        alert("Address added successfully!!")
      } catch (error) {
        console.error("Error adding address:", error);
      } finally {
        setiscartmessageLoading(false); // Reset loading state
      }
    } else {
      console.log("Please fill in all required fields.");
    }
  };

  const addressFormSubmit = async (e) => {
    e.preventDefault();

    if (validateadressformFields()) {
      setisAddressLoading(true); // Set loading to true

      try {
        // Prepare data for API
        let address_to_sendforAPI = [
          {
            "salutation": "",
            "firstname": formData.name,
            "lastname": "",
            "addressline1": formData.address,
            "addressline2": "",
            "pincode": formData.zipCode,
            "city": formData.city,
            "state": formData.state,
            "country": formData.country,
            "country_code": "",
            "contactno": formData.contact,
            "addresstype": "",
            "errortext": { "pinerr": "", "pinerr1": "", "country": "" }
          }
        ];

        // Assuming user_id and id are available in your component
        const user_id = userProfile_id /* Get user ID from state or props */;
        const id_in_useraddress = useraddress[SelectedOptionEditAddress]?.id;/* Get ID from state */

        // Call the API function
        await addUserAddress(address_to_sendforAPI, user_id, id_in_useraddress);
        setIsModalOpen(false);
        dispatch(getalluseraddressess(userProfile_id))
        alert("Address added successfully")
      } catch (error) {
        console.error("Error adding address:", error);
      } finally {
        setisAddressLoading(false); // Reset loading state
      }
    } else {
      console.log("Please fill in all required fields.");
    }
  };


  useEffect(() => {
    if (userProfile_id) {
      dispatch(getalluseraddressess(userProfile_id))
      dispatch(getCartpagedata_count(userProfile_id))
      fetchCountryNames();
    }
    else {
      dispatch(loginModalAction({ isOpenModal: true, modalType: 'cart' }));
    }
    // fetchCountryNames();//to get all country names in address modal
  }, [userProfile_id])


  // Prevent unchecking
  const handleChange = (event) => {
    // Force the checkbox to stay checked
    if (event.target.checked) {
      setbilling_shipping_same(1);
    }
    else {
      setbilling_shipping_same(0);
    }
  };

  const changeGiftMessage = (event) => {
    const { name, value } = event.target;
    console.log(name, " for gift messsage", value)
    if (name === 'gift_to') {
      if (giftmessage.gift_from !== value) {
        setgiftmessage({ ...giftmessage, [name]: value })
      }
      else {
        alert("Mail ID cannot be same as registered mail Id!!!")
      }
    }
    else {
      setgiftmessage({ ...giftmessage, [name]: value })
    }
  }

  //for payment in airpay...
  const handlePayment = async () => {
    setIsLoading(true);
    try {
      // 1. Step: Call createorder API to create an order cartId, voucherCode, userId
      const createOrderResponse = await createOrder(cartId, userProfile_id, voucherCode);
      console.log(createOrderResponse, "createOrderResponse...")

      const createOrderData = createOrderResponse;

      if (!createOrderData || !createOrderData?.id) {
        throw new Error('Failed to create order');
      }

      // 2. Step: Call sendtoairpay API with the order details
      const sendToAirPayResponse = await sendToAirPay(createOrderResponse.id, createOrderResponse.total_amount, buyerPhone, buyerPinCode)


      const sendToAirPayData = sendToAirPayResponse;
      console.log(sendToAirPayData,"sendToAirPayData");
      if (!sendToAirPayData || !sendToAirPayData.checksum) {
        throw new Error('Failed to get AirPay payment data');
      }

      // 3. Step: Create a hidden form dynamically and submit to AirPay
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://payments.airpay.co.in/pay/index.php';
      form.id = 'sendtoairpay';

      // Add input fields dynamically from the response
      // for dynamic below should be used
      const fields = [
        { name: 'privatekey', value: sendToAirPayData.privatekey },
        { name: 'mercid', value: sendToAirPayData.mercid },
        { name: 'orderid', value: createOrderData.payment_id },
        { name: 'currency', value: sendToAirPayData.currency },
        { name: 'isocurrency', value: sendToAirPayData.isocurrency },
        { name: 'chmod', value: sendToAirPayData.chmod },
        { name: 'buyerEmail', value: sendToAirPayData.buyerEmail },
        { name: 'buyerPhone', value: sendToAirPayData.buyerPhone },
        { name: 'buyerFirstName', value: sendToAirPayData.buyerFirstName },
        { name: 'buyerLastName', value: sendToAirPayData.buyerLastName },
        { name: 'buyerAddress', value: sendToAirPayData.buyerAddress },
        { name: 'buyerCity', value: sendToAirPayData.buyerCity },
        { name: 'buyerState', value: sendToAirPayData.buyerState },
        { name: 'buyerCountry', value: sendToAirPayData.buyerCountry },
        { name: 'buyerPinCode', value: sendToAirPayData.buyerPinCode },
        { name: 'amount', value: sendToAirPayData.amount },
        { name: 'checksum', value: sendToAirPayData.checksum },
      ];


    //   const fields = [
    //     { name: 'privatekey', value: "7c6fbe38e75f1b6baaf22926544b289a90793e3af7888a49c07c45094d20388d" },
    //     { name: 'mercid', value: "34701" },
    //     { name: 'orderid', value: "674ea7f594478" },
    //     { name: 'currency', value: "356" },
    //     { name: 'isocurrency', value: "INR" },
    //     { name: 'chmod', value: "" },
    //     { name: 'buyerChitNumber', value: "1" },
    //     { name: 'buyerPhone', value: "9042907304" },
    //     { name: 'buyerFirstName', value: "chandra" },
    //     { name: 'buyerLastName', value: "mohan" },
    //     { name: 'buyerEmail', value: "chandramohan@gmail.com" },
    //     { name: 'amount', value: "1.00" },
    //     { name: 'paymentMode', value: "airpay" },
    //     { name: 'customvar', value: "674ea7f594478" },
    //     { name: 'checksum', value: "9bdf3fc6057675891d2e2f0b9d66264b9148554c1f5899c782ba993c9a46102d" }
    // ];

      // Loop through the fields and create hidden inputs
      fields.forEach(field => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = field.name;
        input.value = field.value;
        form.appendChild(input);
      });

      // Append the form to the document body (hidden from the user)
      document.body.appendChild(form);

      // 4. Step: Submit the form automatically
      form.submit();
    } catch (error) {
      console.error('Payment process error:', error.message);
      // Optionally, handle errors here (e.g., show an alert or notification)
    } finally {
      setIsLoading(false); // Reset isLoading to false when the payment process is complete
    }
  };

  // for shipping
  const handleChange1 = async (event) => {
    // Get the radio button groups by name
    const selectedOptionElements = document.getElementsByName('responsive1');
    const billingOptionElements = document.getElementsByName('responsive');
  
    // Find the selected radio button for both groups
    let selectedValue = null;
    for (let i = 0; i < selectedOptionElements.length; i++) {
      if (selectedOptionElements[i].checked) {
        selectedValue = selectedOptionElements[i].value;
        break; // Exit the loop once we find the selected radio button
      }
    }
  
    let billingValue = null;
    for (let i = 0; i < billingOptionElements.length; i++) {
      if (billingOptionElements[i].checked) {
        billingValue = billingOptionElements[i].value;
        break; // Exit the loop once we find the selected radio button
      }
    }
    // Update React state to keep everything in sync
    if(selectedValue !== null){
      setSelectedOption(parseInt(selectedValue)); // Update the state with the selected shipping address
    } 
    if(billingValue !== null){
      setbillingOption(parseInt(billingValue));   // Update the state with the selected billing address  
    }
     
    // Check if both shipping and billing addresses are selected
    if (billing_shipping_same === 0) {
    if (selectedValue === null || billingValue === null) {
      alert("Select both shipping and billing addresses!");
      return; // Exit if either of the options is not selected
    }}
  
    
  
    let address_info = []; // Array to hold addresses to send to API
    let addressId='';
  
    // Check if billing and shipping addresses are the same
    if (billing_shipping_same === 1) {
      // If billing and shipping are the same, use the selected shipping address from useraddress
      let address_to_send_api = { ...useraddress[parseInt(selectedValue)], addresstype: 1 };
      console.log(address_to_send_api, "address_to_send_api");
      address_info.push(address_to_send_api);  // Add shipping address to address_info
      addressId=address_info.id
    } else {
      // Add shipping address from useraddress
      let address_to_send_api = { ...useraddress[parseInt(selectedValue)], addresstype: 1 };
      address_info.push(address_to_send_api);  // Add shipping address to address_info
  
      // Add billing address from billing_address
      if (billing_address && billing_address.length > 0 && billingValue >= 0 && billingValue < billing_address.length) {
        let billing_address_to_send_api = { ...billing_address[parseInt(billingValue)], addresstype: 2 };
        address_info.push(billing_address_to_send_api);  // Add billing address to address_info
        addressId=address_info.id
      } else {
        console.error('Invalid billingOption selected or billing address not found.');
        alert('Invalid billing option selected or no billing addresses available.');
        return; // Exit if billing address is not valid
      }
    }
  
    // Now that address_info is populated, call the cartaddressadd API function with the correct parameters
    try {
      await cartaddressadd(userProfile_id, address_info, cartId);
      console.log("Address information sent to API:", address_info);
      alert("Address selected has been added to the cart.");
    } catch (error) {
      console.error('Error in cartaddressadd:', error);
      alert("Failed to update the cart address.");
    }
  };
  
  
  

  const changeBillingaddress = (c) => {
    alert(`changeBillingaddress called`)
    setbillingOption(parseInt(c));
  }

  const cartItemsProductInfo = (cart_items_numberResponseSuccess?.nodes[0]?.shoppingCartItemsByShoppingCartId?.nodes) || [];
  const getImage = (image, size = "300X300") => {
    let productId = image.split("/")
    return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`
  }
  const actualPrice = cartItemsProductInfo
    .reduce((total, item) => {
      return total + (item.transSkuListByProductSku.discountPrice || 0);
    }, 0);

  //get all country names from api..
  const fetchCountryNames = async () => {
    try {
      const response = await getAllCountryNames();
      setCountryNames(response);
    } catch (error) {
      console.log(error, " error in getting the all country names....")
    }
  };
  return (
    <>
      {/* {userAddressess_success && <h2>{JSON.stringify(useraddress)}</h2>} */}
      {/* {cart_items_numberResponseSuccess && <div>{JSON.stringify(cart_items_numberResponseSuccess)}</div>} */}
      <div className="bg-white">
        <div className="container mx-auto px-4 2xl:mb-16">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-8">
              <div className='border border-solid px-6 py-6 gap-4 w-full border-none px-0 sm:border sm:border-solid sm:px-6'>
                <div className='md:flex justify-start items-center gap-4 mt-10 md:mt-0'>
                  <div>
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="40.0003" cy="40" r="28" transform="rotate(-45 40.0003 40)" fill="url(#paint0_linear_1345_54)" />
                      <g clip-path="url(#clip0_1345_54)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.8125 30.6885C49.5869 31.3323 50.6482 32.9546 51.9788 35.1875H46.8125V30.6885ZM30.5986 35.375C31.1418 35.375 31.5361 35.6372 31.5361 36.25C31.5361 36.7668 31.1179 37.186 30.6013 37.1875H24.9375C24.4197 37.1875 24 37.6074 24 38.125C24 38.6428 24.4197 39.0625 24.9375 39.0625H33.375C33.8977 39.0625 34.3162 39.4819 34.3162 40C34.3162 40.5178 33.8965 40.9375 33.3787 40.9375H24.9375C24.4197 40.9375 24 41.3572 24 41.875C24 42.3928 24.4197 42.8125 24.9375 42.8125H27V45.625C27 46.1428 27.4197 46.5625 27.9375 46.5625H29.822C30.158 48.2173 31.6201 49.4375 33.3438 49.4375C35.0674 49.4375 36.5295 48.2173 36.8655 46.5625H46.3845C46.7205 48.2173 48.1826 49.4375 49.9062 49.4375C51.6299 49.4375 53.092 48.2173 53.428 46.5625H55.0625C55.5803 46.5625 56 46.1428 56 45.625V40C56 37.2493 53.0986 37.0667 53.0959 37.0625H45.875C45.3572 37.0625 44.9375 36.6428 44.9375 36.125V30.5H27.9375C27.4197 30.5 27 30.9197 27 31.4375V33.5H25.875C25.3572 33.5 24.9375 33.9197 24.9375 34.4375C24.9375 34.9553 25.3572 35.375 25.875 35.375H30.5986ZM51.1216 44.6284C51.7927 45.2996 51.7927 46.3882 51.1216 47.0593C50.042 48.1389 48.1875 47.3708 48.1875 45.8438C48.1875 44.3169 50.042 43.5488 51.1216 44.6284ZM34.5591 44.6284C35.2302 45.2996 35.2302 46.3882 34.5591 47.0593C33.4795 48.1389 31.625 47.3708 31.625 45.8438C31.625 44.3169 33.4795 43.5488 34.5591 44.6284Z" fill="white" />
                      </g>
                      <defs>
                        <linearGradient id="paint0_linear_1345_54" x1="40.0003" y1="12" x2="40.0003" y2="68" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#06AB9F" />
                          <stop offset="0.23" stop-color="#007970" />
                          <stop offset="0.44" stop-color="#06AB9F" />
                          <stop offset="0.65" stop-color="#007970" />
                          <stop offset="0.84" stop-color="#06AB9F" />
                          <stop offset="1" stop-color="#007970" />
                        </linearGradient>
                        <clipPath id="clip0_1345_54">
                          <rect width="32" height="32" fill="white" transform="translate(24 24)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <p className='text-lg font-bold'>Delivery/ Pickup Information</p>
                  </div>
                  <div className='md:ml-auto'>
                    <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]'
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedOptionEditAddress(null);
                        setFormData({
                          name: '',
                          country: 'India',
                          address: '',
                          contact: '',
                          zipCode: '',
                          city: '',
                          state: ''
                        })
                      }
                      }>
                      Add New Address
                    </Link>
                  </div>
                </div>
                {/*shipping address */}
                {(!useraddress || useraddress.length <= 0) ? (
                  <h2>No address to list</h2>
                ) : (
                  <></>
                )}
                {useraddress && useraddress.map((e, i) => {
                  return (
                    <div key={i} className="border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0">
                      <div className="flex justify-between flex-col md:flex-row items-center md:items-center space-y-2 md:space-y-0 md:space-x-4">
                        <div className="flex justify-between">
                          <label className="flex items-start space-x-2 mt-2">
                            <input
                              type="radio"
                              name="responsive1"
                              value={i}
                              className="w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500 mr-2"
                              checked={selectedOption === i}
                              onChange={(e) => handleChange1(e)}
                            />
                          </label>
                          <div>
                            <p className="text-xl font-medium mb-2">{e.firstname} {e.lastname}</p>
                            <p className="text-base text-gray-500">{e.addressline1} {e.city || "Chennai"} {e.state || "Tamil Nadu"} {e.pincode}</p>
                          </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                          <Link to=""
                            onClick={() => {
                              setIsModalOpen(true);
                              setSelectedOptionEditAddress(parseInt(i));
                              setFormData({
                                name: `${e.firstname} ${e.lastname}`,
                                country: e.country,
                                address: e.addressline1,
                                contact: e.contactno,
                                zipCode: e.pincode,
                                city: e.city,
                                state: e.state
                              });
                            }}

                            className="text-black text-base text-center py-2 px-10 border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 mb-3 w-[220px]">
                            Edit Address
                          </Link>
                          {/* {i + 1 === useraddress.length && <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]' onClick={() => setIsModalOpen(true)}>Add New Address</Link>} */}
                        </div>
                      </div>
                    </div>
                  );
                })}


                {useraddress && useraddress.length > 0 && (
                  <div className="flex gap-2 ml-5">
                    <div>
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500"
                        checked={billing_shipping_same}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div>
                      <p className="text-base text-sm text-gray-700">
                        Please check the box if your Billing address is same as your shipping address.
                      </p>
                    </div>
                  </div>
                )}

                
                {billing_address && billing_address?.map((e, i) => {
                  return (
                    <div key={i} className='border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0' >
                      <div className="flex justify-between flex-col md:flex-row items-center md:items-center space-y-2 md:space-y-0 md:space-x-4">
                        <div className='flex justify-between'>
                          <label className="flex items-start space-x-2 mt-2">
                            <input
                              type="radio"
                              name="responsive"
                              value={i}
                              className="w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500 mr-2"
                              checked={billingOption === i}
                              // onChange={() => changeBillingaddress(i)}
                              onChange={(e) => handleChange1(e)}
                            />
                          </label>
                          <div>
                            <p className='text-sm text-bold'>Billing Address</p>
                            <p className='text-xl font-medium mb-2'>{`${e.firstname} ${e.lastname}`}</p>
                            <p className='text-base text-gray-500'>{e.addressline1} {e.city || "Chennai"} {e.state || "Tamil Nadu"} {e.pincode}</p>
                          </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                          {/* <Link 
                          onClick={() =>                            
                            {
                              setIsModalOpen(true);
                              setSelectedOptionEditAddress(parseInt(i));
                              setFormData({
                                name: `${e.firstname} ${e.lastname}`,
                                country: e.country,
                                address: e.addressline1,
                                contact: e.contactno,
                                zipCode: e.pincode,
                                city: e.city,
                                state: e.state
                              });
                            }}
                           to="" className='text-black text-base text-center py-2 px-10 border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 mb-3 w-[220px]'>
                            Edit Address
                          </Link> */}
                          {/* {i + 1 == billing_address.length && <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]'>
                            Add New Address
                          </Link>} */}
                        </div>
                      </div>
                    </div>
                  );
                })}





                <div className='md:flex justify-start items-center gap-4 mt-10 md:mt-10'>
                  <div>
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="40.0003" cy="40" r="28" transform="rotate(-45 40.0003 40)" fill="url(#paint0_linear_1345_54)" />
                      <g clip-path="url(#clip0_1345_54)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.8125 30.6885C49.5869 31.3323 50.6482 32.9546 51.9788 35.1875H46.8125V30.6885ZM30.5986 35.375C31.1418 35.375 31.5361 35.6372 31.5361 36.25C31.5361 36.7668 31.1179 37.186 30.6013 37.1875H24.9375C24.4197 37.1875 24 37.6074 24 38.125C24 38.6428 24.4197 39.0625 24.9375 39.0625H33.375C33.8977 39.0625 34.3162 39.4819 34.3162 40C34.3162 40.5178 33.8965 40.9375 33.3787 40.9375H24.9375C24.4197 40.9375 24 41.3572 24 41.875C24 42.3928 24.4197 42.8125 24.9375 42.8125H27V45.625C27 46.1428 27.4197 46.5625 27.9375 46.5625H29.822C30.158 48.2173 31.6201 49.4375 33.3438 49.4375C35.0674 49.4375 36.5295 48.2173 36.8655 46.5625H46.3845C46.7205 48.2173 48.1826 49.4375 49.9062 49.4375C51.6299 49.4375 53.092 48.2173 53.428 46.5625H55.0625C55.5803 46.5625 56 46.1428 56 45.625V40C56 37.2493 53.0986 37.0667 53.0959 37.0625H45.875C45.3572 37.0625 44.9375 36.6428 44.9375 36.125V30.5H27.9375C27.4197 30.5 27 30.9197 27 31.4375V33.5H25.875C25.3572 33.5 24.9375 33.9197 24.9375 34.4375C24.9375 34.9553 25.3572 35.375 25.875 35.375H30.5986ZM51.1216 44.6284C51.7927 45.2996 51.7927 46.3882 51.1216 47.0593C50.042 48.1389 48.1875 47.3708 48.1875 45.8438C48.1875 44.3169 50.042 43.5488 51.1216 44.6284ZM34.5591 44.6284C35.2302 45.2996 35.2302 46.3882 34.5591 47.0593C33.4795 48.1389 31.625 47.3708 31.625 45.8438C31.625 44.3169 33.4795 43.5488 34.5591 44.6284Z" fill="white" />
                      </g>
                      <defs>
                        <linearGradient id="paint0_linear_1345_54" x1="40.0003" y1="12" x2="40.0003" y2="68" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#06AB9F" />
                          <stop offset="0.23" stop-color="#007970" />
                          <stop offset="0.44" stop-color="#06AB9F" />
                          <stop offset="0.65" stop-color="#007970" />
                          <stop offset="0.84" stop-color="#06AB9F" />
                          <stop offset="1" stop-color="#007970" />
                        </linearGradient>
                        <clipPath id="clip0_1345_54">
                          <rect width="32" height="32" fill="white" transform="translate(24 24)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <p className='text-lg font-bold'>ADD A GIFT MESSAGE</p>
                  </div>
                </div>
                <div className='border border-solid px-6 py-6 gap-4 mx-0 my-5 sm:mx-0'>
                  <div className="w-full mx-auto p-6 bg-white rounded-lg sm: !p-0">
                    <form onSubmit={cartMessageFormSubmit}>
                      <div className="mb-4">
                        <label for="message" className="block text-gray-700 font-medium">Add a Special Message! (Optional)</label>
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          onChange={changeGiftMessage}
                          className="mt-1 p-3 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-gray-100"
                          placeholder="Enter your special message here..."
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-xs">{errors.message}</p>}
                      </div>

                      <div class="mb-4">
                        <label for="gift_to" className="block text-gray-700 font-medium">Gift Recipient’s Email! (Optional)</label>
                        <input
                          type="email"
                          id="gift_to"
                          name="gift_to"
                          onChange={changeGiftMessage}
                          className="mt-1 p-3 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-gray-100"
                          placeholder="Enter recipient's email"
                        />
                        {errors.gift_to && <p className="text-red-500 text-xs">{errors.gift_to}</p>}
                      </div>
                      <div className="mb-4">
                        {/* <Link to="" className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]' 
                    >
                     Add Cart Message
                  </Link> */}
                        <button
                          disabled={iscartmessageLoading}
                          className='text-white text-base text-center py-2 px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]'
                        >
                          Add Cart Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>


              </div>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <div className='flex items-center justify-start bg-customGreen w-full px-4 py-2 text-white font-bold text-normal'>
                <div className='mr-4'>
                  <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.3594 6.49163H14.0096C14.2809 6.04758 14.4375 5.52614 14.4375 4.9688C14.4375 3.35339 13.1232 2.03911 11.5078 2.03911C10.5042 2.03911 9.58561 2.5417 9.04308 3.38545L7.21875 5.74336L5.39442 3.38545C4.85194 2.5417 3.93338 2.03906 2.92969 2.03906C1.31428 2.03906 0 3.35334 0 4.96875C0 5.66948 0.247594 6.31336 0.659484 6.81806C0.259406 7.11755 0 7.59516 0 8.13225V20.3198C0 21.2244 0.735984 21.9604 1.64062 21.9604H22.3594C23.264 21.9604 24 21.2244 24 20.3198V8.13225C24 7.22756 23.264 6.49163 22.3594 6.49163ZM22.5938 20.3198C22.5938 20.449 22.4886 20.5541 22.3594 20.5541H7.92188V8.96569L9.34223 10.9358C9.47967 11.1265 9.69483 11.2278 9.91317 11.2278C10.0556 11.2278 10.1994 11.1847 10.3238 11.095C10.6388 10.8679 10.71 10.4285 10.4829 10.1135L8.88586 7.8983L22.3594 7.89788C22.4886 7.89788 22.5938 8.00302 22.5938 8.13225V20.3198ZM2.92969 3.44531C3.45802 3.44531 3.94116 3.71353 4.22217 4.16273C4.23455 4.18252 4.24791 4.20169 4.26216 4.22011L6.01997 6.492L2.95355 6.49158C2.95148 6.49158 2.94942 6.49158 2.94736 6.49158C2.93925 6.49163 2.93114 6.49186 2.92219 6.49214C2.08561 6.48811 1.40625 5.80631 1.40625 4.96875C1.40625 4.12875 2.08969 3.44531 2.92969 3.44531ZM10.2153 4.16273C10.4963 3.71353 10.9795 3.44531 11.5078 3.44531C12.3478 3.44531 13.0312 4.12875 13.0312 4.96875C13.0312 5.80636 12.3518 6.4883 11.5151 6.49219C11.5074 6.49195 11.4998 6.49177 11.4922 6.49167C11.4894 6.49167 11.4867 6.49163 11.484 6.49163L8.41753 6.49205L10.1753 4.22011C10.1896 4.20164 10.203 4.18252 10.2153 4.16273ZM1.40625 20.3198V8.13225C1.40625 8.00302 1.51139 7.89788 1.64062 7.89788L5.60967 7.89825L4.2195 9.93488C4.00059 10.2556 4.08309 10.6931 4.40386 10.912C4.72462 11.1309 5.16206 11.0484 5.38102 10.7277L6.51562 9.06539V20.5541H1.64062C1.51139 20.5541 1.40625 20.4489 1.40625 20.3198Z" fill="white" />
                    <path d="M12.9375 12.1172H16.4531C16.8414 12.1172 17.1562 11.8024 17.1562 11.4141C17.1562 11.0257 16.8414 10.7109 16.4531 10.7109H12.9375C12.5492 10.7109 12.2344 11.0257 12.2344 11.4141C12.2344 11.8024 12.5492 12.1172 12.9375 12.1172Z" fill="white" />
                    <path d="M19.2656 13.5234H12.9375C12.5492 13.5234 12.2344 13.8382 12.2344 14.2266C12.2344 14.6149 12.5492 14.9297 12.9375 14.9297H19.2656C19.6539 14.9297 19.9688 14.6149 19.9688 14.2266C19.9688 13.8382 19.6539 13.5234 19.2656 13.5234Z" fill="white" />
                    <path d="M19.2656 16.3359H12.9375C12.5492 16.3359 12.2344 16.6507 12.2344 17.0391C12.2344 17.4274 12.5492 17.7422 12.9375 17.7422H19.2656C19.6539 17.7422 19.9688 17.4274 19.9688 17.0391C19.9688 16.6507 19.6539 16.3359 19.2656 16.3359Z" fill="white" />
                  </svg>
                </div>
                <div>
                  Apply Coupon
                </div>
              </div>
              <div className='px-4 py-4 border border-solid mt-5 pb-10'>
                <p className='font-medium text-lg mb-5 mt-5'>Order Summary</p>
                <div className='flex justify-between'>
                  <div>
                    <p className='mb-3'>Subtotal</p>
                    <p className='text-green-500 mb-3 font-bold'>You Saved</p>
                    <p className='mb-3'>Delivery Changers</p>
                    <p className='mb-3 font-medium text-lg'>Total Cost</p>
                  </div>
                  <div className='text-right'>
                    <p className='mb-3'>{formatCurrency(actualPrice)}  {formatCurrency(cart_items_numberResponseSuccess?.nodes[0]?.netAmount)}</p>
                    <p className='text-green-500 mb-3 font-bold'>- {formatCurrency(actualPrice - cart_items_numberResponseSuccess?.nodes[0]?.netAmount)}</p>
                    <p className='mb-3'>{formatCurrency(cart_items_numberResponseSuccess?.nodes[0]?.shippingCharge)}</p>
                    <p className='mb-3 text-lg font-bold'>{formatCurrency(cart_items_numberResponseSuccess?.nodes[0]?.grossAmount)}</p>
                  </div>
                </div>
                <p className='font-normal text-gray-500 text-sm'>International shipping charges will be calculated at the checkout page Checkout Securely</p>
              </div>
              {cart_items_numberResponseSuccess_info &&
                cart_items_numberResponseSuccess_info.map((item, index) => (
                  <div key={index} className="px-4 py-4 border border-solid mt-5">
                    <div className="flex">
                      <div>
                        <img src={getImage(item?.transSkuListByProductSku?.productListByProductId?.productImagesByProductId.nodes[0].imageUrl)} alt="Ring" className="w-28 mr-3 border border-solid border-gray-300 rounded-md p-1" />
                      </div>
                      <div>
                        <p className="text-base font-medium mb-1">{item?.transSkuListByProductSku?.productListByProductId?.productName}</p>
                        <p className="text-gray-500 mb-1">{item?.productSku}</p>
                        <p className="text-gray-500 text-sm">Qty: 1</p>
                      </div>
                    </div>
                  </div>
                ))
              }



            </div>
          </div>
        </div>
        <div className='bg-white border border-slate-200 py-8 px-0 shadow-top-only mt-5'>
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 lg:col-span-6 mx-auto ">
                <p className='text-lg font-semibold text-gray-800'>Total ({cartItemsProductInfo?.length || '0'}Items) : <span>{formatCurrency(cart_items_numberResponseSuccess?.nodes[0]?.grossAmount)}</span></p>
              </div>
              <div className="col-span-12 lg:col-span-6 mx-auto">
                <Link to="" onClick={handlePayment} disabled={isLoading} className='text-white text-base text-center py-4 shadow-md px-10 bg-customGreen border border-customLightGreen hover:bg-white hover:border-gray-500 hover:text-customLightGreen duration-300 w-[220px]'>Proceed to Pay</Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-full md:w-3/4 lg:w-2/6 shadow-lg p-5 md:p-7 relative">
            <button onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-2xl bg-customGreen text-white w-7 h-7 rounded-2xl leading-1"
            >
              &times;
            </button>
            <div className="grid grid-cols-1 gap-4">
              <div className="text-left">
                <p className="text-lg font-semibold">{SelectedOptionEditAddress !== null ? `Edit` : `Add`} Address</p>
              </div>
              <form className="space-y-4" onSubmit={addressFormSubmit}>
                {/* Name and Country */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-bold text-gray-700">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      onChange={addressFormValueChange}
                      placeholder="Enter your name"
                    />
                    {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">Country</label>
                    <select name="country" className="w-full p-2 border border-gray-300 rounded-md"
                      value={formData.country}
                      onChange={addressFormValueChange}>
                      {countryNames.map((countryName, index) => (
                        <option key={index} value={countryName.nicename}>
                          {countryName.nicename}
                        </option>
                      ))}
                    </select>
                    {errors.country && <p className="text-red-500 text-xs">{errors.country}</p>}
                  </div>
                </div>
                {/* Address */}
                <div>
                  <label className="block text-sm font-bold text-gray-700">Address</label>
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded-md"
                    name="address"
                    rows="3"
                    // value={useraddress[SelectedOptionEditAddress]?.firstname + " "+ useraddress[SelectedOptionEditAddress]?.lastname}
                    placeholder="Enter your address" onChange={addressFormValueChange}
                  >
                    {formData.address}
                  </textarea>
                  {errors.address && <p className="text-red-500 text-xs">{errors.address}</p>}
                </div>
                {/* Contact and ZIP Code */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-bold text-gray-700">Contact</label>
                    <input
                      type="text"
                      name="contact"
                      value={formData.contact}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Enter your contact" onChange={addressFormValueChange}
                    />
                    {errors.contact && <p className="text-red-500 text-xs">{errors.contact}</p>}
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">ZIP Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Enter ZIP Code" onChange={addressFormValueChange}
                    />
                    {errors.zipCode && <p className="text-red-500 text-xs">{errors.zipCode}</p>}
                  </div>
                </div>

                {/* City and State */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-bold text-gray-700">City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      className="w-full p-2 border border-gray-300 rounded-md" onChange={addressFormValueChange}
                      placeholder="Enter your city"
                    />
                    {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">State</label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      className="w-full p-2 border border-gray-300 rounded-md" onChange={addressFormValueChange}
                      placeholder="Enter your state"
                    />
                    {errors.state && <p className="text-red-500 text-xs">{errors.state}</p>}
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex justify-end gap-4">
                  <button type="button" onClick={() => setIsModalOpen(false)}
                    className="px-4 py-2 bg-white border  text-gray-700 rounded-md hover:bg-gray-400 w-3/6 hover:bg-gray-300 mt-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isAddressLoading} // Disable button if loading
                    className={`px-4 py-2 ${isAddressLoading ? 'bg-gray-400' : 'bg-customGreen'} text-white rounded-md hover:bg-green-600 w-3/6 mt-3`}
                  >
                    {isAddressLoading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )};
      {/* Modal */}

    </>
  )
}

export default Checkout