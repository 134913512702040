import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accountpageindividualcontent from "./Accountpageindividualcontent";
import { getPersionalData } from "../redux/action/myAccountAction";
import constants from "../utilities/constants";
import AddAddress from "../product_img/AddAddress.svg";

function Accountpagecontent({
  header = {},
  handleModel,
  handleButtonChange,
  handleClick,
}) {
  const dispatch = useDispatch();
  const {
    //persionalinformation
    persionalInformationDataLoading,
    persionalInformationDataSuccess,
    persionalInformationDataError,
    //address
    persionalAddressDataLoading,
    persionalAddressDataSuccess,
    persionalAddressDataError,
    //wishlist
    wishListDataLoading,
    wishListDataSuccess,
    wishListDataError,
    //all orders
    allOrdersDataLoading,
    allOrdersDataSuccess,
    allOrdersDataError,
    //single order
    singleOrderDataLoading,
    singleOrderDataSuccess,
    singleOrderDataError,
  } = useSelector((state) => state.myAccountReducer);
  useEffect(() => {
    dispatch(getPersionalData());
  }, []);
  return (
    <main>
      <section className="border-b  border-b-[#D9D9D9] p-4 flex flex-row justify-between">
        <span className="font-Cormorant font-bold text-lg">
          {header?.title}
        </span>
        {header?.actionTitle !== null && (
          <button
            onClick={() => {
              handleModel(header?.title, true);
            }}
            className={`border  text-[16px] p-2 font-medium ${
              header?.title === constants.ManageAddress ||
              header?.title === constants.Wishlist
                ? "bg-[#15AB9E] text-white"
                : "bg-inherit border-[#262626]"
            }`}
          >
            {header?.title === constants.ManageAddress ? (
              <span className="flex flex-row justify-center items-center">
                <img
                  src={AddAddress}
                  className="mx-2 w-5 h-5"
                  alt="add address"
                />
                <span>{header?.actionTitle}</span>
              </span>
            ) : (
              <span>{header?.actionTitle}</span>
            )}
          </button>
        )}
      </section>
      <section>
        <Accountpageindividualcontent
          showContent={header?.title}
          handleModel={handleModel}
          onClick={handleButtonChange}
          handleClick={handleClick}
        />
      </section>
    </main>
  );
}

export default Accountpagecontent;
