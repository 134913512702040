import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dateimg from "../../product_img/date.png";
const Datepic = ({
  label = "test",
  placeholder = "test",
  value,
  onChange,
  type = "text",
  keyvalue,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const handleSelectedDate = (date) => {
    setSelectedDate(date);

    onChange(label, date);
  };
  return (
    <section key={keyvalue}>
      <div className="text-sm  font-medium text-[#353535]">
        <label>{label}</label>
      </div>

      <div className="relative mt-4 mb-5 border border-[#CACACA] ">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => handleSelectedDate(date)}
          className="block w-full px-4 py-2    focus:outline-none"
          placeholderText="Select a date"
          dateFormat="dd MMM yyyy"
          shouldCloseOnSelect={true} // Close calendar on date select
        />
        <img
          src={Dateimg} // Replace with your image path
          alt="Calendar Icon"
          className="absolute top-1/2 right-4 w-5 h-5 transform -translate-y-1/2 pointer-events-none"
        />
      </div>
    </section>
  );
};

export default Datepic;
