// src/store/actions/authActions.js

import { authenticateUser } from "../../utilities/api";
import { ACTION_TYPE } from './type'

export const loginRequest = () => ({
    type: ACTION_TYPE.LOGIN_REQUEST,
});

export const loginSuccess = (userData) => ({
   
    type: ACTION_TYPE.LOGIN_SUCCESS,
    payload: userData,
});

export const loginFailure = (error) => ({
    type: ACTION_TYPE.LOGIN_FAILURE,
    payload: error,
});

// Async action to handle user login
export const login = (email, password) => {
    console.log("loginResponreducer",email,"password",password)
    return (dispatch) => {
        dispatch(loginRequest());
        authenticateUser(email, password)
            .then(data => {
                console.log("loginResponseSuccess",data)
                dispatch(loginSuccess(data)); // Dispatch success action with user data
            })
            .catch(error => {
                console.log(error," error in login....")
                dispatch(loginFailure(error)); // Dispatch failure action with error message
            });
    };
};

export const loginModalAction = (payload) => ({
    type: ACTION_TYPE.LOGIN_MODAL,
    payload
});
export const loginModalType = (payload) => ({
    type: ACTION_TYPE.LOGIN_MODAL_TYPE,
    payload
});