import React from "react";
import Textinput from "../../components/Atoms/Textinput";
import Button from "../../components/Atoms/Button";
import constants from "../../utilities/constants";
import Datepic from "../../components/Atoms/Datepic";
import Dropdown from "../../components/Atoms/Dropdown";
import { useFormik } from "formik";
import Textarea from "../../components/Atoms/Textarea";
import Contact from "../../components/Atoms/Contact";
import Switch from "../../components/Atoms/Switch";
import ModelCloseImg from "../../product_img/modelclose-icon.png";

const Modalinformationpage = ({
  modelTitle,
  modelSubTitle,
  handleInputChange,
  handleButtonChange,
  showModelData,
  headerEditModal,
  value,
  setIsOpen,
}) => {
  const changeMobilenumber = [
    {
      label: "Old Mobile number",
      type: "text",
      placeholder: "Enter Old Mobile number",
      value: "",
      key: 1,
    },
    {
      label: "New Mobile number",
      type: "text",
      placeholder: "Enter New Mobile number",
      value: "",
      key: 2,
    },
    {
      label: "Enter OTP",
      type: "text",
      placeholder: "OTP",
      value: "",
      key: 3,
    },
  ];
  const changeEmailAddress = [
    {
      label: "Old Email Address",
      type: "text",
      placeholder: "Enter Old Mobile number",
      value: "",
      key: 4,
    },
    {
      label: "New Email Address",
      type: "text",
      placeholder: "Enter New Mobile number",
      value: "",
      key: 5,
    },
    {
      label: "Enter OTP",
      type: "text",
      placeholder: "OTP",
      value: "",
      key: 6,
    },
  ];
  const changePassword = [
    {
      label: "Enter Old Password",
      type: "text",
      placeholder: "Enter Old Mobile number",
      value: "",
      key: 7,
    },
    {
      label: "New Password",
      type: "text",
      placeholder: "Enter New Mobile number",
      value: "",
      key: 8,
    },
    {
      label: "Re-enter your new password",
      type: "text",
      placeholder: "password",
      value: "",
      key: 9,
    },
  ];
  const changePersionalInformation = [
    {
      label: "Fisrt Name",
      type: "text",
      placeholder: "Enter Fisrt Name",
      value: value,
      key: 10,
    },
    {
      label: "Last Name",
      type: "text",
      placeholder: "Enter Last Name",
      value: value,
      key: 11,
    },
    {
      label: "Title",
      type: "dropdown",
      placeholder: "Enter Title ",
      value: value,
      options: ["Mr", "Miss", "Mrs"],
      key: 12,
    },
    {
      label: "Zip code",
      type: "text",
      placeholder: "Enter Zip code",
      value: value,
      key: 13,
    },
    {
      label: "Date of Birth",
      type: "date",
      placeholder: "Enter Date of Birth",
      value: "",
      key: 14,
    },
    {
      label: "Anniversary date",
      type: "date",
      placeholder: "Enter Anniversary date",
      value: "",
      key: 15,
    },
  ];
  const editAddress = [
    {
      label: "Name",
      type: "text",
      placeholder: "Enter Name",
      value: value,
      key: 16,
    },
    {
      label: "Country",
      type: "dropdown",
      placeholder: "Select Country",
      value: "",
      key: 17,
      options: ["India", "US", "UK"],
    },
    {
      label: "Street address",
      type: "textarea",
      placeholder: "Enter Street address",
      value: value,
      key: 18,
    },
    {
      label: "Contact",
      type: "contactdropdown",
      placeholder: "Enter Mobile No",
      value: value,
      key: 19,
      options: ["IND", "US", "UK"],
    },
    {
      label: "Zip code",
      type: "text",
      placeholder: "Enter Zip code",
      value: value,
      key: 20,
    },
    {
      label: "City",
      type: "dropdown",
      placeholder: "Select City",
      value: "",
      key: 21,
      options: ["chennai", "Erode", "Salem", "Namakal"],
    },
    {
      label: "State",
      type: "text",
      placeholder: "Enter State",
      value: value,
      key: 22,
    },
    {
      label: "Make this as a Primary Address",
      type: "switch",
      placeholder: "",
      value: value,
      key: 23,
    },
  ];
  const deleteAddressData = [
    {
      title: "Are you sure you want to delete this Address?",
      key: 24,
    },
  ];
  const renderConformationComponent = (item) => {
    return (
      <div className="py-5">
        <span>{item?.title}</span>
      </div>
    );
  };

  const renderComponent = (item, index) => {
    if (item.type === "text") {
      return (
        <Textinput
          label={item.label}
          placeholder={item.placeholder}
          value={item.value}
          onChange={handleInputChange}
          type={item.type}
          keyvalue={item.key}
        />
      );
    } else if (item.type === "date") {
      return (
        <Datepic
          label={item.label}
          placeholder={item.placeholder}
          value={item.value}
          onChange={handleInputChange}
          type={item.type}
          keyvalue={`date-input-${item.key}`}
        />
      );
    } else if (item.type === "dropdown") {
      return (
        <Dropdown
          label={item.label}
          placeholder={item.placeholder}
          value={item.value}
          onChange={handleInputChange}
          type={item.type}
          keyvalue={`dropdown-input-${item.key}`}
          options={item?.options}
        />
      );
    } else if (item.type === "textarea") {
      return (
        <Textarea
          label={item.label}
          placeholder={item.placeholder}
          value={item.value}
          onChange={handleInputChange}
          type={item.type}
          keyvalue={`textarea-input-${item.key}`}
        />
      );
    } else if (item.type === "contactdropdown") {
      return (
        <Contact
          label={item.label}
          placeholder={item.placeholder}
          value={item.value}
          onChange={handleInputChange}
          type={item.type}
          keyvalue={`textarea-input-${item.key}`}
          options={item?.options}
        />
      );
    } else if (item.type === "switch") {
      return (
        <Switch
          label={item.label}
          value={item.value}
          onToggle={handleInputChange}
        />
      );
    }
  };
  const getFormData = () => {
    switch (showModelData) {
      case constants.MobileNumber:
        return changeMobilenumber;
      case constants.EmailAddress:
        return changeEmailAddress;
      case constants.Password:
        return changePassword;
      case constants.AddressEdit:
        return editAddress;
      case constants.PersonalInformation:
        return changePersionalInformation;
      case constants.ManageAddress:
        return editAddress;
      case constants.AddressDelete:
        return deleteAddressData;

      default:
        return [];
    }
  };
  const initialValues = getFormData().reduce((acc, item) => {
    acc[item.label] = item.value;
    return acc;
  }, {});
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log("Formsubmittedwithvalues:", values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <section>
        <div className="font-Cormorant font-bold flex flex-row justify-between items-center text-xl text-[#262626]">
          <span>{modelTitle}</span>
          {(headerEditModal === constants.Confirmation ||
            showModelData === constants.ManageAddress ||
            showModelData === constants.AddressEdit) && (
            <img
              src={ModelCloseImg}
              onClick={() => setIsOpen(false)}
              className="w-3 h-3 cursor-pointer"
              alt="model close"
            />
          )}
        </div>
        {modelSubTitle !== null && (
          <div className=" font-medium text-sm mt-2 text-[#262626]">
            {modelSubTitle}
          </div>
        )}
        <div className="mt-5 border border-b-[#BFBFBF]"></div>
      </section>
      {headerEditModal === false && (
        <section className="py-5">
          {/* {showModelData === constants.MobileNumber &&
            changeMobilenumber.map((item) => renderComponent(item))}
          {showModelData === constants.EmailAddress &&
            changeEmailAddress.map((item) => renderComponent(item))}
          {showModelData === constants.Password &&
            changePassword.map((item) => renderComponent(item))} */}

          {getFormData().map((item) => renderComponent(item))}
        </section>
      )}
      {headerEditModal === true && (
        <section className="mt-5">
          <div className="grid grid-cols-2 gap-2 ">
            {/* {changePersionalInformation.map((item, index) =>
              renderComponent(item, index)
            )} */}
            {getFormData().map((item) => renderComponent(item))}
          </div>
        </section>
      )}
      {headerEditModal === constants.Confirmation && (
        <section>
          {getFormData().map((item) => renderConformationComponent(item))}
        </section>
      )}
      {headerEditModal !== constants.Confirmation ? (
        <section className="flex flex-row ">
          <Button
            label={"Cancel"}
            onClick={handleButtonChange}
            type="button"
            variant="secondary"
          />
          <Button
            label={"Submit"}
            onClick={handleButtonChange}
            type="submit"
            variant="primary"
          />
        </section>
      ) : (
        <section className="flex flex-row ">
          <Button
            label={"Cancel"}
            onClick={handleButtonChange}
            type="button"
            variant="secondary"
          />
          <Button
            label={"Confirm"}
            onClick={handleButtonChange}
            type="submit"
            variant="primary"
          />
        </section>
      )}
    </form>
  );
};

export default Modalinformationpage;
