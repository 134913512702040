export default {
  // api_url:'http://localhost:8002',
  // api_url_graphql:'http://localhost:8002/graphql',
  // api_url:'http://localhost',
  // api_url_graphql:'http://localhost/graphql',
  image_url: "https://assets.stylori.com/",
  //for staging
  api_url: "https://api-staging-new.stylori.com",
  api_url_graphql: "https://api-staging-new.stylori.com/graphql",
  shipping_days: 2, // shipping days intervals...
  HomePageBanner: "HomePageBanner",
  StyloriCard: "StyloriCard",
  StyloriTitle: "StyloriTitle",
  BestSellers: "BESTSELLERS",
  StyloriCardPrice: "StyloriCardPrice",
  ShopbyFinish: "SHOPBYFINISH",
  BlogPageCard: "blogPageCard",
  PersonalInformation: "Personal Information",
  ManageAddress: "Manage Address",
  Wishlist: "Wishlist",
  AllOrders: "All Orders",
  RedeemPoints: "Redeem Points",
  SingleOrder: "View Orders",
  ShoppingBag: "Shopping Bag",
  Logout: "Logout",
  MobileNumber: "Mobile Number",
  EmailAddress: "Email Address",
  Password: "Password",
  AddressEdit: "AddressEdit",
  AddressDelete: "AddressDelete",
  Confirmation: "Confirmation",
};
