import { ACTION_TYPE } from "./type";
import wishlist_img1 from "../../product_img/wishlist_img1.png";
import wishlist_img2 from "../../product_img/wishlist_img2.png";
import wishlist_img3 from "../../product_img/wishlist_img3.png";
import wishlist_img4 from "../../product_img/ wishlist_img4.png";
import order_img1 from "../../product_img/order_img1.png";
import order_img2 from "../../product_img/order_img2.png";

export const getPersionalData = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST });
  try {
    const data = [
      {
        label: "Title",
        value: "Mr",
        key: 1,
      },
      {
        label: "First Name",
        value: "gowrishankar",
        key: 2,
      },
      {
        label: "Last Name",
        value: "raman",
        key: 3,
      },
      {
        label: "Country",
        value: "india",
        key: 4,
      },
      {
        label: "Date of Birth",
        value: "07th May 2001",
        key: 5,
      },
      {
        label: "Anniversary Date",
        value: "11th January 2001",
        key: 6,
      },
      {
        label: "Mobile Number",
        value: "8056157840",
        key: 7,
      },
      {
        label: "Email Address",
        value: "g@gmail.com",
        key: 8,
      },
      {
        label: "Password",
        value: "1234",
        key: 9,
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
      payload: { persionalInformationDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_INFORMATION_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

export const getPersionalAddress = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST });
  try {
    const data = [
      {
        cilentName: "gowrishankar",
        primary: true,
        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar1",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India1",
        mobile: "8056157840",
      },
      {
        cilentName: "viki",
        primary: false,
        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar2",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India2",
        mobile: "8056157841",
      },
      {
        cilentName: "krithi",
        primary: false,

        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar3",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India3",
        mobile: "8056157842",
      },
      {
        cilentName: "krithika",
        primary: false,

        address1: "No 14 tholkpiar street, katangulathur chennai",

        address2: "maraimalainagar4",
        address3: "chengalpattu, Tamil Nadu, 603209",
        address4: "India4",
        mobile: "8056157843",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
      payload: { persionalAddressDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_PERSIONAL_ADDRESS_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getWishList = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_WISH_LIST_DATA_REQUEST });
  try {
    const data = [
      {
        image: wishlist_img1,
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: wishlist_img2,
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: wishlist_img3,
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
      {
        image: wishlist_img4,
        title: "Sherina Avikam Short Necklace Set",
        price: "₹ 46,700",
        action_btn1: "Move to Cart",
        action_btn2: "Remove",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_SUCCESS,
      payload: { wishListDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_WISH_LIST_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getAllOrders = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_ALL_ORDERS_DATA_REQUEST });
  try {
    const data = [
      {
        image: order_img1,
        status: ["Shipped"],
        title: "CHIC TWEETY GOLD EARRINGS",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
      {
        image: order_img2,
        status: ["Cancelled"],
        title: "IMANI GOLD MEN RING",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
      {
        image: order_img2,
        status: ["Delivered"],
        title: "NAP & SONS",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
      {
        image: order_img2,
        status: ["Cancelled", "Refunded"],
        title: "IMANI GOLD MEN RING",
        amount: "₹4,096",
        ordernumber: "SBA12009-92570000",
        orderdate: "06 March 2024",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_SUCCESS,
      payload: { allOrdersDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_ALL_ORDERS_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getSingleOrder = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_REQUEST });
  try {
    const data = [
      {
        OrderNumber: "03b5bfa0-50ff-11ef-bdbf-29ab60419f16",
        DeliveryDate: "3rd August 2024",
        PaymentMode: "UPI",
        ShippingAddress: [
          {
            name: "karthi",
            address1: " No 14 tholkpiar street, katangulathur chennai",
            address2: "maraimalainagar",
            address3: "chengalpattu, Tamil Nadu, 603209",
            address4: "India",
            mobile: "8056157840",
          },
        ],
        BillingAddress: [
          {
            name: "karthi",
            address1: " No 14 tholkpiar street, katangulathur chennai",
            address2: "maraimalainagar",
            address3: "chengalpattu, Tamil Nadu, 603209",
            address4: "India",
            mobile: "8056157840",
          },
        ],
        OrderItems: [
          {
            image: order_img2,
            title: "CHIC TWEETY GOLD EARRINGS",
            price: "$16.99",
            quantity: "1",
            productcode: "134BG1911",
            goldweight: "2.394 GM",
            Metal: "14KT white",
          },
          {
            image: order_img2,
            title: "CHIC TWEETY GOLD EARRINGS",
            price: "$16.99",
            quantity: "1",
            productcode: "134BG1911",
            goldweight: "2.394 GM",
            Metal: "14KT white",
          },
          {
            image: order_img2,
            title: "CHIC TWEETY GOLD EARRINGS",
            price: "$16.99",
            quantity: "1",
            productcode: "134BG1911",
            goldweight: "2.394 GM",
            Metal: "14KT white",
          },
        ],
        Subtotal: "$33.99",
        CGST: "$3.99",
        SGST: "$3.99",
        Shipping: "FREE",
        ShippingInsurance: "FREE",
        CoupeApplied: "-1200",
        GrantTotal: "$33.99",
      },
    ];

    dispatch({
      type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_SUCCESS,
      payload: { singleOrderDataSuccess: data },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_SINGLE_ORDER_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getShoppingDetails = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    // });
  } catch (error) {}
};
export const getRedeemPointsDetails = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    // });
  } catch (error) {}
};
export const logoutWebsite = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_INFORMATION_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_PERSIONAL_ADDRESS_REQUEST_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_ALL_ORDERS_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_SINGLE_ORDER_DATA_SUCCESS,
    // });
    // dispatch({
    //   type: ACTION_TYPE.CLEAR_WISH_LIST_DATA_SUCCESS,
    // });
  } catch (error) {}
};
