import React, { useState } from "react";
import Arrow from "../../product_img/chevron-down.svg";

const Dropdown = ({
  label = "test",
  placeholder = "test",
  value,
  onChange,
  type = "text",
  keyvalue,
  options,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectedValue = (data) => {
    setSelectedOption(data);
    onChange(label, data);
  };
  return (
    <section key={keyvalue}>
      <div className="text-sm font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div className="relative mt-4 ">
        <select
          className="block w-full appearance-none  border border-[#CACACA] p-2  focus:outline-none"
          value={selectedOption}
          onChange={(e) => handleSelectedValue(e.target.value)}
        >
          <option value="" disabled>
            {`Select the ${label}`}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div>
          <img
            src={Arrow}
            alt="Dropdown Icon"
            className="absolute top-1/2 right-3 w-6 h-6 transform -translate-y-1/2 pointer-events-none"
          />
        </div>
      </div>
    </section>
  );
};

export default Dropdown;
