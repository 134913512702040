import { ACTION_TYPE } from "./type";
// import { login } from '../../utilities/api'
import Config from "../../utilities/config";
import {
  home_product_type_count,
  homeNotification,
  homeResponse,subscribeUser,submitContactForm
} from "../../utilities/api";
import constants from "../../utilities/constants";
import {
  createHomeDataResponseObject,
  createHomeProductCountObject,
} from "../../utilities/helper";
import video_img1 from "../../product_img/video_img1.png";
import video_img2 from "../../product_img/video_img2.png";
import video_img3 from "../../product_img/video_img3.png";
import video_img4 from "../../product_img/video_img4.png";
import video_img5 from "../../product_img/video_img5.png";
import video_img6 from "../../product_img/video_img6.png";
import video_circleimg1 from "../../product_img/video_circleimg1.png";
import video_circleimg2 from "../../product_img/video_circleimg2.png";
import video_circleimg3 from "../../product_img/video_circleimg3.png";
import video_circleimg4 from "../../product_img/video_circleimg4.png";
import video_circleimg5 from "../../product_img/video_circleimg5.png";
import video_circleimg6 from "../../product_img/video_circleimg6.png";
import productcountcard_img1 from "../../product_img/productcountcard_img1.png";
import productcountcard_img2 from "../../product_img/productcountcard_img2.png";
import productcountcard_img3 from "../../product_img/productcountcard_img3.png";
import productcountcard_img4 from "../../product_img/productcountcard_img4.png";
import productcountcard_hoverimg1 from "../../product_img/productcountcard_hoverimg1.png";
import productcountcard_hoverimg2 from "../../product_img/productcountcard_hoverimg2.png";
import productcountcard_hoverimg3 from "../../product_img/productcountcard_hoverimg3.png";
import productcountcard_hoverimg4 from "../../product_img/productcountcard_hoverimg4.png";
import necklace from "../../product_img/nacklace.webp";

//Login
// export const loginUser = (form_data) => async (dispatch) => {
//     dispatch({ type: ACTION_TYPE.LOGIN_USER_ATTEMPT });

//     try {
//         // const  data  = await login(form_data);
//         const data="123"
//         dispatch({
//             type: ACTION_TYPE.LOGIN_USER_SUCCESS,
//             loginStatus:true,
//             payload: data
//         });
//     } catch (error) {
//         Config.logGeneral && console.log("Error", error)
//         dispatch({ type: ACTION_TYPE.LOGIN_USER_FAIL, errorMessage: error?.response?.data?.message, loginStatus:error?.response? error.response.data.status:error.message});
//     }
// };

// export const logout = () => async (dispatch) => {

//     dispatch({ type: ACTION_TYPE.USER_LOGGED_OUT });
// };

// export const loginInitLoading = () => async (dispatch) => {
//     dispatch({ type: ACTION_TYPE.LOGIN_INIT_LOADING,errorMessage:null });
// };

// export const update_token_fun=(update_token)=>(dispatch)=>{

//     dispatch({ type: ACTION_TYPE.LOGIN_UPDATE_TOKEN,token:{update_token} });

// }

const menuListHeaderNew1 = [
  {
    title: "Necklaces",
    key: "1",
    url: "/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    Submenu: [
      [
        {
          value: null,
          img: necklace,
          description:
            "Discover our exquisite collection of necklaces tailored for every style, material, and occasion. From timeless gold pieces to dazzling diamonds, explore the perfect accessory to complement your look",
          title: "ALL NECKLACES",
          url: "/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
          button: "Explore Our New Arrivals",
        },
        // {value:"TOP 20 NECKLACES",
        // title: "TOP 20 NECKLACES",
        // url: "/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0"
        // }
      ],
      [
        {
          value: null,
          title: "FINISH AND DESIGN",
          url: "/silver-necklaces-jewellery-oxidised+finish?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          value: "CZ/Swarovski Jewellery",
          title: null,
          url: "/silver-necklaces-jewellery-cubiczircon+swarovski?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Beads & Pearl Jewellery",
          title: null,
          url: "/silver-beads+chain-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          value: "Deep Nagas & Kundan",
          title: null,
          url: "/silver-necklaces-jewellery-deep+nagas+kundan?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Gold Plated Kundan",
          title: null,
          url: "/silver-necklaces-jewellery-gold+plated+kundan?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Studded Jewellery",
          title: null,
          url: "/silver-necklaces-jewellery-studded+jewellery?sort=Best%20Seller&startprice=0&endprice=0",
        },
        // {
        //   value: "Gold Plated kemp",
        //   title: "Gold Plated kemp",
        //   url: "/silver-necklaces-jewellery-gold+plated+kemp?sort=Best%20Seller&startprice=0&endprice=0"
        // },
        {
          value: "Nagas/Nakshi",
          title: null,
          url: "/silver-necklaces-jewellery-nagas+nakshi?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Dual Tone",
          title: null,
          url: "/silver-necklaces-jewellery-dual+tone+finish?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Victorian",
          title: null,
          url: "/silver-necklaces-jewellery-victorian?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Oxidised",
          title: null,
          url: "/silver-necklaces-jewellery-oxidised+finish?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Others",
          title: null,
          url: "/silver-necklaces-jewellery-finish+others?sort=Best%20Seller&startprice=0&endprice=0",
        },
      ],
      [
        {
          value: null,
          title: "JEWEL TYPE",
          url: "/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          value: "Necklace And Earrings",
          title: null,
          url: "/short+necklace-silver-set-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Short Necklace",
          title: null,
          url: "/short+necklace-silver-jewellery?sort=Best%20Seller&startprice=0&endprice=0",
        },
        {
          value: "Long Necklace",
          title: null,
          url: "/long+necklace-silver-necklaces-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Kaasu Maalai",
          title: null,
          url: "/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          value: "Beads/ Chain",
          title: null,
          url: "/silver-necklaces-jewellery-beads+pearl+jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Gutta Pusalu",
          title: null,
          url: "/silver-guttapusalu+necklace-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Layered",
          title: null,
          url: "/silver-layered+necklaces-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          value: "Choker",
          title: null,
          url: "/silver-chokers-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "PRICE RANGE",
          url: "/silver-necklaces-jewellery?sort=New to Stylori&startprice=0&endprice=100000000",
          class: "allneck",
        },
        {
          value: "Under 5000",
          url: "/silver-necklaces-jewellery?sort=New to Stylori&startprice=0&endprice=5000",
        },
        {
          value: "5000-10000",
          url: "/silver-necklaces-jewellery?sort=New to Stylori&startprice=5001&endprice=10000",
        },
        {
          value: "10000-25000",
          url: "/silver-necklaces-jewellery?sort=New to Stylori&startprice=10001&endprice=25000",
        },
        {
          value: "Above 25000",
          url: "/silver-necklaces-jewellery?sort=New to Stylori&startprice=25001&endprice=100000000",
        },
      ],
    ],
    expand: true,
  },

  {
    title: "Earrings",
    key: "2",
    url: "/earrings-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    Submenu: [
      [
        {
          value: null,
          description:
            "Discover our exquisite collection of Earring tailored for every style, material, and occasion. From timeless gold pieces to dazzling diamonds, explore the perfect accessory to complement your look",
            img: necklace,
            title: "ALL EARRINGS",
          url: "/earrings-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
          button: "Explore Our New Arrivals",
        },
        // {value:"TOP 20 EARRINGS",
        // title: "TOP 20 EARRINGS",
        // url: "/earrings-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0"
        // }
      ],
      [
        {
          title: "FINISH AND DESIGN",
          value: null,
          url: "#",
          class: "allneck",
        },
        {
          title: null,
          value: "CZ/Swarovski Jewellery",
          url: "/earrings-silver-jewellery-cubiczircon+swarovski?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Beads & Pearl Jewellery",
          url: "/earrings-silver-jewellery-beads+pearl+jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Deep Nagas & Kundan",
          url: "/earrings-silver-jewellery-deep+nagas+kundan?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Gold Plated Kundan",
          url: "/earrings-silver-jewellery-gold+plated+kundan?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Studded Jewellery",
          url: "/earrings-silver-jewellery-studded+jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        // { title: "Gold Plated kemp", value: "Gold Plated kemp", url: "/earrings-silver-jewellery-gold+plated+kemp?sort=New to Stylori&startprice=0&endprice=0" },
        {
          title: null,
          value: "Nagas/Nakshi",
          url: "/earrings-silver-jewellery-nagas+nakshi?sort=New to Stylori&startprice=0&endprice=0",
        },
        // { title: "Rose Gold", value: "Rose Gold", url: "/earrings-silver-jewellery-rose+gold+finish?sort=New to Stylori&startprice=0&endprice=0" },
        {
          title: null,
          value: "Dual Tone",
          url: "/earrings-silver-jewellery-dual+tone+finish?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Victorian",
          url: "/earrings-silver-jewellery-victorian?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Oxidised",
          url: "/earrings-silver-jewellery-oxidised+finish?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Others",
          url: "/earrings-others-silver-jewellery-finish+others?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "JEWEL TYPE",
          value: null,
          url: "#",
          class: "allneck",
        },
        {
          title: null,
          value: "Long Earrings/Drops",
          url: "/silver-long+earrings+drops-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Hooks & Hoops",
          url: "/earrings-silver-hooks+hoops-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Gutta Pusalu",
          url: "/silver-guttapusalu-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Ear cuffs",
          url: "/earrings-silver-ear+cuffs-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Chandbali",
          url: "/earrings-silver-chandbali-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Jhumkas",
          url: "/earrings-silver-jhumkas+online-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Studs",
          url: "/earrings-silver-studs-jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
      [
        { title: "PRICE RANGE", url: "#", class: "allneck" },
        {
          value: "Under 5000",
          url: "/earrings-silver-jewellery?sort=New to Stylori&startprice=0&endprice=5000",
        },
        {
          value: "5000-10000",
          url: "/earrings-silver-jewellery?sort=New to Stylori&startprice=5001&endprice=10000",
        },
        {
          value: "10000-25000",
          url: "/earrings-silver-jewellery?sort=New to Stylori&startprice=10001&endprice=25000",
        },
        {
          value: "Above 25000",
          url: "/earrings-silver-jewellery?sort=New to Stylori&startprice=25001&endprice=25000000",
        },
      ],
    ],
    expand: true,
  },
  {
    title: "Bangles",
    key: "3",
    url: "/bangles-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    Submenu: [
      [
        {
          value: null,
          title: "ALL BANGLES",
          img: necklace,
          description: "Discover our exquisite collection of necklaces tailored for every style, material, and occasion. From timeless gold pieces to dazzling diamonds, explore the perfect accessory to complement your look",
          url: "/bangles-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
          button: "Explore Our New Arrivals",
        },
      ],
      [
        {
          title: "FINISH AND DESIGN",
          value: null,
          url: "/bangles-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "CZ/Swarovski Jewellery",
          url: "/bangles-silver-jewellery-cubiczircon+swarovski?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Beads & Pearl Jewellery",
          url: "/bangles-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Deep Nagas & Kundan",
          url: "/bangles-silver-jewellery-deep+nagas+kundan?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Gold Plated Kundan",
          url: "/bangles-silver-jewellery-gold+plated+kundan?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Studded Jewellery",
          url: "/bangles-silver-jewellery-studded+jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Nagas/Nakshi",
          url: "/bangles-silver-jewellery-nagas+nakshi?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Dual Tone",
          url: "/bangles-silver-jewellery-dual+tone+finish?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Victorian",
          url: "/bangles-silver-jewellery-victorian?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Oxidised",
          url: "/bangles-silver-jewellery-oxidised+finish?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Others",
          url: "/others-bangles-silver-jewellery-finish+others?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "JEWEL TYPE",
          value: null,
          url: "/bangles-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Single Bangle",
          url: "/silver-single+bangle-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Pair Bangle",
          url: "/silver-pair+bangle-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "PRICE RANGE",
          url: "/bangles-silver-jewellery?sort=New to Stylori&startprice=0&endprice=10000000",
          class: "allneck",
        },
        {
          value: "Under 5000",
          url: "/bangles-silver-jewellery?sort=New to Stylori&startprice=0&endprice=5000",
        },
        {
          value: "5000-10000",
          url: "/bangles-silver-jewellery?sort=New to Stylori&startprice=5001&endprice=10000",
        },
        {
          value: "10000-25000",
          url: "/bangles-silver-jewellery?sort=New to Stylori&startprice=10001&endprice=25000",
        },
        {
          value: "Above 25000",
          url: "/bangles-silver-jewellery?sort=New to Stylori&startprice=25001&endprice=10000000",
        },
      ],
    ],
    expand: true,
  },
  {
    title: "Pendants",
    key: "4",
    url: "/pendants-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    // bigImage: "https://styloriimages.s3.ap-south-1.amazonaws.com/Banners/Stylori+Silver/Menu-Dropdown-Images---Earrings.jpg",
    Submenu: [
      [
        {
          value: null,
          title: "ALL PENDANTS",
          img: necklace,
          description: "Discover our exquisite collection of necklaces tailored for every style, material, and occasion. From timeless gold pieces to dazzling diamonds, explore the perfect accessory to complement your look",
          url: "/pendants-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
          button: "Explore Our New Arrivals",
        },
        // {value:"TOP 20 PENDANTS",
        // title: "TOP 20 PENDANTS",
        // url: "/pendants-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0"
        // }
      ],
      [
        {
          title: "FINISH AND DESIGN",
          value: null,
          url: "#",
          class: "allneck",
        },
        {
          title: null,
          value: "CZ/Swarovski Jewellery",
          url: "/pendants-silver-jewellery-cubiczircon+swarovski?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Deep Nagas & Kundan",
          url: "/pendants-silver-jewellery-deep+nagas+kundan?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Gold Plated Kundan",
          url: "/pendants-silver-jewellery-gold+plated+kundan?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Studded Jewellery",
          url: "/pendants-silver-jewellery-studded+jewellery?sort=New to Stylori&startprice=0&endprice=0",
        },
        // { title: "Gold Plated kemp", value: "Gold Plated kemp", url: "/pendants-silver-jewellery-gold+plated+kemp?sort=New to Stylori&startprice=0&endprice=0" },
        {
          title: null,
          value: "Nagas/Nakshi",
          url: "/pendants-silver-jewellery-nagas+nakshi?sort=New to Stylori&startprice=0&endprice=0",
        },
        // { title: "Rose Gold", value: "Rose Gold", url: "/pendants-silver-jewellery-rose+gold+finish?sort=New%20to%20Stylori&startprice=0&endprice=0" },
        {
          title: null,
          value: "Dual Tone",
          url: "/pendants-silver-jewellery-dual+tone+finish?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Victorian",
          url: "/pendants-silver-jewellery-victorian?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Oxidised",
          url: "/pendants-silver-jewellery-oxidised+finish?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Others",
          url: "/others-pendants-silver-jewellery-finish+others?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "JEWEL TYPE",
          value: null,
          url: "/pendants-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Pendant & Earring",
          url: "/silver-pendant+earring-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Double Hook",
          url: "/silver-doublehook-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Single Hook",
          url: "/silver-singlehook-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
      ],
      [
        {
          title: "PRICE RANGE",
          url: "/pendants-silver-jewellery?sort=New to Stylori&startprice=0&endprice=2500000",
          class: "allneck",
        },
        {
          value: "Under 5000",
          url: "/pendants-silver-jewellery?sort=New to Stylori&startprice=0&endprice=5000",
        },
        {
          value: "5000-10000",
          url: "/pendants-silver-jewellery?sort=New to Stylori&startprice=5001&endprice=10000",
        },
        {
          value: "10000-25000",
          url: "/pendants-silver-jewellery?sort=New to Stylori&startprice=10001&endprice=25000",
        },
        {
          value: "Above 25000",
          url: "/pendants-silver-jewellery?sort=New to Stylori&startprice=25001&endprice=2500000",
        },
      ],
    ],
    expand: true,
  },
  // [
  //   { title: "MORE CATEGORIES", value: "MORE CATEGORIES", url: "#" },
  //   { title: "Hair Ornaments", value: "Hair Ornaments", url: "#" },
  //   { title: "Idols & Articles", value: "Idols & Articles", url: "#" },
  //   { title: "Maang Tikka", value: "Maang Tikka", url: "#" },
  //   { title: "Oddiyanam", value: "Oddiyanam", url: "#" },
  //   { title: "Bracelet", value: "Bracelet", url: "#" },
  //   { title: "Armlets", value: "Armlets", url: "#" },
  //   { title: "Anklets", value: "Anklets", url: "#" },
  //   { title: "Nosepin", value: "Nosepin", url: "#" },
  //   { title: "Other Accessories", value: "Other Accessories", url: "#" }
  // ]
  {
    title: "More Categories",
    key: "5",
    url: "/silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    // bigImage: "https://styloriimages.s3.ap-south-1.amazonaws.com/Banners/Stylori+Silver/Menu-Dropdown-Images---Earrings.jpg",
    Submenu: [
      [
        {
          value: null,
          title: "MORE CATEGORIES",
          url: "/silver-hair+ornaments-jewellery?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          value: "Hair Ornaments",
          title: null,
          url: "/silver-hair+ornaments-jewellery?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          value: "Idols & Articles",
          title: null,
          url: "/silver-antique+idols-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
        {
          value: "Maang Tikka",
          title: null,
          url: "/silver-maang+tikka-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
        {
          value: "Oddiyanam",
          title: null,
          url: "/silver-oddiyanam-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
        {
          value: "Bracelet",
          title: null,
          url: "/bracelets-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          value: "Armlets",
          title: null,
          url: "/silver-armlet-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
        {
          value: "Anklets",
          title: null,
          url: "/anklet-silver-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
        {
          value: "Nosepin",
          title: null,
          url: "/nose+pin+online-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
        },
        {
          value: "Other Accessories",
          title: null,
          url: "/silver-other+accessories-jewellery?sort=Best Seller&startprice=0&endprice=0",
        },
      ],
    ],
    expand: true,
  },
  {
    title: "Shop By Style",
    key: "6",
    url: "/silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    Submenu: [
      [
        {
          title: "SHOP BY STYLE",
          value: null,
          url: "/silver-jewellery-traditional?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Traditional",
          url: "/silver-jewellery-traditional?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Folklore",
          url: "/silver-jewellery-folklore?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Contemporary",
          url: "/silver-jewellery-contemporary?sort=New to Stylori&startprice=0&endprice=0",
        },
        // {"title":"Dmudra","value":"Dmudra","url":"/silver-jewellery-dmudra?sort=New to Stylori&startprice=0&endprice=0"},
        {
          title: null,
          value: "Everyday",
          url: "/silver-jewellery-everyday?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
    ],
    expand: true,
  },
  {
    title: "Shop By Collection",
    key: "7",
    url: "/silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0",
    Submenu: [
      [
        {
          title: "SHOP BY COLLECTION",
          value: null,
          url: "/silver-rasvini-jewellery?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Rasvini",
          url: "/silver-rasvini-jewellery?sort=New to Stylori&startprice=0&endprice=0",
          class: "allneck",
        },
        {
          title: null,
          value: "Mural",
          url: "/silver-jewellery-mural+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Starstuck",
          url: "/silver-jewellery-starstruck+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Concentric",
          url: "/silver-jewellery-concentric+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Inlovecollection",
          url: "/silver-jewellery-inlovecollection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Elemental",
          url: "/silver-jewellery-elemental+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Baroque Whites",
          url: "/silver-jewellery-baroque+whites+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
        {
          title: null,
          value: "Akruti",
          url: "/silver-jewellery-akruti+collection?sort=New to Stylori&startprice=0&endprice=0",
        },
      ],
    ],
    expand: true,
  },
  // {
  //   title: "Stylori",
  //   key: "8",
  //   url: "/",
  //   expand: false
  // }
];
const homePageVideoData = [
  {
    title: "Carlona Studs",
    price: "2,990",
    salesprice: "2,691",
    image: video_img1,
    circle_image: video_circleimg1,
  },
  {
    title: "Product Number 01",
    price: "2,990",
    salesprice: "2,691",
    image: video_img2,
    circle_image: video_circleimg2,
  },
  {
    title: "Product Number 02",
    price: "2,990",
    salesprice: "2,691",
    image: video_img3,
    circle_image: video_circleimg3,
  },
  {
    title: "Product Number 03",
    price: "2,990",
    salesprice: "2,691",
    image: video_img4,
    circle_image: video_circleimg4,
  },
  {
    title: "Product Number 04",
    price: "2,990",
    salesprice: "2,691",
    image: video_img5,
    circle_image: video_circleimg5,
  },
  {
    title: "Product Number 05",
    price: "2,990",
    salesprice: "2,691",
    image: video_img6,
    circle_image: video_circleimg6,
  },
];
const homePageProductCountCard = [
  {
    title: "Gold Pendants",
    subtitle: "pendants",

    image: productcountcard_img1,
    hoverimg: productcountcard_hoverimg1,
  },
  {
    title: "Diamond Ring",
    subtitle: "rings",

    image: productcountcard_img2,
    hoverimg: productcountcard_hoverimg2,
  },
  {
    title: "Gold Earings",
    subtitle: "earrings",

    image: productcountcard_img3,
    hoverimg: productcountcard_hoverimg3,
  },
  {
    title: "Designer Bangle",
    subtitle: "bangles",

    image: productcountcard_img4,
    hoverimg: productcountcard_hoverimg4,
  },
];
export const getHomepagedata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_HOME_DATA_REQUEST });

  try {
    const data = await homeResponse();

    // Config.logGeneral && console.log("homepagedata", data)
    dispatch({
      type: ACTION_TYPE.GET_HOME_DATA_RESPONSE_SUCCESS,

      payload: {
        homeResponseSuccess: data,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("ErroringetHomepagedata", error);
    dispatch({
      type: ACTION_TYPE.GET_HOME_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getHomePageMainBannerdata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_REQUEST });

  try {
    const data = await homeResponse();

    Config.logGeneral && console.log("getHomePageMainBannerdata", data);

    dispatch({
      type: ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_SUCCESS,

      payload: {
        homeMainBannerResponseSuccess: createHomeDataResponseObject(
          data,
          constants.HomePageBanner,
          null
        ),
      },
    });
  } catch (error) {
    Config.logGeneral &&
      console.log("ErroringgetHomePageMainBannerdata", error);
    dispatch({
      type: ACTION_TYPE.GET_HOME_MAIN_BANNER_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getHomePageSubBannerdata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_REQUEST });

  try {
    const data = await homeResponse();

    dispatch({
      type: ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_SUCCESS,

      payload: {
        homeSubBannerResponseSuccess: createHomeDataResponseObject(
          data,
          constants.StyloriCard,
          null
        ),
      },
    });
  } catch (error) {
    Config.logGeneral &&
      console.log("ErroringetgetHomePageSubBannerdata", error);
    dispatch({
      type: ACTION_TYPE.GET_HOME_SUB_BANNER_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getBestSellerdata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_BESTSELLER_DATA_REQUEST });

  try {
    const data = await homeResponse();

    // const filterdData = data?.findIndex(
    //   (obj) =>
    //     obj?.component === constants.StyloriTitle &&
    //     obj?.props?.title_Text === constants.BESTSELLERS
    // );

    // const bestSellerDataArray = data[filterdData + 1]?.props;
    // const bestSellerHeader = data[filterdData]?.props?.title_Text;
    // const bestSellerData = {
    //   component: bestSellerHeader,
    //   props: bestSellerDataArray,
    // };

    // Config.logGeneral && console.log("homepagedata", data)

    dispatch({
      type: ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_SUCCESS,

      payload: {
        bestSellerResponseSuccess: createHomeDataResponseObject(
          data,
          constants.StyloriTitle,
          constants.BestSellers
        ),
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Erroringetbestseller", error);
    dispatch({
      type: ACTION_TYPE.GET_BESTSELLER_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

export const getShopbyfinishdata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_REQUEST });

  try {
    const data = await homeResponse();

    // Config.logGeneral && console.log("homepagedata", data)
    dispatch({
      type: ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_SUCCESS,

      payload: {
        shopbyfinishCollectionResponseSuccess: createHomeDataResponseObject(
          data,
          constants.StyloriTitle,
          constants.ShopbyFinish
        ),
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Erroringetnavarathnaa", error);
    dispatch({
      type: ACTION_TYPE.GET_SHOPBYFINISH_COLLECTION_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getReadourblogsdata = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_REQUEST });

  try {
    const data = await homeResponse();

    // Config.logGeneral && console.log("homepagedata", data)
    dispatch({
      type: ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_SUCCESS,

      payload: {
        readOurBlogsDataResponseSuccess: createHomeDataResponseObject(
          data,
          constants.BlogPageCard,
          null
        ),
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Erroringetreadourblogsdata", error);
    dispatch({
      type: ACTION_TYPE.GET_READ_OUR_BLOGS_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

export const getHomepagenotification = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_HOME_NOTIFICATION_REQUEST });

  try {
    const data = await homeNotification();

    Config.logGeneral && console.log("homenotificationpagedata", data);
    dispatch({
      type: ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_SUCCESS,

      payload: {
        homenotificationResponseSuccess: data,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error", error);
    dispatch({
      type: ACTION_TYPE.GET_HOME_NOTIFICATION_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};

export const getHeaderdata = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_HEADER_DATA_REQUEST });

  try {
    // const  data  = await login(form_data);

    // Config.logGeneral && console.log("homepagedata", data)
    dispatch({
      type: ACTION_TYPE.GET_HEADER_DATA_REQUEST_SUCCESS,

      payload: {
        headerResponseSuccess: menuListHeaderNew1,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error in getHomepagedata", error);
    dispatch({
      type: ACTION_TYPE.GET_HEADER_DATA_REQUEST_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getVideodata = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_VIDEO_DATA_REQUEST });

  try {
    // const  data  = await login(form_data);

    // Config.logGeneral && console.log("homepagedata", data)
    dispatch({
      type: ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_SUCCESS,

      payload: {
        videoDataResponseSuccess: homePageVideoData,
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error in getHomepagedata", error);
    dispatch({
      type: ACTION_TYPE.GET_VIDEO_DATA_RESPONSE_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
export const getProductCountCard = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_REQUEST });

  try {
    const data = await home_product_type_count();

    Config.logGeneral &&
      console.log(
        "productCountDatacheck",
        createHomeProductCountObject(homePageProductCountCard, data)
        // "data?.data",
        // data?.data
      );

    dispatch({
      type: ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_SUCCESS,

      payload: {
        productsCountCardDataResponseSuccess: createHomeProductCountObject(
          homePageProductCountCard,
          data
        ),
      },
    });
  } catch (error) {
    Config.logGeneral && console.log("Error in getHomepagedata", error);
    dispatch({
      type: ACTION_TYPE.GET_PRODUCT_COUNT_CARD_DATA_FAILURE,
      errorMessage: error?.response?.data?.message,
    });
  }
};
//MAIL SUBSCRIPTION
export const subscribeUserWithMail = (email) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.MAIL_SUBSCRIPTION_REQUEST });

  try {
    // Call the subscribeUser function, which returns a Promise
    const message = await subscribeUser(email);
    console.log(message,"uuuumessa")
    // Dispatch success action if subscription is successful
    dispatch({
      type: ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS,
      payload: message, // Pass the success message from the API response
    });
  } catch (error) {
    // Dispatch failure action with the error message
    dispatch({
      type: ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_FAILURE,
      errorMessage: error, // The error message from the rejection
    });
  }
};
//MAIL SUBSCRIPTION clear
export const clearMessage = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.MAIL_SUBSCRIPTION_RESPONSE_SUCCESS_CLEAR });  
};
//contact us form submit
export const contactus_form_submission = (form_data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.CONTACTUSFORM_REQUEST });

  try {
    const data = await submitContactForm(form_data);
    // Check if the API response is successful
    if (data && data.message === "Thanks for contacting us. Our Team will reach out you.") {
      dispatch({
        type: ACTION_TYPE.CONTACTUSFORM_SUCCESS,
        payload: "Thanks for contacting us. Our Team will reach out you.",
      });
    } else {
      // Handle failure case if the message from the API is not success
      dispatch({
        type: ACTION_TYPE.CONTACTUSFORM_FAIL,
        errorMessage: data.message || 'Failed to submit the form. Please try again later.',
      });
    }
  } catch (error) {
    // If any unexpected error occurs
    dispatch({
      type: ACTION_TYPE.CONTACTUSFORM_FAIL,
      errorMessage: error?.message || 'An error occurred. Please try again later.',
    });
  }
};
//to reset contact us form submission
export const resetContactUsStatus = () => async (dispatch) => {  
  dispatch({ type: ACTION_TYPE.CONTACTUSFORM_RESET});  
};


