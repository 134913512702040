import React from "react";

const Textarea = ({
  label = "test",
  placeholder = "test",
  value,
  onChange,
  type = "textarea",
  keyvalue,
}) => {
  return (
    <section className="col-span-2">
      <div className="text-sm font-medium text-[#353535]">
        <label>{label}</label>
      </div>
      <div key={keyvalue} className="pt-2 relative">
        <textarea
          type={type}
          value={value}
          onChange={(e) => onChange(label, e.target.value)}
          placeholder={placeholder}
          className="w-full px-4 resize-none  h-[86px] py-2 border border-[#CACACA]  focus:outline-none focus:ring-2 focus:ring-blue-500"
          key={label}
        />
      </div>
    </section>
  );
};

export default Textarea;
