import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { product_list_filter,product_list_item } from '../redux/action/productAction';
//searchquerymenu
import {searchquerymenu} from "../redux/action/homeAction";
import config from "../utilities/config";
import constants from "../utilities/constants";
import { formatCurrency, getValue } from "../utilities/helper";
import Config from "../utilities/config";
import { Link, useParams } from 'react-router-dom';
import Cart from "../product_img/cart.svg";
import Myaccount from "../product_img/profileicon.svg";
import Wishlist from "../product_img/wishlist.svg";
import close from "../product_img/close.svg";
import search from "../product_img/searchicon.svg";
import Sparkling from "../product_img/sparkling.svg";
import kundan from "../product_img/kundan.webp";
import pichwai from "../product_img/pichwai.webp";
import varshin from "../product_img/varshin.webp";

const Header = () => {
  const dispatch = useDispatch();
  const {searchMenuResponseLoading,searchMenuResponseSuccess,searchMenuResponseError} = useSelector((state) => state.homeReducer);
  const {productListResponseSuccess,productListResponseError} = useSelector((state) => state.productFilterListReducer);
  console.log(productListResponseSuccess,"productListResponseSuccess")
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  
  // Handle search query change
  const handleSearchChange = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(`${query}`);    
  };
  const getImage = (image, size = "300X300") => {
      let productId = image.split("/");
      return `${constants.image_url}${productId?.[0]}/${productId?.[1]}/${size}/${productId?.[2]}`;
    };
  console.log(searchMenuResponseSuccess,"searchMenuResponseSuccess")
  // useEffect(() => {
  //   if (searchMenuResponseSuccess) {
  //     setSearchResults(searchMenuResponseSuccess);      
  //   }
  // }, [searchMenuResponseSuccess]); // Only run this effect when searchMenuResponseSuccess changes

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchquerymenu(searchQuery)); // Dispatch the action only when searchQuery changes
      dispatch(product_list_filter());
    }
  }, [searchQuery, dispatch]); 

  return (
    <div className="lg:flex text-[#1C1C1C] items-center space-x-4">
      {/* Search Section */}
      <div className=" flex items-center cursor-pointer">
        {/* Search Icon */}
        {!isSearchOpen && (
          <img
            src={search}
            alt="Search"
            className="pr-3"
            onClick={() => setIsSearchOpen(true)}
          />
        )}

        {/* Search Bar */}
        {isSearchOpen && (
          <div className="absolute top-3 bottom-0 left-1/4 right-1/2 m-auto w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search..."
              className="w-full pl-12 pr-12 py-3 rounded-md focus:outline-none bg-[#F5F5F5] text-[#242424]"
              autoFocus
            />
            {/* Search Icon Inside the Input Field */}
            <img
              src={search}
              alt="Search"
              className="absolute left-3 top-6 transform -translate-y-1/2"
            />
            {/* Close Button */}
            <button
              onClick={() => setIsSearchOpen(false)}
              className="absolute top-6 right-3 transform -translate-y-1/2"
            >
              <img src={close} alt="Close" />
            </button>

            {/* Search Results Box */}
            {searchQuery && (
              <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                {(
                   
                    <div className="bg-white w-11/12 md:w-full p-6 rounded-md shadow-lg">
                      <h2 className="text-lg font-medium mb-4 uppercase">Suggestions</h2>
                      <hr className="border-[#06ab9f] border"></hr>
                      <div className="flex flex-wrap gap-3 pt-5 pb-3">
                      {productListResponseSuccess && (
                        productListResponseSuccess["Product Type"]?.map((list, i) => (
                          <div key={i} className="flex border w-auto rounded-full py-1 px-2" onClick={()=> {
                            if(list.name === 'necklace'){
                              window.location.href = `/styloriSilver/silver-necklaces-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`;
                            }
                            else if(list.name ==='nosepins'){
                              window.location.href = `/styloriSilver/nose+pin+online-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`
                            }
                            else{
                              let productlistname=list.name.split(" ").length > 1 ? list.name.toLowerCase().split(" ").join("+"):list.name.toLowerCase();                            
                              window.location.href = `/styloriSilver/${productlistname}-silver-jewellery?sort=New%20to%20Stylori&startprice=0&endprice=0`
                            }
                            
                            }}
                            >
                            <img src={Sparkling} className="pr-1" />
                            <p className="text-base font-medium text-[#262626]">{list.name}</p>
                          </div>
                        ))
                      )}


                        
                        
                      </div>
                      <h2 className="text-lg font-medium mb-4 uppercase pt-5">Products</h2>
                      <hr className="border-[#06ab9f] border"></hr>
                      {searchMenuResponseSuccess?.length > 0 ? (
                        <div className="grid grid-cols-4 gap-3 pt-5">
                          {searchMenuResponseSuccess.map((item) => (
                            <div key={item.skuId} className="flex col-span-1 items-center gap-2" onClick={()=>{window.location.href=item?.skuUrl || "/"}}>
                              <img
                                src={getImage(
                                  item.productListByProductId.productImagesByProductId.nodes[0].imageUrl
                                )
                                  }
                                alt={item.productListByProductId.productName}
                                className="size-16"
                              />
                              <div>
                                <h3 className="text-[#242424] text-sm font-semibold pb-1">
                                  {item.productListByProductId.productName}
                                </h3>
                                <p className="text-[#6A6A6A] text-xs font-medium">{formatCurrency(item?.markupPrice)}                                  
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "No products found"
                      )}

                     
                    </div>
                  
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Other Header Elements */}
      <Link to="#" className="hover:text-yellow-400 pr-3">
        <img src={Cart} alt="Cart" />
      </Link>
      <Link
        to="/wishlist"
        className="hover:text-yellow-400 pr-3 hidden lg:block"
      >
        <img src={Wishlist} alt="Wishlist" className="w-[26px]" />
      </Link>
      <Link
        to="/"
        className="hover:text-yellow-400 w-[32px] hidden lg:block"
      >
        <img src={Myaccount} alt="My Account" className="w-[26px]" />
      </Link>
    </div>
  );
};

export default Header;
