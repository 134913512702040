import React, { useState } from "react";

const PopupModal = ({ isOpen, children }) => {
  return (
    <div className="flex justify-center items-center ">
      {/* Trigger Button */}

      {/* Modal */}
      {isOpen && (
        <div className="z-10 fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-[594px]  p-6">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupModal;
