

import { ACTION_TYPE } from '../action/type'
const initialState = {
    orderResponesLoading: false,
    orderfailResponesLoading: false,
    orderResponseSuccess: null,
    orderResponseError: null,
    orderResponseFail: null,
    orderResponseFailError: null,
    reSendOrderMailLoading:false,
    reSendOrderMailSuccess:null,
    reSendOrderMailFail:null,

}
const useraddress_initialState = {
    userAddressess_success:null,
    userAddressess_loading: false,
    userAddressess_error: null,
};

export function orderReducer(state = initialState, action) {

    switch (action.type) {
        case ACTION_TYPE.GET_ORDERSUCCESS_DATA_REQUEST:
            return {
                ...state,
                orderResponesLoading: true,
                orderResponseSuccess: null,
                orderResponseError: null
            }
        case ACTION_TYPE.GET_ORDERSUCCESS_DATA_RESPONSE_SUCCESS:

            return {
                ...state,
                orderResponesLoading: false,
                orderResponseSuccess: action.payload.orderSuccessResponse,
                orderResponseError: null
            }
        case ACTION_TYPE.GET_ORDERSUCCESS_DATA_RESPONSE_FAILURE:
            return {
                ...state,
                orderResponesLoading: false,
                orderResponseSuccess: null,
                orderResponseError: action.errorMessage
            }
        case ACTION_TYPE.GET_ORDERFAILED_DATA_REQUEST:
            return {
                ...state,
                orderfailResponesLoading: true,
                orderResponseFail: null,
                orderResponseFailError: null
            }
        case ACTION_TYPE.GET_ORDERFAILED_DATA_RESPONSE_SUCCESS:
            return {
                ...state,
                orderfailResponesLoading: false,
                orderResponseFail: action.payload.orderfailResponse,
                orderResponseFailError: null
            }
        case ACTION_TYPE.GET_ORDERFAILED_DATA_RESPONSE_FAILURE:
            return {
                ...state,
                orderfailResponesLoading: false,
                orderResponseFail: null,
                orderResponseFailError: action.errorMessage
            }
        case ACTION_TYPE.RESENDORDERMAIL_REQUEST:
        return {
            ...state,
            reSendOrderMailLoading:true,
            reSendOrderMailSuccess:null,
            reSendOrderMailFail:null,
        }
        case ACTION_TYPE.RESENDORDERMAIL_SUCCESS:
        return {
            ...state,
            reSendOrderMailLoading:false,
            reSendOrderMailSuccess:action.payload.resendResponse,
            reSendOrderMailFail:null,
        }
        case ACTION_TYPE.RESENDORDERMAIL_FAIL:
        return {
            ...state,
            reSendOrderMailLoading:false,
            reSendOrderMailSuccess:null,
            reSendOrderMailFail:action.errorMessage,
        }

        default:
            return state;
    }
}

export const useraddressreducer = (state = useraddress_initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE.USER_ADDRESSES_REQUEST:
            return { ...state, userAddressess_loading: true, userAddressess_error: null };
        case ACTION_TYPE.USER_ADDRESSES_SUCCESS:
            return { 
                ...state, 
                userAddressess_loading: false,
                userAddressess_success:action.payload.useraddressSuccessResponse          
            };
        case ACTION_TYPE.USER_ADDRESSES_FAILURE:
            return { ...state, userAddressess_loading: false, userAddressess_error: action.payload };        
        default:
            return state;
    }
};

