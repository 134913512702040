import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./screens/Home";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";
import Thanks from "./screens/Thanks";
import PaymentFail from "./screens/PaymentFail";
import Header from "./components/Header";
import ProductDetails from "./screens/ProductDetails";
import ContactUsForm from "./screens/ContactUsForm";
import ProductList from "./screens/ProductList";
import Register from "./screens/Register";
import Account from "./screens/Account";
import Login from "./screens/Login";
import Footer from "./components/Footer";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsofUse from "./screens/TermsofUse";
import SiteMap from "./screens/SiteMap";
import ShippingReturns from "./screens/ShippingReturns";
import Faqs from "./screens/Faqs";
import ProductCare from "./screens/ProductCare";

function App() {
  return (
    <Router>
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Navigate replace to="/stylorisilver" />} />
          <Route path="/styloriSilver" element={<Home />} />
          <Route path="/styloriSilver/contactus" element={<ContactUsForm />} />
          <Route path="/styloriSilver/cart" element={<Cart />} />
          <Route path="/styloriSilver/checkout" element={<Checkout />} />
          {/* /paymentsuccess/be383350-9a9a-11ef-b160-8b3d5475222d */}
          <Route
            path="/styloriSilver/paymentsuccess/:orderId"
            element={<Thanks />}
          />
          {/* product description */}
          <Route
            path="/styloriSilver/jewellery/:category/:product"
            element={<ProductDetails />}
          />
          {/* product list */}
          <Route path="/styloriSilver/:category" element={<ProductList />} />
          {/* Login */}
          <Route path="/styloriSilver/login" element={<Login />} />
          {/* register */}
          <Route path="/styloriSilver/registers" element={<Register />} />
          {/* /paymentfail/ff46ada0-9d19-11ef-9238-3b9903186ac9       */}
          <Route
            path="/styloriSilver/paymentfail/:orderid"
            element={<PaymentFail />}
          />
          {/* /account-wishlist */}
          <Route path="/styloriSilver/account" element={<Account />} />
          {/* static pages */}
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsconditions" element={<TermsofUse />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/deliveryreturns" element={<ShippingReturns />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/productcare" element={<ProductCare />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
